import React, { FunctionComponent } from 'react'
import { Statistic } from 'antd';
import { TeamOutlined, UsergroupDeleteOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons/lib';
import { LoaderAdaptive } from './Loader';

type Props = {
  loading: boolean
  active: number
  blocked: number
  online: number
  offline: number
}
export const CouriersStatistic: FunctionComponent<Props> = props => {
  const {
    active,
    blocked,
    online,
    offline,
    loading,
  } = props;

  if (loading) return (
    <div className='Couriers__statistic'>
      <LoaderAdaptive size='small'/>
    </div>
  );

  return (
    <div className='Couriers__statistic'>
      <Statistic
        className='Couriers__statisticItem'
        title='Онлайн'
        value={online}
        prefix={<EyeOutlined />}
      />
      <Statistic
        className='Couriers__statisticItem'
        title='Офлайн'
        value={offline}
        prefix={<EyeInvisibleOutlined />}
      />
      <Statistic
        className='Couriers__statisticItem'
        title='Активных'
        value={active}
        prefix={<TeamOutlined />}
      />
      <Statistic
        className='Couriers__statisticItem'
        title='В черном списке'
        value={blocked}
        prefix={<UsergroupDeleteOutlined />}
      />
    </div>
  )
};
