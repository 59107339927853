import {
  CustomerListItem,
  CustomerListResponse,
  CustomersPaginatorExtra,
  CustomerType,
  CustomerTypesResponse,
} from '../../api/protocol';

export type CustomerListTypes = 'active' | 'blocked';
export type CustomerPaginateLikeItem = PaginateLikeCacheList<CustomerListItem> & CustomersPaginatorExtra;
export interface CustomerState {
  types: CustomerType[];
  list: Record<CustomerListTypes, CustomerPaginateLikeItem>;
}

export const CUSTOMER_SET_TYPES = 'CUSTOMER_SET_TYPES';
export const CUSTOMER_SET_LIST_ITEM = 'CUSTOMER_SET_LIST_ITEM';
export const CUSTOMER_SET_LIST_PAGE = 'CUSTOMER_SET_LIST_PAGE';
export const CUSTOMER_SET_EMPTY_LIST = 'CUSTOMER_SET_EMPTY_LIST';

interface CustomerSetTypesAction {
  type: typeof CUSTOMER_SET_TYPES;
  payload: CustomerTypesResponse;
}

interface CustomerSetListItemsAction {
  type: typeof CUSTOMER_SET_LIST_ITEM;
  payload: { response: CustomerListResponse, selector: CustomerListTypes };
}

interface CustomerSetListPageAction {
  type: typeof CUSTOMER_SET_LIST_PAGE;
  payload: { page: number, selector: CustomerListTypes }
}

interface CustomerSetEmptyListAction {
  type: typeof CUSTOMER_SET_EMPTY_LIST;
}

export type CustomerActionTypes =
  | CustomerSetTypesAction
  | CustomerSetListItemsAction
  | CustomerSetListPageAction
  | CustomerSetEmptyListAction;
