import { NewsItemResponse, NewsListResponse } from '../../api/protocol';
import {
  NEWS_SET_DETAILS_ITEM,
  NEWS_SET_LIST_FLUSH,
  NEWS_SET_LIST_ITEM,
  NEWS_SET_LIST_PAGE,
  NewsActionTypes,
} from './types';

export const setListPage = (payload: number): NewsActionTypes => ({ type: NEWS_SET_LIST_PAGE, payload });
export const setListItem = (payload: NewsListResponse): NewsActionTypes => ({ type: NEWS_SET_LIST_ITEM, payload });
export const setListFlush = (): NewsActionTypes => ({ type: NEWS_SET_LIST_FLUSH });
export const setDetailsItem = (payload: NewsItemResponse): NewsActionTypes => ({ type: NEWS_SET_DETAILS_ITEM, payload });
