import {
  CourierLocation,
  CouriersDetailsResponse,
  CouriersListItem,
  CouriersListResponse, CouriersOnlineLocationsResponse,
  CouriersTransactionsListResponse,
} from '../../api/protocol';

export type CouriersListTypes = 'active' | 'blocked';
export type CouriersPaginateLikeItem = PaginateLikeCacheList<CouriersListItem>;
export type CouriersLocationList = Record<string, CourierLocation>;
export type CouriersLocationCacheList = { items: CouriersLocationList, pages: number };
export type CouriersListExtra = {
  active: number,
  blocked: number,
  offline: number,
  online: number,
};
export interface CouriersState {
  details: Record<number, CouriersDetailsResponse>
  currentList: CouriersListTypes
  list: Record<CouriersListTypes, CouriersPaginateLikeItem>
  listExtra: CouriersListExtra
  locationList: CouriersLocationCacheList
}

export const COURIERS_SET_LIST_ITEM = 'COURIERS_SET_LIST_ITEM';
export const COURIERS_SET_LIST_PAGE = 'COURIERS_SET_LIST_PAGE';
export const COURIERS_SET_EMPTY_LIST = 'COURIERS_SET_EMPTY_LIST';
export const COURIERS_SET_CURRENT_LIST = 'COURIERS_SET_CURRENT_LIST';
export const COURIERS_SET_DETAILS = 'COURIERS_SET_DETAILS';
export const COURIERS_REPLENISH_BALANCE = 'COURIERS_REPLENISH_BALANCE';
export const COURIERS_SET_EMPTY_DETAILS = 'COURIERS_SET_EMPTY_DETAILS';
export const COURIERS_SET_LOCATION_RESULT = 'COURIERS_SET_LOCATION_RESULT';

interface CouriersSetListItemsAction {
  type: typeof COURIERS_SET_LIST_ITEM;
  payload: { response: CouriersListResponse, selector: CouriersListTypes };
}

interface CouriersSetListPageAction {
  type: typeof COURIERS_SET_LIST_PAGE;
  payload: number
}

interface CouriersSetEmptyListAction {
  type: typeof COURIERS_SET_EMPTY_LIST;
}

interface CouriersSetCurrentListAction {
  type: typeof COURIERS_SET_CURRENT_LIST;
  payload: CouriersListTypes
}

interface CouriersSetDetailsAction {
  type: typeof COURIERS_SET_DETAILS;
  payload: CouriersDetailsResponse;
}

export type CouriersReplenishBalancePayload = {
  id: number,
  balance: number,
  transactions: CouriersTransactionsListResponse | null
}
interface CouriersReplenishBalanceAction {
  type: typeof COURIERS_REPLENISH_BALANCE;
  payload: CouriersReplenishBalancePayload;
}

interface CouriersSetEmptyDetailsAction {
  type: typeof COURIERS_SET_EMPTY_DETAILS;
}

interface CouriersSetLocationsResultAction {
  type: typeof COURIERS_SET_LOCATION_RESULT
  payload: CouriersOnlineLocationsResponse[]
}

export type CouriersActionTypes =
  | CouriersSetListItemsAction
  | CouriersSetListPageAction
  | CouriersSetCurrentListAction
  | CouriersSetEmptyListAction
  | CouriersSetDetailsAction
  | CouriersReplenishBalanceAction
  | CouriersSetEmptyDetailsAction
  | CouriersSetLocationsResultAction
