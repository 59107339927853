import React, { FunctionComponent, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/es6/pipeable';

import { Button, Layout, Dropdown, Modal, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PhoneOutlined,
  SettingOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { UserRole } from '../../api/protocol';
import { RootState } from '../../store/root';
import { OperatorData } from '../../store/user/types';
import { makeMapDispatch } from '../../store/dispatcher';
import { setCollapseState } from '../../store/user/actions';

import { EventBus } from '../../utils/bus';
import { formatPhone, getProfileName } from '../../utils/view';

import { DateTimeInfo } from './DateTimeInfo';

import './HeaderTopBar.scss';

const { Header } = Layout;

type StateProps = {
  userRole: UserRole | null;
  userName: string | null;
  isCollapsed: boolean;
  operatorData: OperatorData;
};

const mapState = (state: RootState): StateProps => ({
  isCollapsed: state.user.settings.collapsed,
  userName: getProfileName(state.user.profile),
  userRole: state.user.role,
  operatorData: state.user.operatorData,
});

const mapDispatch = makeMapDispatch({ setCollapse: setCollapseState });

type Props = StateProps & ReturnType<typeof mapDispatch>;
const HeaderTopBar: FunctionComponent<Props> = props => {
  const { userRole, userName, isCollapsed, setCollapse, operatorData } = props;

  const { push: redirectTo } = useHistory();

  // collapse sider on mobile by default
  useEffect(() => {
    if (window.innerWidth < 768) setCollapse(true);
  }, [setCollapse]);

  const createLogoutModal = (): void => {
    Modal.confirm({
      title: 'Подтверждение действия',
      content: 'Вы действительно хотите выйти?',
      okText: 'Да',
      icon: <ExclamationCircleOutlined />,
      onOk: close => {
        close();
        EventBus.emit('onLogout');
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => redirectTo('/profile/' + userRole)}>
        <SettingOutlined />
        Профиль
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={createLogoutModal}>
        <LogoutOutlined />
        Выйти
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="HeaderTopBar">
      <div className="HeaderTopBar__leftSection">
        <Button
          className="HeaderTopBar__navToggle"
          type="primary"
          onClick={() => setCollapse(!isCollapsed)}
        >
          {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>

        {userRole !== 'franchiser' && (
          <div className="HeaderTopBar__phoneContainer">
            <p className="HeaderTopBar__phoneLabel">Телефон диспетчера</p>
            <a className="HeaderTopBar__phone" href={`tel:+${operatorData.phone}`}>
              <PhoneOutlined />
              {formatPhone(operatorData.phone)}
            </a>
          </div>
        )}
      </div>

      <div className="HeaderTopBar__rightSection">
        <DateTimeInfo />
        {userName && (
          <Dropdown overlay={menu}>
            <Button
              type="link"
              className="ant-dropdown-link HeaderTopBar__link"
              onClick={e => e.preventDefault()}
            >
              {userName}
            </Button>
          </Dropdown>
        )}
      </div>
    </Header>
  );
};

const connected = pipe(
  HeaderTopBar,
  connect(mapState, mapDispatch),
  withRouter
);

export { connected as HeaderTopBar };
