import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Input,
  Select,
  Radio,
  Form,
  Card,
  Divider,
  Button,
  Tooltip,
  Row,
  Col,
} from 'antd';
import {
  IdcardOutlined,
  BankOutlined,
  PartitionOutlined,
  SettingOutlined,
  SaveOutlined,
  LeftOutlined,
} from '@ant-design/icons/lib';
import { LabeledValue } from 'antd/lib/select';
import { FormInstance } from 'antd/lib/form';
import Password from 'antd/lib/input/Password';

import { CurrencyInput, KmInput } from './Inputs';
import { FormBranchesPart } from './FormBranchesPart';
import { FormRateVariant } from './FormRateVariant';

import {
  CustomerBranchInfo,
  CustomerBranchPayload,
  CustomerCreateRequest,
  CustomerDetailsResponse,
  CustomerType,
  EncodedAddress,
} from '../api/protocol';
import { formatAutocompleteBranch } from '../utils/form';
import { apiCustomerTypes, makeMapDispatch } from '../store/dispatcher';

const requiredRule = [{ required: true, message: 'Заполните поле' }];

const defaults: CustomerCreateRequest = {
  name: '',
  type_id: 1,
  user: { is_blocked: false, name: '', phone: '', username: '', password: '' },
  branches: [
    {
      address: '',
      phone: '',
      comment: '',
      place_id: '',
    },
  ],
  rate_variant: {
    rate_type: 'fix',
    delivery_price_fix: 100,
  },
  rate: {
    courier_commission_percent: 1,
    courier_increased_commission_start_price: 100,
    courier_increased_commission_percent: 1,
    additional_delivery_price_start_distance_km: 1,
    additional_delivery_price_distance_step_km: 1,
    additional_delivery_price_fix: 100,
  },
};

const mapActions = makeMapDispatch({ fetchCustomerTypes: apiCustomerTypes });

type Props = {
  form: FormInstance;
  loading: boolean;
  isUpdate: boolean;
  typesList: CustomerType[];
  onBack: () => void;
  onSubmit: (data: unknown) => void;
  customerData?: CustomerDetailsResponse;
} & ReturnType<typeof mapActions>;

const submitButton = (
  loading: boolean,
  isMobile = false,
  text = 'Сохранить'
): JSX.Element => (
  <Button
    type="primary"
    htmlType="submit"
    size="large"
    loading={loading}
    className={`Organizations__saveButton ${
      isMobile ? 'Organizations__saveButton--mobile' : ''
    }`}
  >
    {!loading && <SaveOutlined />}
    {text}
  </Button>
);

const OrganizationForm: FunctionComponent<Props> = props => {
  const {
    loading,
    typesList,
    onBack,
    onSubmit,
    customerData,
    isUpdate,
    form,
    fetchCustomerTypes,
  } = props;
  const typesOptions: LabeledValue[] = typesList.map(item => ({
    label: item.name,
    value: item.id,
  }));

  const [addresses, setAddresses] = useState<EncodedAddress[]>([]);

  const addAddress = (index: number, value?: string): void => {
    setAddresses(prev => [
      ...prev.filter(item => item.index !== index),
      { index, value },
    ]);
  };

  const initialData =
    isUpdate && customerData ? { ...customerData } : { ...defaults };

  useEffect(() => {
    fetchCustomerTypes().finally(undefined);

    if (isUpdate) {
      initialData.branches.forEach((item: any, index: number) => {
        addAddress(
          index,
          (item as CustomerBranchInfo).address.geocoder_place_id
        );
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="AppSection Organizations__form">
      <Form
        layout="vertical"
        form={form}
        onFinish={data => {
          const branches: CustomerBranchPayload[] = [];
          data.branches.forEach(
            (branch: CustomerBranchPayload, index: number) => {
              branches.push(formatAutocompleteBranch(branch, index, addresses));
            }
          );
          onSubmit({
            ...data,
            branches,
          });
        }}
        initialValues={initialData}
      >
        {isUpdate && (
          <Form.Item name="id" noStyle>
            <Input type="hidden" />
          </Form.Item>
        )}
        <Card style={{ marginBottom: 15 }}>
          <div className="Organizations__header">
            <div className="Organizations__headerLeft">
              <Tooltip title="Назад к списку организаций">
                <Button
                  type="link"
                  onClick={onBack}
                  className="Organizations__headerBack"
                >
                  <LeftOutlined />
                </Button>
              </Tooltip>
              <h2 className="Organizations__headerTitle">
                /
                <BankOutlined style={{ marginRight: 8, marginLeft: 8 }} />
                Организация
              </h2>
            </div>
            {submitButton(loading)}
          </div>
          <Divider />

          <Row gutter={24}>
            <Col className="Organizations__column">
              <Form.Item label="Название" name="name" rules={requiredRule}>
                <Input />
              </Form.Item>
            </Col>
            <Col className="Organizations__column">
              <Form.Item
                label="Тип организации"
                name="type_id"
                rules={requiredRule}
              >
                <Select options={typesOptions} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Row gutter={24}>
          <Col className="Organizations__column">
            <Card style={{ marginBottom: 15 }}>
              <h2>
                <IdcardOutlined style={{ marginRight: 8 }} />
                Пользователь
              </h2>
              <Divider />
              <Row gutter={24}>
                <Col className="Organizations__column">
                  <Form.Item
                    label="Логин"
                    name={['user', 'username']}
                    rules={requiredRule}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="Organizations__column">
                  <Form.Item
                    label="Пароль"
                    name={['user', 'password']}
                    rules={isUpdate ? [] : requiredRule}
                  >
                    <Password />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Пользователь заблокирован"
                name={['user', 'is_blocked']}
              >
                <Radio.Group>
                  <Radio.Button value>Да</Radio.Button>
                  <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Card>
            <FormBranchesPart
              addressPath={['address', 'address']}
              isUpdate={isUpdate}
              setOuterValues={(index, value) =>
                setAddresses(prev => [
                  ...prev.filter(item => item.index !== index),
                  { index, value },
                ])
              }
            />
          </Col>
          <Col className="Organizations__column">
            <Card style={{ marginBottom: 15 }}>
              <h2>
                <PartitionOutlined style={{ marginRight: 8 }} />
                Вариант тарифа
              </h2>
              <Divider />
              <FormRateVariant form={form} />
            </Card>
            <Card style={{ marginBottom: 15 }}>
              <h2>
                <SettingOutlined style={{ marginRight: 8 }} />
                Настройки тарифа
              </h2>
              <Divider />
              <Row gutter={24}>
                <Col className="Organizations__column">
                  <Form.Item
                    name={['rate', 'additional_delivery_price_fix']}
                    help="Дополнительная плата за доставку"
                    rules={requiredRule}
                  >
                    <CurrencyInput />
                  </Form.Item>
                </Col>
                <Col className="Organizations__column">
                  <Form.Item
                    name={[
                      'rate',
                      'additional_delivery_price_start_distance_km',
                    ]}
                    help="С какого расстояния брать дополнительную плату"
                    rules={requiredRule}
                  >
                    <KmInput />
                  </Form.Item>
                </Col>
                <Col className="Organizations__column">
                  <Form.Item
                    name={[
                      'rate',
                      'additional_delivery_price_distance_step_km',
                    ]}
                    help="Дополнительная плата за каждый последующий STEP км"
                    rules={requiredRule}
                  >
                    <KmInput />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {submitButton(loading, true, 'Добавить')}
      </Form>
    </section>
  );
};

const connected = connect(null, mapActions)(OrganizationForm);
export { connected as OrganizationForm };
