import React, { FunctionComponent } from 'react';
import { Lens } from 'monocle-ts';

import { Form, Divider, Button, Radio, Tooltip, Input, Card, Row, Col } from 'antd';
import {
  IdcardOutlined,
  SaveOutlined,
  LeftOutlined,
  CarOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons/lib';
import { FormInstance } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import Password from 'antd/lib/input/Password';
import ReactInputMask from 'react-input-mask';
import moment from 'moment';

import './CouriersForm.scss';

import { PhoneInput } from '../Inputs';
import { FormCarVariant } from '../FormCarVariant';

import { CouriersCreateRequest, CouriersDetailsResponse, CouriersUpdateRequest } from '../../api/protocol';
import { clearFormData } from '../../utils/form';

const requiredRule = [{ required: true, message: 'Заполните поле' }];

const formDataGetter = Lens.fromProps<CouriersDetailsResponse>()([
  'id',
  'first_name',
  'last_name',
  'middle_name',
  'birth_date',
  'registration',
  'car',
  'user',
]);

type Props = {
  form: FormInstance
  loading: boolean
  isUpdate: boolean
  onBack: () => void,
  onSubmit: (data: unknown) => void
  courierData?: CouriersDetailsResponse
};

const submitButton = (loading: boolean, isMobile = false, text = 'Сохранить'): JSX.Element => (
  <Button className={`CouriersForm__submitButton ${isMobile ? 'CouriersForm__submitButton--mobile' : ''}`} type='primary' htmlType='submit' size='large' loading={loading}>
    {!loading && <SaveOutlined/>}
    {text}
  </Button>
);

export const CouriersForm: FunctionComponent<Props> = props => {
  const { loading, onBack, onSubmit, isUpdate, form, courierData } = props;

  if (!courierData && isUpdate) return null;

  const initialData = isUpdate && courierData ? formDataGetter.get(courierData) : undefined;
  if (initialData && initialData.birth_date) {
    initialData.birth_date = moment(initialData.birth_date, 'YYYY.MM.DD').format('DD.MM.YYYY');
  }

  const sanitizeSubmitData = (data: unknown): void => {
    const formData = clearFormData(data) as CouriersCreateRequest | CouriersUpdateRequest;

    if (formData.car.type === 'walker') formData.car = {
      type: 'walker',
      brand: null,
      color: null,
      model: null,
      number: null,
      year: null,
    };

    if (formData.birth_date) formData.birth_date = moment(formData.birth_date, 'DD.MM.YYYY').format('YYYY.MM.DD');

    onSubmit(formData);
  };

  return (
    <section className="AppSection CouriersForm">
      <Form layout="vertical"
            form={form}
            onFinish={sanitizeSubmitData}
            initialValues={initialData}
      >
        {isUpdate && (<Form.Item name="id" noStyle><Input type='hidden'/></Form.Item>)}
        <Card style={{ marginBottom: 15 }}>
          <div className="CouriersForm__header">
            <div className="CouriersForm__headerLeft">
              <Tooltip title='Назад к списку курьеров'>
                <Button type='link' onClick={onBack} className='CouriersForm__headerBack'>
                  <LeftOutlined/>
                </Button>
              </Tooltip>
              <h2 className='CouriersForm__headerTitle'>
                /
                {isUpdate
                  ? <UserSwitchOutlined style={{ marginRight: 8, marginLeft: 8 }}/>
                  : <UserAddOutlined style={{ marginRight: 8, marginLeft: 8 }}/>
                }
                Курьер
              </h2>
            </div>
            {submitButton(loading)}
          </div>
          <Divider/>

          <Row gutter={24}>
            <Col className="CouriersForm__column">
              <Form.Item label="Фамилия" name="last_name" rules={requiredRule}>
                <Input/>
              </Form.Item>
            </Col>
            <Col className="CouriersForm__column">
              <Form.Item label="Имя" name="first_name" rules={requiredRule}>
                <Input/>
              </Form.Item>
            </Col>
            <Col className="CouriersForm__column">
              <Form.Item label="Отчество" name="middle_name" rules={requiredRule}>
                <Input/>
              </Form.Item>
            </Col>
            <Col className="CouriersForm__column">
              <Form.Item label="Дата рождения" name="birth_date" rules={requiredRule}>
                <ReactInputMask
                  className="ant-input"
                  mask="99.99.9999"
                />
              </Form.Item>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item label="Регистрация" name="registration" rules={requiredRule}>
                <TextArea placeholder='Город, улица, дом, квартира'/>
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Row gutter={24}>
          <Col className="CouriersForm__column">
            <Card style={{ marginBottom: 15 }}>
              <h2>
                <IdcardOutlined style={{ marginRight: 8 }}/>
                Пользователь
              </h2>
              <Divider/>
              <Row gutter={24}>
                <Col style={{ width: '100%' }}>
                  <Form.Item label="Телефон" name={['user', 'phone']} rules={requiredRule}>
                    <PhoneInput placeholder='+7 (123) 456-78-90'/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col className="CouriersForm__column">
                  <Form.Item label="Логин" name={['user', 'username']} rules={requiredRule}>
                    <Input/>
                  </Form.Item>
                </Col>
                <Col className="CouriersForm__column">
                  <Form.Item label="Пароль" name={['user', 'password']} rules={isUpdate ? [] : requiredRule}>
                    <Password/>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Пользователь заблокирован" name={['user', 'is_blocked']}>
                <Radio.Group>
                  {/* eslint-disable-next-line */}
                  <Radio.Button value={true}>Да</Radio.Button>
                  <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Col>
          <Col className="CouriersForm__column">
            <Card style={{ marginBottom: 15 }}>
              <h2>
                <CarOutlined style={{ marginRight: 8 }}/>
                Данные о транспорте
              </h2>
              <Divider/>
              <FormCarVariant form={form}/>
            </Card>
          </Col>
        </Row>

        {submitButton(loading, true, 'Добавить')}
      </Form>
    </section>
  );
};
