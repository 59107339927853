// import * as t from 'io-ts'
import * as SafeBase64 from '../../utils/base64';
import { ApiError } from './errors';
import { Authorization } from './auth';

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
}

type FetchBody = FormData | string | null;

export class ApiClientClass {
  private invalidTokenHandler: () => void = () => {};
  private defaultHeaders = { 'Accept': 'application/json' };

  constructor(private readonly auth: Authorization) {}

  private request<T>(method: HttpMethod, url: string, body: FetchBody, headers = {}): Promise<T> {
    const init: RequestInit = {
      headers: { ...this.defaultHeaders, ...headers, ...this.auth.getAuthHeaders() } as HeadersInit,
      credentials: 'same-origin',
      mode: 'cors',
      method,
      body,
    };

    return fetch(url, init).then(response => this.processResponse<T>(response));
  }

  private processResponse<T>(response: Response): Promise<T> {
    const contentType = response.headers.get('content-type');
    if (!contentType || contentType.indexOf('application/json') < 0) {
      // TODO: create fake error for this status
      throw new ApiError({ error: { '': [{ code: 'invalid_response_type', message: 'Invalid response type' }] } });
    }
    return response.json().then(json => {
      if (!response.ok) {
        let ServerError = null;
        if (response.status === 500) {
          // TODO: create fake error for this status
          ServerError = new ApiError({
            error: {
              '': [{
                code: 'invalid_response_type',
                message: 'Invalid response type',
              }],
            },
          });
        } else if (response.status === 422) {
          ServerError = new ApiError(json);
          if (ServerError.getCommonCodes().includes('invalid_token')) {
            this.invalidTokenHandler();
          }
        }
        throw ServerError;
      }

      if ('token' in json) this.auth.setToken(json.token);

      // TODO: implement generic validation (io-ts with codec)
      return json as T;
    });
  }

  setInvalidTokenHandler(handler: () => void): void {
    this.invalidTokenHandler = handler;
  }

  get<Response, Request = null>(url: string, payload: Request): Promise<Response> {
    let getUrl = url;
    if (payload instanceof Object && Object.keys(payload).length) getUrl += '?_body=' + SafeBase64.encode(JSON.stringify(payload));
    return this.request<Response>(HttpMethod.Get, getUrl, null);
  }

  post<Response, Request = null>(url: string, payload: Request): Promise<Response> {
    return this.request<Response>(HttpMethod.Post, url, JSON.stringify(payload), { 'Content-Type': 'application/json' });
  }

  multiPartRequest<Response, Request = null>(url: string, data: Request, files: Record<string, File>): Promise<Response> {
    const body = new FormData();
    Object.entries(files).forEach((pair) => body.append(...pair));
    body.append('_body', SafeBase64.encode(JSON.stringify(data)));
    return this.request<Response>(HttpMethod.Post, url, body);
  }
}


