import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { Table, Tag, Tooltip, Radio, Button, Empty } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ColumnsType } from 'antd/lib/table';
import { EditOutlined, PlusOutlined, PhoneOutlined } from '@ant-design/icons';

import { RootState } from '../../store/root';
import { CustomerListTypes } from '../../store/customers/types';
import { setCustomerListPage } from '../../store/customers/actions';
import { apiCustomerList, makeMapDispatch } from '../../store/dispatcher';
import { CustomerListItem } from '../../api/protocol';

import { formatCurrency } from '../../utils/strings';
import { showApiErrorNotification } from '../../utils/noty';
import { formatPercents, formatPhone, formatPrice } from '../../utils/view';

import './Organizations.scss';
import '../Orders/ResponsiveTable.scss';

type OrganizationBranchesProps = { branches: CustomerListItem['branches'] };
const OrganizationBranches: FunctionComponent<OrganizationBranchesProps> = ({
  branches,
}) => {
  switch (true) {
    case branches.length > 1: {
      const mainBranch = branches.slice(0, 1)[0];
      const secondBranches = branches.slice(1, 100000).map(branch => (
        <p className="OrganizationsTable__secondaryBranch" key={branch.id}>
          {branch.address.address}
        </p>
      ));
      return (
        <>
          <div className="OrganizationsTable__mainBranch">
            <p className="OrganizationsTable__mainBranchAddress">{mainBranch.address.address}</p>
            {mainBranch.phone && (
              <a
                className="OrganizationsTable__mainBranchPhone"
                href={`tel:+${mainBranch.phone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PhoneOutlined />
                {formatPhone(mainBranch.phone)}
              </a>
            )}
          </div>
          <Tooltip placement="top" title={secondBranches}>
            <Tag color="#FF3030">Еще {secondBranches.length}</Tag>
          </Tooltip>
        </>
      );
    }
    case branches.length > 0:
      return (
        <p className="OrganizationsTable__mainBranch">
          {branches[0].address.address}
        </p>
      );
    default:
      return null;
  }
};

const createColumnSettings = (
  redirectTo: (path: string) => void
): ColumnsType<CustomerListItem> => [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
    render: (id: number) => (
      <div className="ResponsiveTable__idWrapper">
        <h3 className="ResponsiveTable__mobileHeading">
          № организации:
        </h3>
        <Tag color="red" className="ResponsiveTable__id">{id}</Tag>
      </div>
    ),
  },
  {
    title: 'Название',
    key: 'name',
    render: ({ name }: CustomerListItem) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">
          Название
        </h3>
        <div>{name}</div>
      </>
    ),
  },
  {
    title: 'Адрес и телефон',
    key: 'branches',
    render: (organization: CustomerListItem) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">
          Адрес и телефон
        </h3>
        <OrganizationBranches branches={organization.branches} />
      </>
    ),
  },
  {
    title: 'Представитель',
    dataIndex: 'user',
    key: 'user',
    className: 'OrganizationsTable__agentColumn',
    render: (agent: CustomerListItem['user']): JSX.Element => {
      return (
        <>
          <h3 className="ResponsiveTable__mobileHeading">
            Представитель
          </h3>
          <p className="OrganizationsTable__agentName">
            {agent.name || 'Не указан'}
          </p>
          {agent.phone && (
            <a
              className="OrganizationsTable__agentPhone"
              href={`tel:+${agent.phone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PhoneOutlined />
              {formatPhone(agent.phone)}
            </a>
          )}
        </>
      );
    },
  },
  {
    title: 'Стоимость доставки',
    dataIndex: 'rate_variant',
    key: 'rate_variant',
    className: 'OrganizationsTable__priceColumn',
    render: (variant: CustomerListItem['rate_variant']) => {
      return (
        <>
          <h3 className="ResponsiveTable__mobileHeading">
            Стоимость доставки
          </h3>

          {variant.rate_type === 'fix' ? (
            <b>{formatPrice(variant.delivery_price_fix.toString())}&nbsp;{formatCurrency()}</b>
          ) : (
            <span>
              <b>{formatPercents(variant.delivery_price_percent)}&nbsp;%</b>
              <br />
              от заказа
            </span>
          )}
        </>
      );
    },
  },
  {
    dataIndex: 'id',
    key: 'actions',
    className: 'OrganizationsTable__actionsColumn',
    render: (id: number): JSX.Element => {
      return (
        <>
          <h3 className="ResponsiveTable__mobileHeading">
            Действия
          </h3>
          <Tooltip title="Изменить">
            <Button
              type="primary"
              data-id={id}
              icon={<EditOutlined />}
              onClick={() => {
                redirectTo('/organizations/edit/' + id);
              }}
            />
          </Tooltip>
        </>
      );
    },
  },
];

type StateProps = {
  customersList: RootState['customers']['list'];
};
const mapState = (state: RootState): StateProps => ({
  customersList: state.customers.list,
});
const mapDispatch = makeMapDispatch({
  setPage: setCustomerListPage,
  getOrganizationPage: apiCustomerList,
});
type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const Organizations: FunctionComponent<Props> = props => {
  const { setPage, getOrganizationPage, customersList } = props;

  const { push: redirectTo } = useHistory();
  const [loading, setLoading] = useState(false);
  const [selector, setSelector] = useState<CustomerListTypes>('active');

  const { items, ...pagination } = customersList[selector];
  const columnSettings = useMemo(() => createColumnSettings(redirectTo), [
    redirectTo,
  ]);

  const requestPage = (page: number): void => {
    if (page in items) {
      setPage({ page, selector });
    } else {
      setLoading(true);
      getOrganizationPage(
        { page, is_blocked: selector === 'blocked' },
        selector
      )
        .catch(showApiErrorNotification)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!loading) requestPage(pagination.page);
  }, [selector, loading, pagination.page]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeFilter = (evt: RadioChangeEvent): void => {
    setSelector(evt.target.value as CustomerListTypes);
  };

  const viewList = items[pagination.page] || [];

  return (
    <section className="AppSection Organizations">
      <header className="Organizations__header Organizations__header--margined">
        <div className="Organizations__filters">
          <Radio.Group
            className="Organizations__radioButtons"
            defaultValue="active"
            buttonStyle="solid"
            onChange={onChangeFilter}
          >
            <Radio.Button className="Organizations__radioButton" value="active">Активные</Radio.Button>
            <Radio.Button className="Organizations__radioButton" value="blocked">Черный список</Radio.Button>
          </Radio.Group>
        </div>

        <div className="Organizations__addButtonWrapper">
          <Button
            type="primary"
            className="Organizations__addButton"
            onClick={() => redirectTo('/organizations/create')}
          >
            <PlusOutlined />
            Добавить организацию
          </Button>
        </div>
      </header>

      <div className="Organizations__table ResponsiveTable ResponsiveTable--organizations">
        <Table
          rowKey="id"
          loading={loading}
          columns={columnSettings}
          dataSource={viewList}
          onChange={paginator => {
            requestPage(paginator.current || 1);
          }}
          pagination={{
            total: pagination.total,
            current: pagination.page,
            pageSize: pagination.page_size,
            hideOnSinglePage: true,
          }}
          locale={{
            emptyText: (
              <Empty
                description="Список организаций пуст"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ),
          }}
        />
      </div>
    </section>
  );
};

const connected = withRouter(connect(mapState, mapDispatch)(Organizations));
export { connected as Organizations };
