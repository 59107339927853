import React, { FunctionComponent, useState } from 'react';
import moment, { Moment } from 'moment';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons/lib';
import { useInterval } from '../../hooks/interval';

export const DateTimeInfo: FunctionComponent = () => {
  const [currentTime, setTime] = useState<Moment>(moment());
  useInterval(() => { setTime(moment()); }, 1000);
  return (
    <div className="HeaderTopBar__dateTime">
      {/* Display current date */}
      <p className="HeaderTopBar__currentDate">
        <CalendarOutlined />
        {currentTime.format('DD MMMM YYYY, dddd')}
      </p>

      {/* Display current time */}
      <p className="HeaderTopBar__currentTime">
        <ClockCircleOutlined />
        {currentTime.format('HH:mm:ss')}
      </p>
    </div>
  );
};
