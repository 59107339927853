import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Button, InputNumber } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';

import { apiCourierReplenishBalance, makeMapDispatch } from '../store/dispatcher';

import { useBusEffect } from '../utils/bus';
import { bindErrorHandler } from '../utils/noty';
import { formatCurrency } from '../utils/strings';

declare module '../utils/bus' {
  interface BusEvents {
    onReplenishBalance: number
  }
}

const MIN_AMOUNT = 1;

type FormData = { amount: number }
const initialValues: FormData = { amount: 100 };
const amountRules: Rule[] = [{ type: 'integer', required: true, message: 'Введите сумму' }, { type: 'integer', min: MIN_AMOUNT, message: `Сумма должна быть больше ${MIN_AMOUNT}${formatCurrency()}` }];

const mapDispatch = makeMapDispatch({ replenishBalance: apiCourierReplenishBalance });
type Props = ReturnType<typeof mapDispatch>;
const ReplenishBalance: FunctionComponent<Props> = props => {
  const { replenishBalance } = props;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentId, setCurrentId] = useState<number | null>(null);

  useBusEffect('onReplenishBalance', id => {
    setCurrentId(id);
    setVisible(true);
  });

  const errorHandler = bindErrorHandler(form, () => { setLoading(false); });

  const closeModal = (): void => {
    if (loading) return;
    setCurrentId(null);
    setLoading(false);
    setVisible(false);
  };

  const onSubmit = (data: unknown): void => {
    if (!currentId) return;
    const formData = data as FormData;
    const payload = { id: currentId, amount: formData.amount * 100 };
    setLoading(true);
    replenishBalance(payload)
      .then(closeModal)
      .catch(errorHandler);
  };

  return (
    <Modal
      title='Пополнение баланса курьера'
      footer={false}
      visible={visible}
      onCancel={closeModal}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        <Form.Item name='amount' label='Сумма к зачислению' rules={amountRules}>
          <InputNumber min={MIN_AMOUNT} style={{ width: '100%' }}/>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button htmlType='submit' loading={loading} type='primary'>
            Подтвердить
          </Button>
          <Button htmlType='button' onClick={closeModal} style={{ marginLeft: 10 }}>
            Отменить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
};

const connected = connect(null, mapDispatch)(ReplenishBalance);
export { connected as ReplenishBalance }
