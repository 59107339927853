import React, { ChangeEvent, FunctionComponent } from 'react';
import ReactInputMask from 'react-input-mask';

import moment, { Moment } from 'moment';
import 'moment/locale/ru';

import { Input, DatePicker } from 'antd';
import { InputProps } from 'antd/lib/input';

import { formatCurrency } from '../utils/strings';
import { Currency } from '../api/protocol';

type RoubleInputProps = {
  value?: number;
  onChange?: (value: string) => void;
  placeholder?: string
  className?: string
  disabled?: boolean;
  currency?: Currency;
  min?: number;
}

export const CurrencyInput = (props: RoubleInputProps): JSX.Element => {
  return (
    <Input
      className={props.className}
      type="number"
      min={props.min || 0}
      inputMode="numeric"
      // force use of given currency or from user's settings
      addonBefore={formatCurrency('sign', props.currency)}
      value={props.value ? (parseFloat(props.value.toString()) / 100) : (props.min || '')}
      disabled={props.disabled}
      onChange={e => {
        const { value } = e.target;
        const val = (parseFloat(value) * 100).toString();
        if (props.onChange) props.onChange(val);
      }}
    />
  );
};
export const PercentInput = (props: InputProps): JSX.Element => (
  <Input
    {...props}
    type="number"
    min={1}
    max={100}
    addonBefore='%'
  />
);

export const KmInput = (props: InputProps): JSX.Element => (
  <Input
    {...props}
    type="number"
    min={1}
    addonBefore='km'
  />
);

type PhoneInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  addonBefore?: React.ReactNode;
  placeholder?: string
}

export const PhoneInput: FunctionComponent<PhoneInputProps> = props => {
  const { value = '', onChange = () => {}, addonBefore, placeholder } = props;
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    onChange(event.target.value.replace(/\D*/g, ''));
  };

  const InputMask = <ReactInputMask
    className="ant-input"
    mask="+7 (999) 999-99-99"
    value={value}
    onChange={onChangeHandler}
    placeholder={placeholder}
  />;

  return (
    <span className="ant-input-group-wrapper">
      <span className="ant-input-wrapper ant-input-group">
        {
          addonBefore &&
          <span className="ant-input-group-addon">
            {addonBefore}
          </span>
        }
        {InputMask}
      </span>
    </span>
  );
};

const ApiDateFormat = 'YYYY.MM.DD';
const dayModifier = (date: Moment, endOfDay: boolean): Moment => endOfDay
  ? date.endOf('day')
  : date.startOf('day');

type DateInputProps = {
  value?: Moment | null,
  onChange?: (value: string | undefined | null) => void,
  placeholder?: string
  outputFormat?: 'apiDate' | 'iso8601'
  endOfDay?: boolean
  defaultDate?: Moment
  minDate?: Moment
}

export const DateInput: FunctionComponent<DateInputProps> = props => {
  const { value, onChange, placeholder, defaultDate, minDate, outputFormat = 'apiDate', endOfDay = false } = props;

  const format = outputFormat === 'apiDate'
    ? ApiDateFormat
    : undefined;

  const defaultValue = value
    ? dayModifier(moment(value, format), endOfDay)
    : defaultDate;

  const onPickerChange = (val: Moment | null): void => {
    if (!onChange) return;
    const date = val ? dayModifier(val, endOfDay).format(format) : undefined;
    onChange(date);
  };

  return <DatePicker
    format='DD MMMM YYYY'
    defaultValue={defaultValue}
    onChange={onPickerChange}
    style={{ width: '100%' }}
    placeholder={placeholder}
    dropdownClassName='HideNowButton'
    hideDisabledOptions
    showToday={false}
    disabledDate={now => moment.isMoment(minDate) && now.isBefore(minDate)}
  />
};

export const DateTimeInput: FunctionComponent<DateInputProps> = props => {
  const { onChange, placeholder, defaultDate, minDate } = props;
  return (
    <DatePicker
      format='DD MMMM YYYY [в] HH:mm'
      defaultValue={defaultDate}
      onChange={val => { if (onChange) onChange(val?.startOf('minute').format() || null) }}
      style={{ width: '100%' }}
      showTime={{ format: 'HH:mm' }}
      placeholder={placeholder}
      dropdownClassName='HideNowButton'
      hideDisabledOptions
      disabledDate={now => moment.isMoment(minDate) && now.isBefore(minDate)}
    />
  );
};
