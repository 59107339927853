import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';

import {
  BankOutlined,
  IdcardOutlined,
  FileDoneOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Divider,
  Input,
  Select,
  Radio,
} from 'antd';
import 'antd/es/date-picker/style/index';
import { FieldError, FieldData } from 'rc-field-form/lib/interface';
import ReactInputMask from 'react-input-mask';

import { debounce } from 'lodash-es';
import moment, { Moment } from 'moment';

import { pipe } from 'fp-ts/es6/pipeable';
import { toNullable } from 'fp-ts/es6/Option';
import { array, head } from 'fp-ts/lib/Array';
import { Lens, fromTraversable } from 'monocle-ts';

import { RootState } from '../../store/root';
import {
  apiCustomerOrderCreate,
  apiCustomerOrderCalculate,
  apiOperatorsOrderCreate,
  apiOperatorsOrderCalculate,
  makeMapDispatch,
} from '../../store/dispatcher';
import {
  CalculateData,
  CustomersOrderCreateRequest,
  OperatorsOrderCreateRequest,
} from '../../api/protocol';

import {
  CustomerCalculateOrderDecoder,
  OperatorsCalculateOrderDecoder,
  createValidatorFromDecoder,
} from '../../schema/orderCreate';

import { LoaderAdaptive } from '../../components/Loader';
import { SearchAddressInput } from '../../components/AddressSearchInput/AddressSearchInput';
import { DateInput, PhoneInput, CurrencyInput } from '../../components/Inputs';
import {
  SearchInput,
  Option,
  Phones,
  reducePhones,
  mapBranches,
} from '../../components/SearchInput';

import { clearFormData } from '../../utils/form';
import { bindErrorHandler } from '../../utils/noty';
import { formatCurrency } from '../../utils/strings';
import { formatPercents, formatPrice } from '../../utils/view';

import './CreateOrder.scss';

type AddressOption = 'branch' | 'address';

const required = [{ required: true, message: 'Пожалуйста, заполните поле.' }];

const fieldsErrorLens = fromTraversable(array)<FieldError>().composeLens(
  Lens.fromProp<FieldError>()('errors')
);
const fieldsDataLens = fromTraversable(array)<FieldData>()
  .filter(item => item.touched || false)
  .composeLens(Lens.fromProp<FieldData>()('errors'));

type StateProps = {
  isOperator: boolean;
  isCustomer: boolean;
  defaultBranches: Option[];
  defaultPhones: Phones;
};
const mapState = (state: RootState): StateProps => {
  const isOperator = state.user.role === 'operator';
  const isCustomer = state.user.role === 'customer';
  let defaultBranches: Option[] = [];
  let defaultPhones: Phones = {};

  if (state.user.profile?.kind === 'customer') {
    const { branches } = state.user.profile;
    defaultBranches = mapBranches(branches);
    defaultPhones = reducePhones(branches);
  }

  return { isOperator, isCustomer, defaultBranches, defaultPhones };
};

const RangeDefaults = {
  StartAt: 30,
  EndsAt: 60,
};

const mapDispatch = makeMapDispatch({
  customerOrderCalculate: apiCustomerOrderCalculate,
  operatorOrderCalculate: apiOperatorsOrderCalculate,
  customerOrderCreate: apiCustomerOrderCreate,
  operatorOrderCreate: apiOperatorsOrderCreate,
});

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const CreateOrder: FunctionComponent<Props> = props => {
  const {
    customerOrderCalculate,
    operatorOrderCalculate,
    customerOrderCreate,
    operatorOrderCreate,
    isOperator,
    defaultBranches,
    defaultPhones,
  } = props;

  const history = useHistory();
  const [formRef] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);

  const [recipientAddress, setRecipientAddress] = useState<string | undefined>(
    ''
  );
  const [senderAddress, setSenderAddress] = useState<string | undefined>('');

  const pickupMoment = moment().clone().add(RangeDefaults.StartAt + 1, 'minutes');
  const [pickupDate, setPickupDate] = useState(pickupMoment);
  const [pickupTime, setPickupTime] = useState(pickupMoment.format('HH:mm'));

  const deliveryMoment = moment().clone().add(RangeDefaults.EndsAt + 1, 'minutes');
  const [deliveryDate, setDeliveryDate] = useState(deliveryMoment);
  const [deliveryTime, setDeliveryTime] = useState(deliveryMoment.format('HH:mm'));

  const [phones, setPhones] = useState(defaultPhones);
  const [branches, setBranches] = useState(defaultBranches);
  const [addressType, setAddressType] = useState<AddressOption>('branch');
  const [calculateData, setCalculateData] = useState<CalculateData | null>(
    null
  );
  const [calculatedOnce, setCalculatedOnce] = useState(false);

  const errorHandler = bindErrorHandler(formRef, () => setLoading(false));

  const setDefaultPhone = useCallback(
    (customerBranches: Option[], customerPhones: Phones): void => {
      const firstBranch = pipe(customerBranches, head, toNullable);
      if (addressType === 'branch' && firstBranch) {
        formRef.setFieldsValue({
          branch_id: firstBranch.value,
          phone: customerPhones[firstBranch.value],
        });
      }
    },
    [formRef, addressType]
  );

  const getPickupFullDate = (): Moment =>
    moment(
      `${pickupDate.format('YYYY-MM-DD')} ${pickupTime || '00:00'}:00`
    ).clone();
  const getDeliveryFullDate = (): Moment =>
    moment(
      `${deliveryDate.format('YYYY-MM-DD')} ${deliveryTime || '00:00'}:00`
    ).clone();

  const checkDates = (): string | false => {
    const pickup = getPickupFullDate();
    const delivery = getDeliveryFullDate();
    const diff = pickup.clone().diff(delivery, 'minutes');

    if (
      Number.isNaN(diff) ||
      !pickupDate ||
      !pickupTime ||
      !deliveryDate ||
      !deliveryTime
    ) {
      return 'Заполните все поля с датой доставки';
    }

    if (
      pickup.isBefore(
        moment()
          .clone()
          .add(RangeDefaults.StartAt - 1, 'minutes')
      )
    ) {
      return 'Дата, когда необходимо забрать заказ должна быть больше, чем текущее время минимум на 30 мин.';
    }

    if (diff <= 0 && diff > RangeDefaults.StartAt * -1) {
      return 'Минимимальный интервал — 30 минут';
    }

    if (delivery.isBefore(pickup)) {
      return 'Дата доставки не может быть раньше даты, когда необходимо забрать заказ';
    }

    return false;
  };

  const checkAddresses = (): boolean => {
    if (addressType === 'address' && senderAddress?.length === 0) return false;
    return recipientAddress?.length !== 0;
  };

  const getRawDeliveryPrice = (): number => {
    return Number(formRef.getFieldValue('delivery_price'));
  };

  const getDeliveryPrice = (): number => {
    const value = getRawDeliveryPrice();
    const deliveryPrice = calculateData ? calculateData.delivery_price : 0;
    return value && value > 0 ? value : deliveryPrice;
  };

  const getFieldsWithFormattedData = (): Record<string, any> => {
    const clearedFormData = clearFormData(formRef.getFieldsValue());
    const result: Record<string, any> = {
      ...clearedFormData,
      to_pick_up_at: getPickupFullDate().format(),
      to_deliver_at: getDeliveryFullDate().format(),
      delivery_price: getRawDeliveryPrice() || undefined,
      recipient: {
        ...clearedFormData.recipient,
        place_id: recipientAddress,
      },
    };

    if (senderAddress !== undefined && senderAddress.length > 0) {
      result.sender_place_id = senderAddress;
    }

    return result;
  };

  useEffect(() => {
    const branch = pipe(defaultBranches, head, toNullable);
    if (branch) {
      setPhones(defaultPhones);
      setBranches(defaultBranches);
      formRef.setFields([{ name: 'branch_id', value: branch.value }]);
      setDefaultPhone(defaultBranches, defaultPhones);
    }
  }, [defaultBranches, formRef, defaultPhones, setDefaultPhone]);

  const onCalculate = async (): Promise<void> => {
    if (loading || checkDates() || !checkAddresses()) return;

    // Reset form errors
    pipe(formRef.getFieldsError(), fieldsErrorLens.set([]), formRef.setFields);

    const payload = getFieldsWithFormattedData();

    try {
      let calculateDetails;
      if (isOperator)
        calculateDetails = await operatorOrderCalculate(
          payload as OperatorsOrderCreateRequest
        );
      else
        calculateDetails = await customerOrderCalculate(
          payload as CustomersOrderCreateRequest
        );

      if (!payload.delivery_price) {
        formRef.setFieldsValue({
          delivery_price: calculateDetails.recommended_delivery_price,
        });
      }

      if (!calculatedOnce) setCalculatedOnce(true);
      setCalculateData(calculateDetails);
    } catch (e) {
      errorHandler(e);
    } finally {
      setLoading(false);
    }
  };

  const validateCalcData = useCallback(
    debounce(
      () => {
        const canCalculate = pipe(
          formRef.getFieldsValue(),
          clearFormData,
          createValidatorFromDecoder(
            isOperator
              ? OperatorsCalculateOrderDecoder
              : CustomerCalculateOrderDecoder
          )
        );

        if (canCalculate) {
          // noinspection JSIgnoredPromiseFromCall
          onCalculate().finally(() => {
            setLoading(false);
          });
        }
      },
      1000,
      { leading: false, trailing: true }
    ),
    [
      formRef,
      isOperator,
      OperatorsCalculateOrderDecoder,
      CustomerCalculateOrderDecoder,
      calculatedOnce,
    ]
  );

  const onCreate = async (): Promise<void> => {
    if (loading || checkDates() || !checkAddresses()) return;
    setCreating(true);

    const payload = getFieldsWithFormattedData();

    try {
      if (isOperator)
        await operatorOrderCreate(payload as OperatorsOrderCreateRequest);
      else await customerOrderCreate(payload as CustomersOrderCreateRequest);

      history.push('/orders');
    } catch (e) {
      setCreating(false);
      errorHandler(e);
    }
  };

  const initDateTimeFields = (): void => {
    if (checkDates()) {
      const newPickupMoment = moment().clone().add(RangeDefaults.StartAt + 1, 'minutes');
      setPickupDate(newPickupMoment);
      setPickupTime(newPickupMoment.format('HH:mm'));

      const newDeliveryMoment = moment().clone().add(RangeDefaults.EndsAt + 1, 'minutes');
      setDeliveryDate(newDeliveryMoment);
      setDeliveryTime(newDeliveryMoment.format('HH:mm'));
    }
  };

  const onFieldsChange = (fields: FieldData[]): void => {
    const touchedFields = pipe(fields, fieldsDataLens.asFold().getAll);
    if (touchedFields.length) {
      initDateTimeFields();

      pipe(fields, fieldsDataLens.set([]), formRef.setFields);
      validateCalcData();
    }
  };

  const onSelectCustomer = (
    customerBranches: Option[],
    customerPhones: Phones
  ): void => {
    setBranches(customerBranches);
    setPhones(customerPhones);
    initDateTimeFields();
    setDefaultPhone(customerBranches, customerPhones);
  };

  const calculateCourierProfit = (): number => {
    if (calculateData === null) return 0;
    return (
      getDeliveryPrice() * (calculateData.courier_commission_percent / 100)
    );
  };

  if (creating) return <LoaderAdaptive text="Создание заказа" />;

  return (
    <section className="AppSection CreateOrder">
      <Form
        layout="vertical"
        form={formRef}
        onFinish={() => {
          setLoading(true);
          onCalculate().finally(() => setLoading(false));
        }}
        onFieldsChange={onFieldsChange}
        scrollToFirstError
        initialValues={{
          is_paid: false,
          order_price: '0',
        }}
      >
        <div className="CreateOrder__grid">
          {/* "From" section */}
          <Card>
            <h2>
              <BankOutlined style={{ marginRight: 8 }} />
              Откуда
            </h2>
            <Divider />

            {isOperator && (
              <Form.Item
                label="Организация"
                name="customer_id"
                rules={required}
              >
                <SearchInput onSelectCustomer={onSelectCustomer} />
              </Form.Item>
            )}

            <Form.Item
              label="Выбор адреса"
              className="CreateOrder__addressType"
            >
              <Radio.Group
                value={addressType}
                onChange={type =>
                  setAddressType(type.target.value as AddressOption)
                }
              >
                <Radio.Button value="branch">Из списка</Radio.Button>
                <Radio.Button value="address">Вручную</Radio.Button>
              </Radio.Group>
            </Form.Item>

            {addressType === 'branch' ? (
              <Form.Item
                label="Адрес организации"
                name="branch_id"
                className="CreateOrder__address"
              >
                <Select
                  options={branches}
                  disabled={branches.length === 0}
                  onSelect={val =>
                    formRef.setFieldsValue({ phone: phones[val as string] })
                  }
                  defaultActiveFirstOption
                  placeholder="Выберите филиал"
                />
              </Form.Item>
            ) : (
              <Form.Item label="Адрес отправителя" name="sender_place_id">
                <SearchAddressInput
                  setOuterValue={value => setSenderAddress(value)}
                />
              </Form.Item>
            )}

            <Form.Item label="Телефон" name="phone" rules={required}>
              <PhoneInput />
            </Form.Item>

            <div className="CreateOrder__groupWrapper">
              <Form.Item label="Когда забрать (дата)" rules={required}>
                <DateInput
                  placeholder="Выберите дату и время"
                  minDate={moment().startOf('day')}
                  outputFormat="iso8601"
                  value={pickupDate}
                  onChange={value => {
                    if (!value) return;
                    setPickupDate(moment(value).clone());
                    if (moment(value).diff(deliveryDate, 'days') !== 0)
                      setDeliveryDate(moment(value).clone());
                  }}
                />
              </Form.Item>

              <Form.Item label="Когда забрать (время)" rules={required}>
                <ReactInputMask
                  className="ant-input"
                  mask="99:99"
                  value={pickupTime}
                  onChange={({ target }) => {
                    setPickupTime(target.value);
                  }}
                />
              </Form.Item>
            </div>

            <div className="CreateOrder__groupWrapper">
              <Form.Item label="Когда доставить (дата)" rules={required}>
                <DateInput
                  placeholder="Выберите дату и время"
                  defaultDate={moment()}
                  outputFormat="iso8601"
                  value={deliveryDate}
                  minDate={pickupDate}
                  onChange={value => {
                    if (!value) return;
                    setDeliveryDate(moment(value));
                  }}
                />
              </Form.Item>

              <Form.Item label="Когда доставить (время)" rules={required}>
                <ReactInputMask
                  className="ant-input"
                  mask="99:99"
                  value={deliveryTime}
                  onChange={({ target }) => {
                    setDeliveryTime(target.value);
                  }}
                />
              </Form.Item>
            </div>

            {checkDates() && (
              <div className="CreateOrder__dateError ant-form-item-has-error">
                <div className="ant-form-item-explain">{checkDates()}</div>
              </div>
            )}

            <Form.Item label="Комментарий" name="comment">
              <Input.TextArea />
            </Form.Item>
          </Card>

          <Card>
            <h2>
              <IdcardOutlined style={{ marginRight: 8 }} />
              Клиент
            </h2>
            <Divider />
            <Form.Item
              label="Имя"
              name={['recipient', 'name']}
              rules={required}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Телефон"
              name={['recipient', 'phone']}
              rules={required}
            >
              <PhoneInput />
            </Form.Item>

            <Form.Item
              label="Адрес"
              name={['recipient', 'place_id']}
              rules={required}
            >
              <SearchAddressInput
                setOuterValue={value => setRecipientAddress(value)}
              />
            </Form.Item>

            <Row gutter={24}>
              <Col className="CreateOrder__formColumn toThree">
                <Form.Item label="Подъезд" name={['recipient', 'entrance']}>
                  <Input />
                </Form.Item>
              </Col>
              <Col className="CreateOrder__formColumn toThree">
                <Form.Item label="Этаж" name={['recipient', 'floor']}>
                  <Input />
                </Form.Item>
              </Col>
              <Col className="CreateOrder__formColumn toThree">
                <Form.Item label="Квартира" name={['recipient', 'apartment']}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Комментарий" name={['recipient', 'comment']}>
              <Input.TextArea />
            </Form.Item>
          </Card>

          {/* Payment section */}
          <Card>
            <h2>
              <WalletOutlined style={{ marginRight: 8 }} />
              Оплата
            </h2>
            <Divider />

            <Form.Item
              name="is_paid"
              className="CreateOrder__payedStatusSwitcher"
              label="Заказ оплачен?"
              rules={required}
            >
              <Radio.Group>
                <Radio.Button value>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Row gutter={24}>
              <Col className="CreateOrder__formColumn">
                <Form.Item
                  label="Сумма заказа"
                  name="order_price"
                  rules={required}
                >
                  <CurrencyInput />
                </Form.Item>
              </Col>
              {calculateData && calculatedOnce && (
                <Col className="CreateOrder__formColumn">
                  <Form.Item
                    label="Стоимость доставки"
                    name="delivery_price"
                  >
                    <CurrencyInput />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              className="CreateOrder__actionButton"
            >
              Рассчитать
            </Button>
          </Card>

          {/* Summary section */}
          {calculateData && calculatedOnce && (
            <Card loading={loading}>
              <h2>
                <FileDoneOutlined style={{ marginRight: 8 }} />
                Итого
              </h2>
              <Divider />
              <p className="CreateOrder__paragraph">
                Выкуп:{' '}
                <b>
                  {formatPrice(calculateData.order_price.toString())}{' '}
                  {formatCurrency()}
                </b>
              </p>
              <p className="CreateOrder__paragraph">
                Стоимость доставки:{' '}
                <b>
                  {formatPrice(getDeliveryPrice().toString())}{' '}
                  {formatCurrency()}
                </b>
              </p>
              {isOperator && (
                <>
                  <p className="CreateOrder__paragraph">
                    Комиссия сервиса:{' '}
                    <b>
                      {formatPrice(
                        String(getDeliveryPrice() - calculateCourierProfit())
                      )}{' '}
                      {formatCurrency()}
                    </b>
                  </p>

                  <p className="CreateOrder__paragraph">
                    Выручка курьера:{' '}
                    <b>
                      {formatPrice(calculateCourierProfit().toString())}{' '}
                      {formatCurrency()}
                    </b>
                  </p>

                  <p className="CreateOrder__paragraph">
                    % курьеру от доставки:{' '}
                    <b>
                      {formatPercents(calculateData.courier_commission_percent)}{' '}
                      %
                    </b>
                  </p>
                </>
              )}
              <p className="CreateOrder__paragraph">
                Расстояние: <b>{calculateData.distance_km.toFixed(2)} км</b>
              </p>
              <Button
                size="large"
                type="primary"
                htmlType="button"
                onClick={onCreate}
                loading={loading}
                disabled={loading}
                className="CreateOrder__actionButton"
              >
                Создать заказ
              </Button>
            </Card>
          )}
        </div>
      </Form>
    </section>
  );
};

const component = withRouter(connect(mapState, mapDispatch)(CreateOrder));

export { component as CreateOrder };
