import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Button } from 'antd';

import './NotFound.scss';

export const NotFound: FunctionComponent = () => {
  const { push } = useHistory();
  return (
    <div className='NotFound'>
      <Result
        status="404"
        title="К сожалению, такой страницы не существует"
        extra={<Button type="primary" onClick={() => push('/')}>На главную</Button>}
      />
    </div>
  );
};
