import { Reducer } from 'redux';

import { fromTraversable, Getter, Lens } from 'monocle-ts';

import { pipe } from 'fp-ts/es6/pipeable';
import { array, flatten, reduce } from 'fp-ts/lib/Array';
import { deleteAt, insertAt, record } from 'fp-ts/lib/Record';

import {
  CouriersActionTypes,
  CouriersListTypes,
  CouriersState,
  CouriersPaginateLikeItem,
  CouriersLocationCacheList, CouriersLocationList,
} from './types';
import {
  CourierLocation,
  CouriersDetailsResponse,
  CouriersListItem,
  CouriersOnlineLocationsResponse,
  CouriersTransactionsListResponse,
} from '../../api/protocol';
import { optional } from '../../utils/types';

const initialState: CouriersState = {
  details: {},
  currentList: 'active',
  list: {
    active: { items: {}, page: 1, total: 0, page_size: 0 },
    blocked: { items: {}, page: 1, total: 0, page_size: 0 },
  },
  listExtra: { active: 0, blocked: 0, offline: 0, online: 0 },
  locationList: { items: {}, pages: 1 },
};

const detailsListLens = Lens.fromProp<CouriersState>()('details');
const currentListLens = Lens.fromProp<CouriersState>()('currentList');
const listLens = (node: CouriersListTypes) => Lens.fromPath<CouriersState>()(['list', node]); // eslint-disable-line @typescript-eslint/explicit-function-return-type
const pageLens = Lens.fromProp<CouriersPaginateLikeItem>()('page');
const extraLens = Lens.fromProp<CouriersState>()('listExtra');

const detailsBalanceLens = Lens.fromProp<CouriersDetailsResponse>()('balance');
const detailsTransactionsLens = Lens.fromProp<CouriersDetailsResponse>()('transactions');

const listBalanceLens = (node: CouriersListTypes, id: number) => // eslint-disable-line @typescript-eslint/explicit-function-return-type
  listLens(node)
    .composeLens(Lens.fromProp<CouriersPaginateLikeItem>()('items'))
    .composeTraversal(fromTraversable(record)())
    .composeTraversal(fromTraversable(array)())
    .filter(item => item.id === id)
    .composeLens(Lens.fromProp<CouriersListItem>()('balance'));

const detailsLens = (id: number) => detailsListLens // eslint-disable-line @typescript-eslint/explicit-function-return-type
  .composeTraversal(fromTraversable(record)())
  .filter(item => item.id === id);

const resetBothList = Lens.fromProp<CouriersState>()('list')
  .composeTraversal(fromTraversable(record)())
  .composeLens(Lens.fromProps<CouriersPaginateLikeItem>()(['items', 'page_size', 'total']))
  .set({ items: {}, total: 0, page_size: 0 });

const responsesTraversable = fromTraversable(array)<CouriersOnlineLocationsResponse>();
const itemsLens = Lens.fromProp<CouriersOnlineLocationsResponse>()('items');

const totalPagesGetter = new Getter<CouriersOnlineLocationsResponse, number>(response =>
  Math.max(Math.ceil(response.total / response.page_size), 1),
);

const foldLocationResponses = (responses: CouriersOnlineLocationsResponse[], list: CouriersLocationList): CouriersLocationCacheList => {
  const pages = pipe(
    responses,
    responsesTraversable.composeGetter(totalPagesGetter).getAll,
    items => items[items.length -1] || 1
  );

  const items = pipe(
    responses,
    responsesTraversable
      .composeLens(itemsLens)
      .asFold()
      .getAll,
    flatten,
    reduce<CourierLocation, CouriersLocationList>(list, (cache, location) => {
      const key = location.id.toString();
      const callee = location.is_online ? insertAt(key, location) : deleteAt(key);
      return callee(cache);
    }),
  );

  return { pages, items };
};

const locationLens = Lens.fromProp<CouriersState>()('locationList');

export const couriersReducer: Reducer<CouriersState, CouriersActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case 'COURIERS_SET_LIST_ITEM': {
      const lens = listLens(action.payload.selector);
      const { items: cachedItems } = lens.get(state);
      const {
        items: pageItems,
        number_of_active: active,
        number_of_blocked: blocked,
        number_of_offline: offline,
        number_of_online: online,
        ...meta
      } = action.payload.response;
      const items = { ...cachedItems, [meta.page]: pageItems };
      return pipe(
        state,
        lens.set({ items, ...meta }),
        extraLens.set({ active, blocked, offline, online }),
        currentListLens.set(action.payload.selector),
      );
    }
    case 'COURIERS_SET_LIST_PAGE':
      return listLens(state.currentList).compose(pageLens).set(action.payload)(state);
    case 'COURIERS_SET_CURRENT_LIST':
      return currentListLens.set(action.payload)(state);
    case 'COURIERS_SET_EMPTY_LIST':
      return pipe(
        state,
        resetBothList,
        extraLens.set({ active: 0, blocked: 0, offline: 0, online: 0 }),
      );
    case 'COURIERS_SET_DETAILS':
      return detailsListLens.set({ ...detailsListLens.get(state), [action.payload.id]: action.payload })(state);
    case 'COURIERS_REPLENISH_BALANCE': {
      const { id, balance, transactions } = action.payload;
      const lens = detailsLens(id);

      return pipe(
        state,
        optional(
          transactions !== null,
          lens.composeLens(detailsTransactionsLens).set(transactions as CouriersTransactionsListResponse)),
        lens.composeLens(detailsBalanceLens).set(balance),
        listBalanceLens(state.currentList, id).set(action.payload.balance),
      );
    }
    case 'COURIERS_SET_EMPTY_DETAILS':
      return detailsListLens.set({})(state);
    case 'COURIERS_SET_LOCATION_RESULT':
      return locationLens.modify(locations => foldLocationResponses(action.payload, locations.items))(state);
    default:
      return state;
  }
};
