import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/es6/pipeable';

import { Input, Modal } from 'antd';

import { RootState } from '../../store/root';
import { OrderStatus } from '../../api/protocol';
import { apiCustomerOrderCancel, apiOperatorsOrderCancel, makeMapDispatch } from '../../store/dispatcher';

import { useBusEffect } from '../../utils/bus';

type CancelOrderPayload = {
  id: number,
  kind: OrderStatus
}

declare module '../../utils/bus' {
  interface BusEvents {
    cancelOrder: CancelOrderPayload
  }
}

type StateProps = { isOperator: boolean }
const mapState = (state: RootState): StateProps => ({ isOperator: state.user.role === 'operator' });
const mapActions = makeMapDispatch({
  customerCancelOrder: apiCustomerOrderCancel,
  operatorCancelOrder: apiOperatorsOrderCancel,
});

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapActions> & { afterCancel?: () => void }
const OrderCancel: FunctionComponent<Props> = props => {
  const { isOperator, customerCancelOrder, operatorCancelOrder, afterCancel } = props;

  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [orderInfo, setOrderInfo] = useState<CancelOrderPayload | null>(null);

  useBusEffect('cancelOrder', payload => {
    setVisible(true);
    setOrderInfo(payload);
  });

  const onCancel = (): void => {
    setOrderInfo(null);
    setVisible(false);
    setValue('');
  };

  const onConfirm = async (): Promise<void> => {
    if (loading || orderInfo === null) return;

    const { id, kind } = orderInfo;
    setLoading(true);
    if (isOperator) await operatorCancelOrder({ order_id: id }, kind);
    else await customerCancelOrder({ order_id: id, reason: value }, kind);

    onCancel();
    if (afterCancel) afterCancel();
  };

  return (
    <Modal
      onOk={onConfirm}
      title='Подверждение отмены заказа'
      width={340}
      okText='Подтвердить'
      visible={visible}
      onCancel={onCancel}
      okButtonProps={{ danger: true, loading, disabled: !isOperator && value.length < 3 }}
    >
      <div className='OrderCancel'>
        {
          isOperator ?
            <p className='OrderCancel__reasonTitle'>Вы действительно хотите отменить заказ?</p> :
            <div className='OrderCancel__reason'>
              <p className='OrderCancel__reasonTitle'>Укажите причину отмены заказа</p>
              <Input.TextArea
                maxLength={255}
                value={value}
                style={{ width: '100%' }}
                onChange={event => setValue(event.target.value)}
              />
            </div>
        }
      </div>
    </Modal>
  )
};

const component = pipe(
  OrderCancel,
  connect(mapState, mapActions),
);

export { component as OrderCancel }
