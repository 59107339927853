import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { Form } from 'antd';

import { CouriersDetailsResponse, CouriersUpdateRequest } from '../api/protocol';
import { makeMapDispatch, apiCourierUpdate, apiCouriersGet } from '../store/dispatcher';
import { RootState } from '../store/root';

import { bindErrorHandler, showApiErrorNotification } from '../utils/noty';

import { LoaderAdaptive } from '../components/Loader';
import { CouriersForm } from '../components/CouriersForm/CouriersForm';

type StateProps = { cacheList: RootState['couriers']['details'] };
const mapState = (state: RootState): StateProps => ({ cacheList: state.couriers.details });
const mapActions = makeMapDispatch({
  getCourier: apiCouriersGet,
  updateCourier: apiCourierUpdate,
});
type Props = ReturnType<typeof mapState> & ReturnType<typeof mapActions>;
const CouriersUpdate: FunctionComponent<Props> = props => {
  const { getCourier, updateCourier, cacheList } = props;

  const params = useParams<{ id: string | undefined }>();
  const id = params.id ? parseInt(params.id, 10) : null;
  const { push: redirectTo } = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [courier, setCourier] = useState<CouriersDetailsResponse>();
  const [initializing, setInitializing] = useState(false);

  const errorHandler = bindErrorHandler(form, () => { setLoading(false) });
  const redirectToList = useCallback(() => {
    setLoading(false);
    redirectTo('/couriers');
  }, [redirectTo]);

  const handleUpdate = (data: unknown): void => {
    const payload = data as CouriersUpdateRequest;
    setLoading(true);

    // Clear password field
    const { password, ...userData } = payload.user;
    if (password === null) payload.user = userData;

    updateCourier(payload).then(redirectToList).catch(errorHandler);
  };

  useEffect(() => {
    if (id) {
      if (id in cacheList) setCourier(cacheList[id]);
      else {
        setInitializing(true);
        getCourier({ id })
          .then(data => {
            setCourier(data);
            setInitializing(false);
          })
          .catch(error => {
            showApiErrorNotification(error);
            redirectToList();
          });
      }
    }
  }, [id, setCourier, redirectToList, cacheList]); // eslint-disable-line react-hooks/exhaustive-deps

  if (initializing || (!courier)) return <LoaderAdaptive/>;

  return (
    <CouriersForm
      form={form}
      loading={loading}
      onBack={redirectToList}
      onSubmit={handleUpdate}
      courierData={courier}
      isUpdate
    />
  );
};

const connected = withRouter(connect(mapState, mapActions)(CouriersUpdate));
export { connected as CouriersUpdate };
