import React, { FunctionComponent } from 'react';
import moment from 'moment';

import { Empty, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { OrderStatisticsItem } from '../../api/protocol';

import {
  formatDate,
  formatPhone,
  formatPrice,
  formatRecipient,
} from '../../utils/view';
import { formatCurrency } from '../../utils/strings';

import './StatisticsTable.scss';

const columnsConfig: ColumnsType<OrderStatisticsItem> = [
  {
    key: 'id',
    title: '№ заказа',
    dataIndex: 'id',
    render: (id: number) => (
      <div className="ResponsiveTable__idWrapper">
        <h3 className="ResponsiveTable__mobileHeading">№ заказа:</h3>
        <Tag color="red" className="ResponsiveTable__id">
          {id}
        </Tag>
      </div>
    ),
  },
  {
    key: 'courierName',
    title: 'Курьер',
    render: ({ courier }: OrderStatisticsItem) =>
      courier.full_name ? (
        <>
          <h3 className="ResponsiveTable__mobileHeading">Курьер</h3>
          <p className="OrdersTable__courierName">{courier.full_name}</p>
        </>
      ) : (
        <Tag>Не назначен</Tag>
      ),
  },
  {
    key: 'customerName',
    title: 'Заведение',
    render: ({ customer }: OrderStatisticsItem) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">Заведение</h3>
        <p className="OrdersTable__organizationTitle">{customer.name}</p>
      </>
    ),
  },
  {
    title: 'Дата создания',
    key: 'createdAt',
    render: ({ created_at: ca }: OrderStatisticsItem) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">Дата создания</h3>
        <p className="OrdersTable__deliveryTimeFrom">
          {formatDate(moment(ca))}
        </p>
      </>
    ),
  },
  {
    title: 'Клиент',
    key: 'recipient',
    render: ({ recipient }: OrderStatisticsItem) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">Клиент</h3>
        <p className="OrdersTable__clientName">{recipient.name}</p>
        <p className="OrdersTable__clientAddress">
          {formatRecipient(recipient)}
        </p>
        <a
          href={`tel:+${recipient.phone}`}
          className="OrdersTable__clientPhone"
        >
          {formatPhone(recipient.phone)}
        </a>
      </>
    ),
  },
  {
    title: 'Стоимость',
    key: 'amounts',
    render: ({ order_price: op, delivery_price: dp }: OrderStatisticsItem) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">Стоимость</h3>
        <p className="OrdersTable__deliveryPrice">
          Заказа{' '}
          <strong>
            {formatPrice(op.toString())} {formatCurrency()}
          </strong>
        </p>
        <p className="OrdersTable__deliveryPrice">
          Доставки{' '}
          <strong>
            {formatPrice(dp.toString())} {formatCurrency()}
          </strong>
        </p>
      </>
    ),
  },
  {
    title: 'Выручка',
    key: 'commissions',
    render: ({
      service_commission: sc,
      courier_commission: cc,
    }: OrderStatisticsItem) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">Комиссия</h3>
        <p className="OrdersTable__deliveryPrice">
          Франчайзи{' '}
          <strong>
            {formatPrice(sc.toString())} {formatCurrency()}
          </strong>
        </p>
        <p className="OrdersTable__deliveryPrice">
          Курьера{' '}
          <strong>
            {formatPrice(cc.toString())} {formatCurrency()}
          </strong>
        </p>
      </>
    ),
  },
];

type Props = {
  data: OrderStatisticsItem[];
  loading: boolean;
  paginator: Paginator;
  onPageChange: (page: number) => void;
};
export const StatisticsTable: FunctionComponent<Props> = props => {
  const { data, paginator, onPageChange, loading } = props;

  return (
    <Table
      className="StatisticsTable ResponsiveTable"
      rowKey={({ id }: OrderStatisticsItem) => id}
      loading={loading}
      columns={columnsConfig}
      onChange={pagination => {
        onPageChange(pagination.current || 1);
      }}
      dataSource={data}
      pagination={{
        total: paginator.total,
        current: paginator.page,
        pageSize: paginator.page_size,
      }}
      locale={{
        emptyText: (
          <Empty
            description="Список заказов пуст"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ),
      }}
    />
  );
};
