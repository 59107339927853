import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Empty, Table, Tag, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import { EventBus } from '../../utils/bus';
import { formatPhone } from '../../utils/view';

import { OperatorWithStats } from '../../api/protocol';

import './OperatorsTable.scss';

const buildColumnsConfig = (
  onEditClick: (id: number) => void,
): ColumnsType<OperatorWithStats> => [
  {
    title: '№',
    key: 'id',
    dataIndex: 'id',
    render: (id: number) => (
      <div className='ResponsiveTable__idWrapper'>
        <h3 className='ResponsiveTable__mobileHeading'>№</h3>
        <Tag color='red' className='ResponsiveTable__id'>
          {id}
        </Tag>
      </div>
    ),
  },
  {
    title: 'Город подключения',
    key: 'city',
    render: ({ city }: OperatorWithStats) => (
      <>
        <h3 className='ResponsiveTable__mobileHeading'>Город подключения</h3>
        <p className='OrdersTable__organizationTitle'>{city.name}</p>
      </>
    ),
  },
  {
    title: 'ФИО',
    key: 'name',
    render: ({ name, phone }: OperatorWithStats) => (
      <>
        <p className='OrdersTable__clientName'>{name || '—'}</p>
        <a href={`tel:+${phone}`} className='OperatorsTable__phone'>
          {formatPhone(phone, '—')}
        </a>
      </>
    ),
  },
  {
    title: 'Контактный телефон',
    key: 'phone',
    render: ({ phone }: OperatorWithStats) => {
      return (
        <>
          {phone ? (
            <a href={`tel:+${phone}`} className='OrdersTable__organizationPhone'>
              {formatPhone(phone, '—')}
            </a>
          ) : (
            <span className='OrdersTable__organizationPhone'>
              {formatPhone(phone)}
            </span>
          )}
        </>
      );
    },
  },
  {
    title: 'Количество организаций',
    key: 'numberOfCustomer',
    render: ({ number_of_customer: numberOfCustomer }: OperatorWithStats) => (
      <>
        <h3 className='ResponsiveTable__mobileHeading'>
          Организации
        </h3>
        <p className='OrdersTable__organizationTitle'>{numberOfCustomer}</p>
      </>
    ),
  },
  {
    title: 'Количество курьеров',
    key: 'numberOfCouriers',
    render: ({ number_of_couriers: numberOfCouriers }: OperatorWithStats) => (
      <>
        <h3 className='ResponsiveTable__mobileHeading'>Курьеры</h3>
        <p className='OrdersTable__organizationTitle'>{numberOfCouriers}</p>
      </>
    ),
  },
  {
    key: 'actions',
    render: ({ id }: OperatorWithStats) => {
      return (
        <div className='OrdersTable__actions'>
          <Tooltip title='Редактировать' placement='bottom'>
            <Button
              type='primary'
              icon={<EditOutlined />}
              onClick={() => onEditClick(id)}
            />
          </Tooltip>
        </div>
      );
    },
  },
];

type OperatorsTableProps = {
  list: OperatorWithStats[];
  loading: boolean;
  paginator: Paginator;
  onPageChange: (number: number) => void;
};

export const OperatorsTable: FunctionComponent<OperatorsTableProps> = props => {
  const { list, loading, paginator, onPageChange } = props;

  const { push } = useHistory();

  const columnsConfig = buildColumnsConfig(id => push(`/operators/edit/${id}`));

  return (
    <Table
      className='OrdersTable ResponsiveTable ResponsiveTable--operators'
      columns={columnsConfig}
      dataSource={list}
      onRow={record => ({
        onClick: () => EventBus.emit('openOperatorDrawer', record),
      })}
      rowKey={operator => operator.id}
      loading={loading}
      pagination={{
        total: paginator.total,
        current: paginator.page,
        pageSize: paginator.page_size,
      }}
      onChange={pagination => {
        onPageChange(pagination.current || 1);
      }}
      locale={{
        emptyText: (
          <Empty
            description='Список франчайзи пуст'
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ),
      }}
    />
  );
};
