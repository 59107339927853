import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { Form, message } from 'antd';

import { apiCustomerGet, makeMapDispatch, apiCustomerUpdate } from '../store/dispatcher';
import { RootState } from '../store/root';
import { clearFormData } from '../utils/form';
import { CustomerDetailsResponse, CustomerUpdateRequest } from '../api/protocol';
import { bindErrorHandler, showApiErrorNotification } from '../utils/noty';
import { LoaderAdaptive } from '../components/Loader';
import { OrganizationForm } from '../components/OrganizationForm';

type StateProps = { typesList: RootState['customers']['types'] };
const mapState = (state: RootState): StateProps => ({ typesList: state.customers.types });
const mapActions = makeMapDispatch({
  getCustomer: apiCustomerGet,
  updateCustomer: apiCustomerUpdate,
});
type Props = ReturnType<typeof mapState> & ReturnType<typeof mapActions>;
const OrganizationUpdate: FunctionComponent<Props> = props => {
  const { typesList, getCustomer, updateCustomer } = props;

  const params = useParams<{ id: string | undefined }>();
  const id = params.id ? parseInt(params.id, 10) : null;
  const { push: redirectTo } = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<CustomerDetailsResponse>();
  const [initializing, setInitializing] = useState(true);
  const errorHandler = bindErrorHandler(form, () => { setLoading(false) });
  const redirectToList = useCallback(() => {
    setLoading(false);
    redirectTo('/organizations');
  }, [redirectTo]);
  const handleUpdate = async (data: unknown): Promise<void> => {
    setLoading(true);

    const payload = clearFormData(data) as CustomerUpdateRequest;

    // Clear nullable ids props
    payload.branches = payload.branches.map(({ id: branchId, ...branchData }) => {
      return branchId === null
        ? branchData
        : { id: branchId, ...branchData };
    });

    // Set name & phone strictly
    payload.user = { ...payload.user, name: null, phone: null };

    // Clear password field
    const { password, ...userData } = payload.user;
    if (password === null) payload.user = { ...userData };

    try {
      await updateCustomer(payload);
      message.success(`Данные компании "${payload.name}" успешно изменены`);
      redirectToList();
    } catch (e) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    if (id) {
      setInitializing(true);
      getCustomer({ id })
        .then(data => {
          setCustomer(data);
          setInitializing(false);
        })
        .catch(error => {
          showApiErrorNotification(error);
          redirectToList();
        });
    } else setInitializing(false);
  }, [id, getCustomer, redirectToList]);

  if (initializing) return <LoaderAdaptive/>;

  return (
    <OrganizationForm
      form={form}
      loading={loading}
      typesList={typesList}
      onBack={redirectToList}
      onSubmit={handleUpdate}
      customerData={customer}
      isUpdate
    />
  );
};

const connected = withRouter(connect(mapState, mapActions)(OrganizationUpdate));
export { connected as OrganizationUpdate };
