import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import moment, { Moment } from 'moment';

import { Card, Button, Pagination, Modal, Popconfirm, Result, Tag } from 'antd';
import { PlusOutlined, DeleteOutlined, LoadingOutlined, ReadOutlined } from '@ant-design/icons/lib';
import { PresetColorType } from 'antd/es/_util/colors';

import './News.scss';

import { apiNewsList, apiNewsDelete, makeMapDispatch } from '../../store/dispatcher';
import { NewsListState } from '../../store/news/types';
import { NewsListItem } from '../../api/protocol';
import { RootState } from '../../store/root';

import { showApiErrorNotification } from '../../utils/noty';
import { CreateArticleForm } from '../../components/CreateArticleForm';
import { LoaderAdaptive } from '../../components/Loader';
import { useOnDestroy } from '../../hooks/lifecycle';
import { setListPage } from '../../store/news/actions';

type ArticleMeta = { date: Moment, author: string | null }
const ArticleMeta: FunctionComponent<ArticleMeta> = ({ author, date }) => {
  return (
    <span>
      <span className="ArticleCard__date">
        {date.format('DD.MM.YYYY HH:mm')}
      </span>
      {author && (
        <>
          <span className="ArticleCard__separator">|</span>
          <span className="ArticleCard__author">{author}</span>
        </>
      )}
    </span>
  );
};

type CardWrapperProps = { wrap: boolean, label: string, color: PresetColorType, children: ReactNode }
const CardWrapper = (props: CardWrapperProps): JSX.Element => {
  const { wrap, label, color, children } = props;
  if (!wrap) return <>{children}</>;
  return (
    <div className='CardWrapper'>
      <Tag className='CardWrapper__badge' color={color}>
        {label}
      </Tag>
      {children}
    </div>
  );
};

type NewsItemState = { [id: number]: boolean }

type StateProps = {
  newsList: NewsListState
  isOperator: boolean
}
const mapState = (state: RootState): StateProps => ({
  newsList: state.news.list,
  isOperator: state.user.role === 'operator'
});
const mapDispatch = makeMapDispatch({
  deleteArticle: apiNewsDelete,
  getNewsList: apiNewsList,
  setPage: setListPage,
});
type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
const NewsList: FunctionComponent<Props> = props => {
  const { getNewsList, newsList, setPage, deleteArticle, isOperator } = props;

  const [loading, setLoading] = useState(false);
  const [initialize, setInitialize] = useState(true);
  const [isModalVisible, setModalVisibility] = useState(false);

  const [viewItems, setViewItems] = useState<NewsListItem[]>([]);
  const [stateList, setStateList] = useState<NewsItemState>({});
  const [currentPage, setCurrentPage] = useState(newsList.page);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangePage = (page: number): void => {
    if (page in newsList.items) {
      setPage(page);
      setCurrentPage(page);
    } else {
      setLoading(true);
      getNewsList({ page })
        .then(() => {
          setCurrentPage(page);
          setLoading(false);
        })
        .catch(showApiErrorNotification);
    }
  };

  const refresh = (): void => {
    setInitialize(true);
    onChangePage(currentPage);
  };

  const onDelete = (id: number): void => {
    setStateList(h => ({ ...h, [id]: true }));
    deleteArticle({ id })
      .then(refresh)
      .catch(showApiErrorNotification);
  };

  const popconfirmSettings = {
    title: 'Вы действительно хотите удалить новость?',
    okText: 'Да',
    cancelText: 'Нет',
  };

  useOnDestroy(() => { setPage(1); });
  useEffect(() => {
    if (currentPage) {
      const list = newsList.items[currentPage] || [];
      setViewItems(list);
      setStateList(list.reduce<NewsItemState>((state, item) => ({ ...state, [item.id]: false }), {}));
    }
  }, [newsList, currentPage]);
  useEffect(() => {
    if (initialize) {
      onChangePage(currentPage || 1);
      setInitialize(false);
    }
  }, [initialize, currentPage, onChangePage]);

  if (loading && currentPage === 0) return <LoaderAdaptive/>;

  let viewList = (
    <Result
      style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      icon={<ReadOutlined style={{  color: '#001529', fontSize: '48px' }}/>}
      title={newsList.total ? 'Список новостей пуст' : 'Скоро здесь появится первая новость'}
      subTitle={newsList.total ? 'Выберите другую страницу' : null}
    />
  );
  if (loading) viewList = <LoaderAdaptive/>;
  else if (viewItems.length) viewList = (
    <>
      {viewItems.map(article => {
        let actions: ReactNode[] = [];
        if (isOperator) {
          actions = stateList[article.id]
            ? [
              <LoadingOutlined style={{ color: '#001529', cursor: 'not-allowed' }}/>,
            ] : [
              <Popconfirm
                key='delete'
                onConfirm={() => onDelete(article.id)}
                {...popconfirmSettings}
              >
                <DeleteOutlined/>
              </Popconfirm>,
            ];

        }
        const isCourier = article.target_user_role === 'courier';
        return (
          <CardWrapper
            wrap={isOperator}
            label={`Для ${isCourier ? 'курьеров' : 'организаций'}`}
            color={isCourier ? 'orange' : 'blue'}
          >
            <Card
              key={article.id}
              extra={<ArticleMeta date={moment(article.published_at)} author={article.author_name}/>}
              className="ArticleCard"
              title={article.title}
              actions={actions}
            >
              {article.text}
            </Card>
          </CardWrapper>
        );
      })}
    </>
  );

  return (
    <section className="AppSection News">
      <div className="News__content">
        {
          isOperator &&
          <Button
            className="News__addArticleButton"
            type="primary"
            onClick={() => setModalVisibility(true)}
          >
            <PlusOutlined/>
            Добавить новость
          </Button>
        }
        {viewList}
      </div>

      <Pagination
        onChange={onChangePage}
        defaultCurrent={1}
        pageSize={newsList.page_size}
        current={newsList.page}
        total={newsList.total}
      />

      {
        isOperator &&
        <Modal
          title="Добавление новости"
          visible={isModalVisible}
          footer={false}
          onCancel={() => setModalVisibility(false)}
        >
          <CreateArticleForm
            closeModal={() => setModalVisibility(false)}
            afterCreate={refresh}
          />
        </Modal>
      }
    </section>
  );
};

const connected = withRouter(connect(mapState, mapDispatch)(NewsList));
export { connected as NewsList };
