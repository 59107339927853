import React, { FunctionComponent, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Drawer } from 'antd';

import { flow } from 'fp-ts/es6/function';
import { record } from 'fp-ts/lib/Record';
import { flatten } from 'fp-ts/es6/Array';
import { Lens, fromTraversable } from 'monocle-ts';

import { useBusEffect } from '../../utils/bus';

import { RootState } from '../../store/root';
import { OrderListItem } from '../../api/protocol';
import { OrderPaginateList } from '../../store/orders/types';

import { OrderPreview } from '../OrderPreview/OrderPreview';
import { foldView } from '../../utils/view';
import { CenteredText, LoaderAdaptive } from '../Loader';
import { apiGetOperatorsOrderDetails, makeMapDispatch } from '../../store/dispatcher';

declare module '../../utils/bus' {
  interface BusEvents {
    openOrderDrawer: number
    loadOrderDataAndOpenDrawer: number
  }
}

const reduceCachedOrders = flow(
  fromTraversable(record)<OrderPaginateList>()
    .composeLens(Lens.fromProp<OrderPaginateList>()('items'))
    .composeTraversal(fromTraversable(record)<OrderListItem[]>())
    .asFold()
    .getAll,
  flatten,
);

type StateProps = { cacheList: OrderListItem[] }
const mapState = (state: RootState): StateProps => ({ cacheList: reduceCachedOrders(state.orders.list) });
const mapActions = makeMapDispatch({ getDetails: apiGetOperatorsOrderDetails });

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapActions>
const OrderDrawer: FunctionComponent<Props> = props => {
  const { cacheList, getDetails } = props;

  const [data, setData] = useState<OrderListItem>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const onClose = (): void => {
    setVisible(false);
    setData(undefined);
  };

  useBusEffect('openOrderDrawer', id => {
    setVisible(true);
    setData(cacheList.find(order => order.id === id));
  });

  useBusEffect('loadOrderDataAndOpenDrawer', async id => {
    setVisible(true);
    setLoading(true);
    const response = await getDetails({ order_id: id });
    setData(response);
    setLoading(false);
  });

  const foldV = foldView(data, loading, null);

  return (
    <Drawer
      className="CourierPreview__drawer"
      width={640}
      footer={null}
      onClose={onClose}
      visible={visible}
      maskClosable={!loading}
    >
      {
        foldV(
          () => <CenteredText text='Заказ не найден' size='small'/>,
          () => <LoaderAdaptive/>,
          () => null,
          orderData => <OrderPreview orderData={orderData}/>
        )
      }
    </Drawer>
  );
};

const component = withRouter(connect(mapState, mapActions)(OrderDrawer));
export { component as OrderDrawer };
