import { Reducer } from 'redux';

import { NewsState, NewsActionTypes } from './types';

const initialState: NewsState = {
  details: {},
  list: {
    items: {},
    total: 0,
    page_size: 0,
    page: 0,
  },
};

export const newsReducer: Reducer<NewsState, NewsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case 'NEWS_SET_LIST_ITEM': {
      const { items, total, page, page_size: size } = action.payload;
      const listItems = state.list.total === total
        ? { ...state.list.items, [page]: items }
        : { [page]: items };
      return { ...state, list: { total, page, page_size: size, items: listItems } };
    }
    case 'NEWS_SET_LIST_PAGE':
      return { ...state, list: { ...state.list, page: action.payload } };
    case 'NEWS_SET_LIST_FLUSH':
      return { ...state, list: { ...initialState.list } };
    case 'NEWS_SET_DETAILS_ITEM':
      return { ...state, details: { ...state.details, [action.payload.id]: action.payload } };
    default:
      return state;
  }
};
