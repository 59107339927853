import { Reducer } from 'redux';

import { fromTraversable, Lens } from 'monocle-ts';
import { pipe } from 'fp-ts/es6/pipeable';
import { array } from 'fp-ts/es6/Array';
import { getLastSemigroup } from 'fp-ts/es6/Semigroup';
import { fromFoldableMap, insertAt, record } from 'fp-ts/es6/Record';

import { OrderCountListItem, OrderListItem, OrderStatus } from '../../api/protocol';
import { OrderPaginateList, OrdersActionTypes, OrdersState, OrderListCount } from './types';

const buildListLens = (kind: OrderStatus): Lens<OrdersState, OrderPaginateList> => Lens.fromPath<OrdersState>()(['list', kind]);
const listItemsLens = Lens.fromProp<OrderPaginateList>()('items');
const listCountLens = Lens.fromProp<OrdersState>()('listCount');
const currentTypeLens = Lens.fromProp<OrdersState>()('currentType');
const listPaginatorLens = Lens.fromProps<OrderPaginateList>()(['total', 'page', 'page_size']);
const reduceCountList = (list: OrderCountListItem[]): OrderListCount =>
  fromFoldableMap(getLastSemigroup<number>(), array)(list, item => [item.orders_status, item.number_of_orders]);

const traverseList = fromTraversable(record)<OrderPaginateList>();
const traverseItems = fromTraversable(array)<OrderListItem>();
const traverseAllList = Lens.fromProp<OrdersState>()('list').composeTraversal(traverseList);
const traverseListItems = fromTraversable(record)<OrderListItem[]>().composeTraversal(traverseItems);

const initialState: OrdersState = {
  currentType: 'new',
  list: {
    new: { items: {}, total: 0, page: 1, page_size: 0 },
    canceled: { items: {}, total: 0, page: 1, page_size: 0 },
    delivered: { items: {}, total: 0, page: 1, page_size: 0 },
    in_transit: { items: {}, total: 0, page: 1, page_size: 0 },
    courier_assigned: { items: {}, total: 0, page: 1, page_size: 0 },
  },
  listCount: {
    new: 0,
    canceled: 0,
    delivered: 0,
    in_transit: 0,
    courier_assigned: 0,
  },
};

export const ordersReducer: Reducer<OrdersState, OrdersActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case 'ORDERS_SET_LIST_ITEM': {
      const { kind, response: { items, counts, ...paginator } } = action.payload;
      const listLens = buildListLens(kind);
      return pipe(
        state,
        currentTypeLens.set(kind),
        listLens.compose(listItemsLens).modify(insertAt(paginator.page.toString(), items)),
        listLens.compose(listPaginatorLens).set(paginator),
        listCountLens.set(reduceCountList(counts)),
      );
    }
    case 'ORDERS_SET_EMPTY_LIST':
      return pipe(
        state,
        buildListLens(action.payload).set({ items: {}, total: 0, page: 1, page_size: 0 }),
        listCountLens.modify(insertAt(action.payload, 0)),
      );
    case 'ORDERS_RESET_LIST':
      return pipe(
        state,
        traverseAllList.set({ items: {}, total: 0, page: 1, page_size: 0 }),
        listCountLens.set({ new: 0, canceled: 0, delivered: 0, in_transit: 0, courier_assigned: 0}),
      );
    case 'ORDERS_SET_COURIER_INFO':
      return pipe(
        state,
        traverseAllList
          .composeLens(listItemsLens)
          .composeTraversal(traverseListItems)
          .filter(item => item.id === action.payload.orderId)
          .modify(item => ({ ...item, courier: action.payload.response }))
      );
    case 'ORDERS_SET_CURRENT_TYPE':
      return pipe(
        state,
        currentTypeLens.set(action.payload)
      );
    default:
      return state;
  }
};
