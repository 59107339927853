import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { ApartmentOutlined } from '@ant-design/icons';

import { OperatorWithStats } from '../../api/protocol';
import { formatPhone } from '../../utils/view';

import './OperatorPreview.scss';

type ViewType = JSX.Element | string | number | null | undefined;
type BlockItemProps = {
  label: ViewType;
  value: ViewType;
  isFull?: boolean;
  isFullOnMobile?: boolean;
};
const BlockItem: FunctionComponent<BlockItemProps> = props => {
  const { label, value, isFull, isFullOnMobile } = props;

  return (
    <div
      className={clsx(
        'OperatorPreview__blockItem',
        isFull && 'OperatorPreview__blockItem--full',
        isFullOnMobile && 'OperatorPreview__blockItem--fullOnMobile'
      )}
    >
      <p className="OperatorPreview__blockItemLabel">{label}</p>
      <p className="OperatorPreview__blockItemValue">{value}</p>
    </div>
  );
};

type OperatorPreviewProps = {
  operatorData: OperatorWithStats;
};
export const OperatorPreview: FunctionComponent<OperatorPreviewProps> = props => {
  const { operatorData } = props;

  return (
    <div className="OperatorPreview">
      <div>
        <div className="OperatorPreview__blockHeader">
          <ApartmentOutlined style={{ marginRight: 8 }} />
          <span>Франчайзи</span>
        </div>

        <div className="OperatorPreview__blocks">
          <BlockItem label="ФИО" value={operatorData.name || '—'} isFullOnMobile />
          <BlockItem
            label="Город подключения"
            value={operatorData.city.name}
            isFullOnMobile
          />
          <BlockItem
            label="Телефон"
            value={formatPhone(operatorData.phone)}
            isFullOnMobile
          />
          <BlockItem
            label="Данные о франчайзи"
            value={operatorData.note || '—'}
            isFull
          />
          <BlockItem
            label="Оргацизации"
            value={operatorData.number_of_customer}
            isFullOnMobile
          />
          <BlockItem
            label="Курьеры"
            value={operatorData.number_of_couriers}
            isFullOnMobile
          />
        </div>
      </div>
    </div>
  );
};
