import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Lens } from 'monocle-ts';

import { Button, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { apiGetOperators, makeMapDispatch } from '../../store/dispatcher';

import { OperatorsListResponse } from '../../api/protocol';
import { showApiErrorNotification } from '../../utils/noty';

import { OperatorDrawer } from '../../components/OperatorDrawer/OperatorDrawer';
import { OperatorsTable } from '../../components/OperatorsTable/OperatorsTable';
import { OperatorsStatistic } from '../../components/OperatorsStatistic/OperatorsStatistic';

import './Operators.scss';

const pageLens = Lens.fromProp<OperatorsListResponse>()('page');

const mapActions = makeMapDispatch({
  getOperators: apiGetOperators,
});

const togglers = [
  { name: 'Активные', value: false },
  { name: 'Чёрный список', value: true },
];

type OperatorsProps = ReturnType<typeof mapActions>;
const Operators: FunctionComponent<OperatorsProps> = props => {
  const { getOperators } = props;

  const { push } = useHistory();
  const [isBlocked, setBlocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<OperatorsListResponse>({
    items: [],
    number_of_active: 0,
    number_of_blocked: 0,
    page: 1,
    page_size: 0,
    total: 0,
  });

  const paginator = {
    page: result.page,
    page_size: result.page_size,
    total: result.total,
  };

  const onPageChange = (newPage: number): void => {
    setResult(prevResult => pageLens.set(newPage)(prevResult));
  };

  const onChangeBlocked = (newValue: boolean): void => {
    onPageChange(1);
    setBlocked(newValue);
  };

  const fetchOperators = (): void => {
    if (loading) return;
    setLoading(true);

    getOperators({ is_blocked: isBlocked, page: result.page })
      .then(setResult)
      .catch(showApiErrorNotification)
      .finally(() => setLoading(false));
  };

  useEffect(fetchOperators, [result.page, isBlocked]);

  return (
    <div className="Operators">
      <section className="Operators__container">
        <div className="Operators__header">
          <div className="Operators__togglers">
            <Radio.Group
              value={isBlocked}
              buttonStyle="solid"
              onChange={e => onChangeBlocked(e.target.value)}
            >
              {togglers.map((toggler, index) => (
                <Radio.Button
                  value={toggler.value}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {toggler.name}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>

          <div className="Operators__statistic">
            <OperatorsStatistic
              loading={loading}
              blocked={result.number_of_blocked}
              active={result.number_of_active}
            />
          </div>

          <div className="Couriers__buttons">
            <Button
              type="primary"
              onClick={() => push('/operators/create')}
              className="Couriers__button"
            >
              <PlusOutlined />
              Добавить франчайзи
            </Button>
          </div>
        </div>

        <OperatorsTable
          list={result.items}
          loading={loading}
          paginator={paginator}
          onPageChange={onPageChange}
        />

        <OperatorDrawer />
      </section>
    </div>
  );
};

const component = withRouter(connect(null, mapActions)(Operators));
export { component as Operators };
