import React, { FunctionComponent, useCallback, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { apiCourierCreate, makeMapDispatch } from '../store/dispatcher';
import { CouriersCreateRequest } from '../api/protocol';

import { bindErrorHandler } from '../utils/noty';

import { CouriersForm } from '../components/CouriersForm/CouriersForm';

const mapDispatch = makeMapDispatch({ courierCreate: apiCourierCreate });

type Props = ReturnType<typeof mapDispatch>;
const CouriersCreate: FunctionComponent<Props> = ({ courierCreate }) => {
  const { push: redirectTo } = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const errorHandler = bindErrorHandler(form, () => { setLoading(false) });

  const redirectToList = useCallback(() => {
    setLoading(false);
    redirectTo('/couriers');
  }, [redirectTo]);

  const handleCreate = (data: unknown): void => {
    const payload = data as CouriersCreateRequest;
    setLoading(true);
    courierCreate(payload)
      .then(redirectToList)
      .catch(errorHandler)
  };

  return <CouriersForm
    form={form}
    loading={loading}
    isUpdate={false}
    onBack={redirectToList}
    onSubmit={handleCreate}
  />
};

const connected = withRouter(connect(null, mapDispatch)(CouriersCreate));
export { connected as CouriersCreate };
