import React from 'react';
import './AddMoneyIcon.scss';

export const AddMoneyIcon: JSX.Element = (
  <span className="anticon">
    <svg
      width="551"
      height="551"
      viewBox="0 0 551 551"
      className="AddMoneyIcon"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.545227 274.955C0.545227 122.815 123.905 -0.545166 276.045 -0.545166C428.185 -0.545166 551.545 122.815 551.545 274.955C551.545 314.788 543.089 352.649 527.874 386.839C516.1 373.811 499.048 365.331 488.599 360.061C498.922 334.003 504.809 304.68 504.809 274.955C504.809 148.643 402.357 46.1914 276.045 46.1914C149.733 46.1914 47.2818 148.643 47.2818 274.955C47.2818 401.267 149.733 503.718 276.045 503.718C309.477 503.718 344.887 494.888 373.519 481.991L380.271 494.687L385.722 503.511L391.944 511.344L402.866 520.494C369.363 534.987 314.867 550.455 276.045 550.455C123.905 550.455 0.545227 427.095 0.545227 274.955Z"
          fill="currentColor"
        />
        <path
          d="M481.066 424.645H529.347C531.876 424.645 533.946 426.714 533.946 429.243V461.431C533.946 463.96 531.876 466.029 529.347 466.029H481.066V514.31C481.066 516.839 478.997 518.908 476.468 518.908H444.28C441.751 518.908 439.682 516.839 439.682 514.31V466.029H391.401C388.872 466.029 386.803 463.96 386.803 461.431V429.243C386.803 426.714 388.872 424.645 391.401 424.645H439.682V376.364C439.682 373.835 441.751 371.765 444.28 371.765H476.468C478.997 371.765 481.066 373.835 481.066 376.364V424.645Z"
          fill="currentColor"
        />
        <path
          d="M303.502 313.341C353.83 313.341 389.022 278.185 389.022 227.65C389.022 177.115 353.83 143.058 303.502 143.058H206.914C202.996 143.058 199.819 146.235 199.819 150.153V272.326H169.074C165.155 272.326 161.978 275.503 161.978 279.421V306.245C161.978 310.164 165.155 313.341 169.074 313.341H199.819V332.261H169.074C165.155 332.261 161.978 335.438 161.978 339.356V363.006C161.978 366.924 165.155 370.101 169.074 370.101H199.819V400.847C199.819 404.765 202.996 407.942 206.914 407.942H241.538C245.456 407.942 248.633 404.765 248.633 400.847V370.101H344.086C348.004 370.101 351.181 366.924 351.181 363.006V339.356C351.181 335.438 348.004 332.261 344.086 332.261H248.633V313.341H303.502ZM248.633 183.706H295.177C322.801 183.706 339.45 200.918 339.45 227.65C339.45 254.749 322.801 272.326 294.42 272.326H248.633V183.706Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="551" height="551" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  </span>
);
