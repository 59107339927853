import { NewsItemResponse, NewsListItem, NewsListResponse } from '../../api/protocol';

export type NewsListState = PaginateLikeCacheList<NewsListItem>;
export interface NewsState {
  details: Record<number, NewsItemResponse>
  list: NewsListState
}

export const NEWS_SET_LIST_ITEM = 'NEWS_SET_LIST_ITEM';
export const NEWS_SET_LIST_PAGE = 'NEWS_SET_LIST_PAGE';
export const NEWS_SET_LIST_FLUSH = 'NEWS_SET_LIST_FLUSH';
export const NEWS_SET_DETAILS_ITEM = 'NEWS_SET_DETAILS_ITEM';

interface NewsSetListItemAction {
  type: typeof NEWS_SET_LIST_ITEM
  payload: NewsListResponse
}

interface NewsSetListPageAction {
  type: typeof NEWS_SET_LIST_PAGE
  payload: number
}

interface NewsSetListFlushAction {
  type: typeof NEWS_SET_LIST_FLUSH
}

interface NewsSetDetailsItemAction {
  type: typeof NEWS_SET_DETAILS_ITEM
  payload: NewsItemResponse
}

export type NewsActionTypes =
  | NewsSetListItemAction
  | NewsSetDetailsItemAction
  | NewsSetListPageAction
  | NewsSetListFlushAction
