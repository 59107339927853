import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Form, Input, Button, message } from 'antd';
import logo from '../../assets/auth-logo.svg';
import './Auth.scss';

import { AuthLoginRequest } from '../../api/protocol';
import { ApiError } from '../../api/client/errors';
import { apiUserLogin, AppDispatch } from '../../store/dispatcher';
import { RootState } from '../../store/root';
import { EventBus } from '../../utils/bus';

type StateProps = { isAuthenticated: boolean };
const mapState = (state: RootState): StateProps => ({
  isAuthenticated: state.user.isAuthenticated,
});

const mapActions = { postUserLogin: apiUserLogin };
const mapDispatch: AppDispatch<typeof mapActions> = dispatch =>
  bindActionCreators(mapActions, dispatch);

type AuthProps = StateProps & ReturnType<typeof mapDispatch>;

const Auth: React.FC<AuthProps> = props => {
  const { postUserLogin } = props;

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  return (
    <section className="Auth">
      <div className="Auth__splash">
        <div>
          <img className="Auth__logo" src={logo} alt="" />
          <h1 className="Auth__title">Вход в приложение</h1>
        </div>
      </div>
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        className="Auth__form"
        name="basic"
        initialValues={{ remember: true }}
        layout="horizontal"
        onFinish={({ username, password }) => {
          const payload = { username, password } as AuthLoginRequest;
          setLoading(true);
          postUserLogin(payload)
            .then(res => {
              if (res.role !== 'courier') {
                message.success('Вы успешно авторизовались');
                history.replace({ pathname: '/' });
              } else {
                EventBus.emit('onLogout');
              }
            })
            .catch(error => {
              setLoading(false);
              if (error instanceof ApiError && error.isCommon()) {
                const errText = error.getCommonFirstMessage() ?? '';
                message.warn(errText);
                form.setFields([
                  {
                    name: 'username',
                    errors: [errText],
                  },
                  {
                    name: 'password',
                    errors: [errText],
                  },
                ]);
              }
            });
        }}
      >
        <Form.Item
          className="Auth__control"
          label="Логин"
          name="username"
          rules={[{ required: true, message: 'Пожалуйста, введите логин' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="Auth__control"
          label="Пароль"
          name="password"
          rules={[{ required: true, message: 'Введите пароль' }]}
        >
          <Input.Password />
        </Form.Item>

        <div className="Auth__buttonWrapper">
          <Button
            className="Auth__button"
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Войти
          </Button>
        </div>
      </Form>

      <div className="Auth__footer">
        <span className="Auth__footerTextWrapper">
          <span className="Auth__footerText">
            Посетите наш сайт:
          </span>
          &nbsp;
          <a
            className="Auth__footerLink"
            href="https://delivery-house.info"
            target="_blank"
            rel="noopener noreferrer"
          >
            delivery-house.info
          </a>
        </span>
      </div>
    </section>
  );
};

const connected = connect(mapState, mapDispatch)(Auth);
export { connected as Auth };
