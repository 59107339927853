import React, { FunctionComponent } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

type LoaderSize = 'small' | 'middle' | 'large';
type LoaderProps = { size?: LoaderSize; text?: string };

export const Loader: FunctionComponent<LoaderProps> = ({
  size = 'middle',
  text,
}) => (
  <div className="App-loader">
    <LoadingOutlined style={{ fontSize: '64px', color: 'white' }} />
    {text && <span className="App-loader__text">{text}</span>}
  </div>
);

export const LoaderAdaptive: FunctionComponent<LoaderProps> = ({
  size = 'middle',
  text,
}) => {
  let fontSize = '36px';
  if (size === 'small') fontSize = '24px';
  else if (size === 'large') fontSize = '48px';
  return (
    <div className="App-loader__adaptive">
      <LoadingOutlined style={{ fontSize, color: '#0C0B0B' }} />
      {text && (
        <span className="App-loader__text" style={{ color: '#0C0B0B' }}>
          {text}
        </span>
      )}
    </div>
  );
};

export const CenteredText: FunctionComponent<LoaderProps> = ({ size = 'middle', text}) => {
  let fontSize = '36px';
  if (size === 'small') fontSize = '24px';
  else if (size === 'large') fontSize = '48px';
  return (
    <div className="App-loader__adaptive">
      <span className="App-loader__text" style={{ fontSize, color: '#0C0B0B' }}>
          {text}
        </span>
    </div>
  );
};
