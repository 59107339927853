import * as D from 'io-ts/lib/Decoder'
import { isRight } from 'fp-ts/es6/Either';
import {
  CustomersOrderCreateRequest,
  CustomersOrderCalculateRequest,
  OperatorsOrderCreateRequest,
  OperatorsOrderCalculateRequest,
  OrderCreateRecipient,
} from '../api/protocol';

export const OrderCreateRecipientDecoder: D.Decoder<OrderCreateRecipient> = D.type({
  name: D.string,
  phone: D.string,
  place_id: D.string,
  entrance: D.nullable(D.string),
  apartment: D.nullable(D.string),
  floor: D.nullable(D.string),
  comment: D.nullable(D.string),
});

const customerSchema = {
  phone: D.string,
  comment: D.nullable(D.string),
  recipient: OrderCreateRecipientDecoder,
  is_paid: D.boolean,
  order_price: D.string,
};

const operatorSchema = {
  ...customerSchema,
  customer_id: D.string
};

const addressOptional = D.partial({
  branch_id: D.string,
  sender_address: D.string,
});

type OrderWithoutDate<T> = Omit<T, 'to_pick_up_at' | 'to_deliver_at'>;
export const CustomerCalculateOrderDecoder: D.Decoder<OrderWithoutDate<CustomersOrderCalculateRequest>> = D.intersection(
  D.type(customerSchema),
  addressOptional
);

export const CustomerCreateOrderDecoder: D.Decoder<OrderWithoutDate<CustomersOrderCreateRequest>> = D.intersection(
  D.type(customerSchema),
  addressOptional
);

export const OperatorsCalculateOrderDecoder: D.Decoder<OrderWithoutDate<OperatorsOrderCalculateRequest>> = D.intersection(
  D.type(operatorSchema),
  addressOptional
);

export const OperatorsCreateOrderDecoder: D.Decoder<OrderWithoutDate<OperatorsOrderCreateRequest>> = D.intersection(
  D.type({ ...operatorSchema, delivery_price: D.string }),
  addressOptional
);

export const createValidatorFromDecoder = (decoder: D.Decoder<unknown>) =>
  (data: unknown): boolean => isRight(decoder.decode(data));
