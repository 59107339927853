import React from 'react';
import ReactDOM from 'react-dom';

import moment from 'moment';
import 'moment/locale/ru';

// import * as serviceWorker from './serviceWorker';
import './index.css';
import { Root } from './containers/Root';

moment.locale('ru', {
  week: {
    dow: 1,
  }
});

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

const DSN = process.env.REACT_APP_SENTRY_DSN;
if (DSN) {
  import('@sentry/browser')
    .then(Sentry => { Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN }); })
    .catch(err => { console.warn('Failed to initialize Sentry.', err) })
}
