import React, { FunctionComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Card, Divider, Form, Input } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

import { CommonUserProfile } from '../store/user/types';
import { RootState } from '../store/root';

import { formatPhone } from '../utils/view';
import { CurrencyValue, formatCurrency } from '../utils/strings';

type StateProps = { profile: CommonUserProfile };
const mapState = (
  state: RootState & { user: { profile: CommonUserProfile } }
): StateProps => {
  return {
    profile: state.user.profile,
  };
};

type Props = ReturnType<typeof mapState>;
const ProfileFranchiser: FunctionComponent<Props> = props => {
  const { profile } = props;

  const currency = (prop: keyof CurrencyValue): string =>
    formatCurrency(prop, profile.city.currency_type);

  const fields = [
    { name: 'Имя', value: profile.name },
    { name: 'Телефон', value: profile.phone ? formatPhone(profile.phone) : '' },
    { name: 'Логин', value: profile.username },
    { name: 'Заблокирован', value: profile.is_blocked ? 'Да' : 'Нет' },
    { name: 'Город', value: profile.city.name },
    { name: 'Валюта', value: `${currency('name')}, ${currency('sign')}` },
  ];

  return (
    <section className="AppSection">
      <Form layout="vertical">
        <Card>
          <h2>
            <IdcardOutlined style={{ marginRight: 8 }} />
            Профиль
          </h2>

          <Divider />

          {fields.map(field => (
            <Form.Item label={field.name}>
              <Input value={field.value || '—'} disabled />
            </Form.Item>
          ))}
        </Card>
      </Form>
    </section>
  );
};

const connected = withRouter(connect(mapState)(ProfileFranchiser));
export { connected as ProfileFranchiser };
