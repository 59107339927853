import React, { FunctionComponent } from 'react';
import { Tag } from 'antd';

type StatusTagProps = {
  isOnline: boolean;
};
export const StatusTag: FunctionComponent<StatusTagProps> = ({ isOnline }) => (
  <Tag color={isOnline ? 'success' : 'default'}>
    {isOnline ? 'Работает' : 'Оффлайн'}
  </Tag>
);
