import React, { FunctionComponent } from 'react';
import moment from 'moment';

import { isNonEmpty } from 'fp-ts/es6/Array';

import { Empty, Table, Button, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  PhoneOutlined,
  UserSwitchOutlined,
  CarOutlined,
  BarChartOutlined,
  UnorderedListOutlined,
  HourglassOutlined,
} from '@ant-design/icons';

import {
  CouriersDetailsResponse,
  CourierTransaction,
} from '../../api/protocol';
import { StatusTag } from '../StatusTag';

import { EventBus } from '../../utils/bus';
import { formatCurrency } from '../../utils/strings';
import { formatPhone, formatPrice } from '../../utils/view';

import './CourierProfilePreview.scss';

type ViewType = JSX.Element | string | number | null;
type BlockItemProps = { label: ViewType; value: ViewType; col?: number };
const BlockItem: FunctionComponent<BlockItemProps> = ({
  label,
  value,
  col = 2,
}) => {
  let className = 'CourierProfilePreview__blockItem';
  if (col === 1) className += ' CourierProfilePreview__blockItem--single';
  if (col === 3) className += ' CourierProfilePreview__blockItem--thirds';
  return (
    <div className={className}>
      <div className="CourierProfilePreview__blockItemLabel">{label}</div>
      <div className="CourierProfilePreview__blockItemContent">{value}</div>
    </div>
  );
};

const Block: FunctionComponent<{
  label: ViewType;
  icon?: JSX.Element;
}> = props => (
  <div className="CourierProfilePreview__block">
    <div className="CourierProfilePreview__blockHeader">
      {props.icon}
      {props.label}
    </div>
    <div className="CourierProfilePreview__blockContent">{props.children}</div>
  </div>
);

const columns: ColumnsType<CourierTransaction> = [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
    render: (id: number) => (
      <div className="ResponsiveTable__idWrapper">
        <h3 className="ResponsiveTable__mobileHeading">
          № транзакции:
        </h3>
        <Tag color="red" className="ResponsiveTable__id">{id}</Tag>
      </div>
    ),
  },
  {
    title: 'Тип',
    key: 'type',
    render: ({ type }: CourierTransaction) =>
      <>
        <h3 className="ResponsiveTable__mobileHeading">
          Тип
        </h3>
        <div>{type === 'operator_replenish' ? 'Пополнение' : 'Списание'}</div>
      </>
  },
  {
    title: 'Сумма',
    key: 'amount',
    render: ({ amount }: CourierTransaction) =>
      <>
        <h3 className="ResponsiveTable__mobileHeading">
          Сумма
        </h3>
        <div>{`${formatPrice(amount.toString())} ${formatCurrency()}`}</div>
      </>
  },
  {
    title: 'Дата',
    key: 'created_at',
    render: ({ created_at }: CourierTransaction) =>
      <>
        <h3 className="ResponsiveTable__mobileHeading">
          Дата
        </h3>
        <div>{moment(created_at).format('DD MMM YYYY в HH:mm')}</div>
      </>
  },
  {
    title: 'Оператор',
    key: 'operator_name',
    render: ({ operator_name }: CourierTransaction) =>
      <>
        <h3 className="ResponsiveTable__mobileHeading">
          Оператор
        </h3>
        <div>{operator_name}</div>
      </>
  },
  {
    title: 'Заказ',
    key: 'order_id',
    render: ({ order_id }: CourierTransaction) => {
      const onClick = (): void => { if (order_id) EventBus.emit('loadOrderDataAndOpenDrawer', order_id) };
      return (
        <>
          <h3 className="ResponsiveTable__mobileHeading">
            Заказ
          </h3>
          <div>{order_id !== null ? <Button size='small' onClick={onClick}>№{order_id}</Button> : 'Не назначен'}</div>
        </>
      )
    }
  }
];

type Props = {
  profileData: CouriersDetailsResponse;
  onPageChange: (page: number) => void;
  transactions: PaginateLikeCacheList<CourierTransaction>;
  loading: boolean;
};
export const CourierProfilePreview: FunctionComponent<Props> = props => {
  const {
    profileData,
    transactions: transactionsList,
    onPageChange,
    loading,
  } = props;

  const birthDate = moment(profileData.birth_date, 'YYYY.MM.DD').format('D MMMM YYYY г.');

  let transport = <BlockItem label="" value="Пешеход" />;
  if (profileData.car.type !== 'walker') {
    transport = (
      <>
        <BlockItem
          col={3}
          label="Тип"
          value={profileData.car.type === 'light' ? 'Легковой' : 'Грузовик'}
        />
        <BlockItem
          col={3}
          label="Цвет"
          value={profileData.car.color || 'Не указан'}
        />
        <BlockItem
          col={3}
          label="Номер"
          value={profileData.car.number || 'Не указан'}
        />
        <BlockItem
          col={3}
          label="Бренд"
          value={profileData.car.brand || 'Не указан'}
        />
        <BlockItem
          col={3}
          label="Модель"
          value={profileData.car.model || 'Не указан'}
        />
        <BlockItem
          col={3}
          label="Год"
          value={profileData.car.year || 'Не указан'}
        />
      </>
    );
  }

  const phone = profileData.user.phone ? (
    <a
      className="OrganizationsTable__agentPhone"
      href={`tel:+${profileData.user.phone}`}
    >
      <PhoneOutlined />
      {formatPhone(profileData.user.phone)}
    </a>
  ) : (
    'Не указан'
  );

  const transactions = transactionsList.items[transactionsList.page] || [];

  return (
    <div className="CourierProfilePreview">
      <div className="CourierProfilePreview__container">
        <Block
          label="Курьер"
          icon={<UserSwitchOutlined style={{ marginRight: 8 }} />}
        >
          <BlockItem label="Фамилия" value={profileData.last_name} />
          <BlockItem label="Статус" value={<StatusTag isOnline={profileData.is_online} />} />
          <BlockItem label="Имя" value={profileData.first_name} />
          <BlockItem label="Дата рождения" value={birthDate} />
          <BlockItem label="Отчество" value={profileData.middle_name} />
          <BlockItem label="Телефон" value={phone} />
          <BlockItem
            col={1}
            label="Регистрация"
            value={profileData.registration}
          />
        </Block>
        {
          profileData.active_orders_ids !== null && isNonEmpty(profileData.active_orders_ids) &&
          <Block
            label='Активные заказы'
            icon={<HourglassOutlined style={{ marginRight: 8 }} />}
          >
            {
              profileData.active_orders_ids.map(id => (
                <Button
                  key={'active-order-' + id}
                  style={{ marginRight: 8 }}
                  onClick={() => EventBus.emit('loadOrderDataAndOpenDrawer', id)}
                >
                  Заказ №{id}
                </Button>
              ))
            }
          </Block>
        }
        <Block
          label="Транспорт"
          icon={<CarOutlined style={{ marginRight: 8 }} />}
        >
          {transport}
        </Block>
        <Block
          label="Статистика"
          icon={<BarChartOutlined style={{ marginRight: 8 }} />}
        >
          <BlockItem
            col={2}
            label="Выполненные заказы"
            value={profileData.orders_number}
          />
          <BlockItem
            col={2}
            label="Баланс"
            value={`${formatPrice(profileData.balance.toString())} ${formatCurrency()}`}
          />
        </Block>
        <Block
          label="Транзакции"
          icon={<UnorderedListOutlined style={{ marginRight: 8 }} />}
        >
          <Table
            className="CourierProfilePreview__transactions ResponsiveTable"
            dataSource={transactions}
            columns={columns}
            loading={loading}
            size="small"
            rowKey="id"
            pagination={{
              total: transactionsList.total,
              current: transactionsList.page,
              pageSize: transactionsList.page_size,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            onChange={paginator => {
              onPageChange(paginator.current || 1);
            }}
            locale={{
              emptyText: (
                <Empty
                  description="Список транзакций пуст"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              ),
            }}
          />
        </Block>
      </div>
    </div>
  );
};
