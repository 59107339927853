import React, { FunctionComponent, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { map } from 'fp-ts/lib/Array';

import { Button, Card, Col, Divider, Form, Input, Row, Select } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import { LabeledValue } from 'antd/lib/select';

import { apiCustomerProfileUpdate, makeMapDispatch } from '../store/dispatcher';
import { CustomerProfile } from '../store/user/types';
import { RootState } from '../store/root';

import { bindErrorHandler } from '../utils/noty';
import { FormBranchesPart } from '../components/FormBranchesPart';
import { CustomerBranchInfo, CustomerBranchPayload, CustomerType, EncodedAddress } from '../api/protocol';
import { formatAutocompleteBranch } from '../utils/form';

const requiredRule = [{ required: true, message: 'Заполните поле' }];

type CustomerProfileUpdate = Omit<CustomerProfile, 'branches'> & { branches: CustomerBranchPayload[] }

export const mapBranches = map<CustomerBranchInfo, CustomerBranchPayload>(branch => ({
  id: branch.id,
  phone: branch.phone,
  address: branch.address.address,
  comment: branch.comment,
  place_id: branch.address.geocoder_place_id,
}));

type StateProps = { profile: CustomerProfileUpdate, typesList: CustomerType[] };
const mapState = (state: RootState & { user: { profile: CustomerProfile } }): StateProps => {
  const { branches, ...profileData } = state.user.profile;
  return {
    profile: { ...profileData, branches: mapBranches(branches) },
    typesList: state.customers.types,
  }
};

const mapDispatch = makeMapDispatch({ updateProfile: apiCustomerProfileUpdate });

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const ProfileCustomer: FunctionComponent<Props> = props => {
  const { profile, updateProfile, typesList } = props;

  const typesOptions: LabeledValue[] = typesList.map(item => ({ label: item.name, value: item.id }));

  const [form] = Form.useForm();
  const [addresses, setAddresses] = useState<EncodedAddress[]>([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(props.profile);

  const errorHandler = bindErrorHandler(form, () => { setLoading(false); });

  const addAddress = (index: number, value?: string): void => {
    setAddresses((prev) => [
      ...prev.filter((item) => item.index !== index),
      { index, value },
    ])
  };

  useEffect(() => {
    profile.branches.forEach((item, index) => {
      addAddress(index, item.place_id);
    })
    setFormData(profile);
  }, [profile]);

  const update = (data: any): void => {
    setLoading(true);
    updateProfile(data)
      .then(() => { setLoading(false); })
      .catch(errorHandler);
  };

  return (
    <section className="AppSection">
      <Form
        form={form}
        initialValues={formData}
        onFinish={(data) => {
          const branches: CustomerBranchPayload[] = [];
          data.branches.forEach((branch: CustomerBranchPayload, index: number) => {
            branches.push(formatAutocompleteBranch(branch, index, addresses));
          });
          update({
            ...data,
            branches,
          });
        }}
        layout='vertical'
      >
        <Row gutter={16}>
          <Col style={{ width: '50%' }}>
            <Card>
              <h2>
                <IdcardOutlined style={{ marginRight: 8 }}/>
                Профиль
              </h2>
              <Divider/>
              <Form.Item label="Название организации" name="name">
                <Input/>
              </Form.Item>
              <Form.Item label="Тип организации" name="type_id" rules={requiredRule}>
                <Select options={typesOptions}/>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Сохранить
                </Button>
              </Form.Item>
            </Card>
          </Col>
          <Col style={{ width: '50%' }}>
            <FormBranchesPart
              addressPath={['address']}
              setOuterValues={(index, value) => addAddress(index, value)}
              isUpdate
            />
          </Col>
        </Row>
      </Form>
    </section>
  );
};

const connected = withRouter(connect(mapState, mapDispatch)(ProfileCustomer));
export { connected as ProfileCustomer };
