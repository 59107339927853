import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Operator } from '../../api/protocol';
import { showApiErrorNotification } from '../../utils/noty';
import { apiOperatorDetails, makeMapDispatch } from '../../store/dispatcher';

import { LoaderAdaptive } from '../../components/Loader';
import { OperatorForm } from '../../components/OperatorForm/OperatorForm';

const mapActions = makeMapDispatch({
  getOperator: apiOperatorDetails,
});

type UpdateOperatorProps = ReturnType<typeof mapActions>;
const UpdateOperator: FunctionComponent<UpdateOperatorProps> = props => {
  const { getOperator } = props;

  const params = useParams<{ id: string | undefined }>();
  const id = params.id ? parseInt(params.id, 10) : null;

  const [data, setData] = useState<Operator | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchOperator = (operatorId: number): void => {
    if (loading) return;
    setLoading(true);

    getOperator({ id: operatorId })
      .then(setData)
      .catch(showApiErrorNotification)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (id) fetchOperator(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <section className="AppSection">
        {data && !loading ? (
          <OperatorForm mode="update" data={data} />
        ) : (
          <LoaderAdaptive />
        )}
      </section>
    </div>
  );
};

const component = withRouter(connect(null, mapActions)(UpdateOperator));
export { component as UpdateOperator };
