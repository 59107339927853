import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';

import { debounce } from 'lodash-es';
import { pipe } from 'fp-ts/es6/pipeable';
import { array, map } from 'fp-ts/es6/Array';
import { fromFoldableMap } from 'fp-ts/es6/Record';
import { getLastSemigroup } from 'fp-ts/es6/Semigroup';

import { Tooltip, Select } from 'antd';
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';

import { ApiError } from '../api/client/errors';
import {
  CouriersListItem,
  CustomerBranchInfo,
  CustomerListItem,
} from '../api/protocol';
import {
  apiSearchCourier,
  apiSearchCustomer,
  makeMapDispatch,
} from '../store/dispatcher';

import { foldView } from '../utils/view';

export type Option = SelectOption<string>;
export type Options = Option[];
export type Phones = Record<string, string>;
type BranchesOptions = Record<string, Options>;
type PhonesOptions = Record<string, Phones>;

const mapCustomerOptions = map<CustomerListItem, Option>(item => ({
  value: item.id.toString(),
  label: item.name,
}));
const mapCouriersOptions = map<CouriersListItem, Option>(item => ({
  value: item.id.toString(),
  label: `${item.last_name} ${item.first_name} ${item.middle_name}`,
}));
export const mapBranches = map<CustomerBranchInfo, Option>(item => ({
  value: item.id === undefined ? '' : item.id.toString(),
  label: item.address.address,
}));
export const reducePhones = (list: CustomerBranchInfo[]): Phones =>
  fromFoldableMap(getLastSemigroup<string>(), array)(list, item => [
    item.id === undefined ? '' : item.id.toString(),
    item.phone,
  ]);
const reduceBranches = (list: CustomerListItem[]): BranchesOptions =>
  fromFoldableMap(getLastSemigroup<Options>(), array)(list, item => [
    item.id.toString(),
    mapBranches(item.branches),
  ]);
const reducePhonesOptions = (list: CustomerListItem[]): PhonesOptions =>
  fromFoldableMap(getLastSemigroup<Phones>(), array)(list, item => [
    item.id.toString(),
    reducePhones(item.branches),
  ]);

const mapState = (): {} => ({});
const mapActions = makeMapDispatch({
  searchCustomer: apiSearchCustomer,
  searchCourier: apiSearchCourier,
});

type ComponentProps = {
  value?: string;
  onChange?: (value: string) => void;
  onSelectCustomer?: (options: Options, phones: Phones) => void;
  mode?: 'courier' | 'customer';
  allowClear?: boolean;
};

type Props = ReturnType<typeof mapState> &
  ReturnType<typeof mapActions> &
  ComponentProps;
const SearchInput: FunctionComponent<Props> = props => {
  const {
    onSelectCustomer,
    searchCustomer,
    searchCourier,
    children,
    mode = 'customer',
    onChange: propOnChange,
    ...inputProps
  } = props;

  const [error, setError] = useState<string | null>(null);
  const [options, setOptions] = useState<Options>([]);
  const [loading, setLoading] = useState(false);
  const [phones, setPhones] = useState<PhonesOptions>({});
  const [branches, setBranches] = useState<BranchesOptions>({});

  const errorMessage =
    onSelectCustomer !== undefined
      ? 'Курьер не найден'
      : 'Организация не найдена';

  const onSearch = useCallback(
    async (query: string): Promise<void> => {
      if (onSelectCustomer !== undefined) onSelectCustomer([], {});
      if (query.length < 1) {
        setError(null);
        setLoading(false);
        setOptions([]);
        return;
      }

      setLoading(true);
      try {
        const { items } =
          mode === 'customer'
            ? await searchCustomer(query)
            : await searchCourier(query);
        if (mode === 'customer') {
          setOptions(mapCustomerOptions(items as CustomerListItem[]));
          setBranches(reduceBranches(items as CustomerListItem[]));
          setPhones(reducePhonesOptions(items as CustomerListItem[]));
        } else {
          setOptions(mapCouriersOptions(items as CouriersListItem[]));
        }

        setError(items.length ? null : errorMessage);
      } catch (err) {
        setOptions([]);
        if (err instanceof ApiError) setError(err.getCommonFirstMessage());
      } finally {
        setLoading(false);
      }
    },
    [searchCustomer, onSelectCustomer, errorMessage, mode, searchCourier]
  );

  const onChange = useCallback(
    (value: string): void => {
      if (mode === 'customer') {
        if (value && onSelectCustomer !== undefined)
          onSelectCustomer(branches[value] || [], phones[value] || {});
        else setOptions([]);
      }
      if (propOnChange) propOnChange(value);
    },
    [onSelectCustomer, branches, phones, propOnChange, mode]
  );
  const callable = useMemo(
    () => debounce(onSearch, 500, { leading: false, trailing: true }),
    [onSearch]
  );
  const foldV = useMemo(() => foldView(options, loading, error), [
    options,
    loading,
    error,
  ]);

  return (
    <Select
      {...inputProps}
      showSearch
      options={options}
      onSearch={callable}
      onChange={onChange}
      filterOption={false}
      notFoundContent={null}
      defaultActiveFirstOption={false}
      suffixIcon={foldV(
        () => (
          <span />
        ), // https://ant.design/components/input/#FAQ
        () => (
          <LoadingOutlined style={{ fontSize: 11 }} />
        ),
        errorData => (
          <Tooltip title={errorData}>
            <CloseCircleTwoTone
              twoToneColor="#ff4d4f"
              style={{ fontSize: 11 }}
            />
          </Tooltip>
        ),
        () => (
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 11 }} />
        )
      )}
    />
  );
};

const component = pipe(SearchInput, connect(mapState, mapActions));

export { component as SearchInput };
