import React, { FunctionComponent } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/es6/pipeable';

import { Menu } from 'antd';
import {
  UnorderedListOutlined,
  BankOutlined,
  NotificationOutlined,
  PlusCircleOutlined,
  CarOutlined,
  BarChartOutlined,
  AreaChartOutlined,
  LinkOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import { UserRole } from '../../api/protocol';
import { RootState } from '../../store/root';
import { setCollapseState } from '../../store/user/actions';
import { makeMapDispatch } from '../../store/dispatcher';
import './NavigationMenu.scss';

type StateProps = { userRole: UserRole | null };
const mapState = (state: RootState): StateProps => ({
  userRole: state.user.role,
});

const eulaLink = process.env.REACT_APP_EULA_LINK;

const mapDispatch = makeMapDispatch({
  setCollapse: setCollapseState,
});

const NavigationMenu: FunctionComponent<StateProps &
  ReturnType<typeof mapDispatch>> = props => {
  const { userRole, setCollapse } = props;
  const { pathname } = useLocation();

  const collapseSidebarOnMobile = (): void => {
    setCollapse(true);
  };

  const isOperator = userRole === 'operator';
  const isFranchiser = userRole === 'franchiser';

  const menuItems = [
    {
      icon: <UnorderedListOutlined />,
      name: 'Заказы',
      link: '/orders',
      condition: !isFranchiser,
    },
    {
      icon: <PlusCircleOutlined />,
      name: 'Создать заказ',
      link: '/orders/create',
      condition: !isFranchiser,
    },
    {
      icon: <BankOutlined />,
      name: 'Организации',
      link: '/organizations',
      condition: isOperator && !isFranchiser,
    },
    {
      icon: <CarOutlined />,
      name: 'Курьеры',
      link: '/couriers',
      condition: isOperator && !isFranchiser,
    },
    {
      icon: <NotificationOutlined />,
      name: 'Новости',
      link: '/news',
      condition: !isFranchiser,
    },
    {
      icon: <BarChartOutlined />,
      name: 'Статистика по организациям',
      link: '/stats',
      condition: !isFranchiser,
    },
    {
      icon: <AreaChartOutlined />,
      name: 'Статистика по курьерам',
      link: '/couriers-stats',
      condition: isOperator,
    },
    {
      icon: <BarChartOutlined />,
      name: 'Франчайзи',
      link: '/operators',
      condition: isFranchiser,
    },
    {
      icon: <SettingOutlined />,
      name: 'Настройки',
      link: '/settings',
      condition: isFranchiser,
    },
  ];

  return (
    <Menu
      mode="inline"
      theme="dark"
      selectedKeys={[pathname]}
      className="NavigationMenu"
    >
      {menuItems.map(
        menuItem =>
          menuItem.condition && (
            <Menu.Item key={menuItem.link}>
              {menuItem.icon}

              <span>{menuItem.name}</span>
              <Link to={menuItem.link} className="NavigationMenu__link" />
              <Link
                to={menuItem.link}
                onClick={collapseSidebarOnMobile}
                className="NavigationMenu__link NavigationMenu__link--mobile"
              />
            </Menu.Item>
          )
      )}

      {eulaLink && (
        <Menu.Item key="/eula">
          <LinkOutlined />
          <span>Пользовательское соглашение</span>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */}
          <a
            href={eulaLink}
            target="_blank"
            rel="noopener noreferrer"
            className="NavigationMenu__link"
          />

          {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */}
          <a
            href={eulaLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={collapseSidebarOnMobile}
            className="NavigationMenu__link NavigationMenu__link--mobile"
          />
        </Menu.Item>
      )}
    </Menu>
  );
};

const component = pipe(
  NavigationMenu,
  connect(mapState, mapDispatch),
  withRouter
);

export { component as NavigationMenu };
