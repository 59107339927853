import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { Reducer } from 'redux';
import { fromTraversable, Lens } from 'monocle-ts';
import { record } from 'fp-ts/lib/Record';

import { CustomerActionTypes, CustomerListTypes, CustomerState, CustomerPaginateLikeItem } from './types';

const paginateDefaults = {
  page: 1,
  page_size: 0,
  total: 0,
  number_of_active: 0,
  number_of_blocked: 0,
};
const initialState: CustomerState = {
  types: [],
  list: {
    active: {
      items: {},
      ...paginateDefaults,
    },
    blocked: {
      items: {},
      ...paginateDefaults,
    },
  },
};

const typesLens = Lens.fromProp<CustomerState>()('types');
const listLens = (node: CustomerListTypes) => Lens.fromPath<CustomerState>()(['list', node]); // eslint-disable-line @typescript-eslint/explicit-function-return-type
const pageLens = Lens.fromProp<CustomerPaginateLikeItem>()('page');

const resetBothList = (state: CustomerState): CustomerState =>
  Lens.fromProp<CustomerState>()('list')
    .composeTraversal(fromTraversable(record)())
    .composeLens(Lens.fromProps<CustomerPaginateLikeItem>()(['items', 'page_size', 'total']))
    .set({ items: {}, page_size: 0, total: 0 })(state);

const baseCustomerReducer: Reducer<CustomerState, CustomerActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case 'CUSTOMER_SET_TYPES':
      return typesLens.set(action.payload.items)(state);
    case 'CUSTOMER_SET_LIST_ITEM': {
      const lens = listLens(action.payload.selector);
      const oldValue = lens.get(state);
      const { items: pageItems, ...meta } = action.payload.response;
      const items = { ...oldValue.items, [meta.page]: pageItems };
      return lens.set({ items, ...meta })(state);
    }
    case 'CUSTOMER_SET_LIST_PAGE': {
      return listLens(action.payload.selector).compose(pageLens).set(action.payload.page)(state);
    }
    case 'CUSTOMER_SET_EMPTY_LIST': {
      return resetBothList(state);
    }
    default:
      return state;
  }
};

const persistConfig = {
  key: 'customer',
  storage,
  whitelist: ['types'],
};

export const customerReducer = persistReducer(persistConfig, baseCustomerReducer);
