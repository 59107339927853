import StrictEventEmitter from 'strict-event-emitter-types/src';
import { EventEmitter } from 'events';
import { useEffect } from 'react';

export interface BusEvents {} // eslint-disable-line @typescript-eslint/no-empty-interface

type ListenerType<T> = [T] extends [(...args: infer U) => any] ? U : [T] extends [void] ? [] : [T];
type EventBus = StrictEventEmitter<EventEmitter, BusEvents>;
type EventSubscribe = <T, P extends keyof BusEvents>(this: T, event: P, listener: (...args: ListenerType<BusEvents[P]>) => void) => void;
export const EventBus: EventBus = new EventEmitter();
export const useBusEffect: EventSubscribe = (event, listener): void => {
  useEffect(() => {
    EventBus.on(event, listener);
    return () => { EventBus.off(event, listener); }
  }, [event, listener]);
};
