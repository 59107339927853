import { CouriersDetailsResponse, CouriersListResponse, CouriersOnlineLocationsResponse } from '../../api/protocol';
import {
  COURIERS_REPLENISH_BALANCE,
  COURIERS_SET_CURRENT_LIST,
  COURIERS_SET_DETAILS,
  COURIERS_SET_EMPTY_DETAILS,
  COURIERS_SET_EMPTY_LIST,
  COURIERS_SET_LIST_ITEM,
  COURIERS_SET_LIST_PAGE,
  COURIERS_SET_LOCATION_RESULT,
  CouriersActionTypes,
  CouriersListTypes,
  CouriersReplenishBalancePayload,
} from './types';

export const setCouriersListItems = (payload: { response: CouriersListResponse, selector: CouriersListTypes }): CouriersActionTypes => ({
  type: COURIERS_SET_LIST_ITEM,
  payload,
});

export const setCouriersListPage = (payload: number): CouriersActionTypes => ({
  type: COURIERS_SET_LIST_PAGE,
  payload,
});

export const setCouriersEmptyList = (): CouriersActionTypes => ({
  type: COURIERS_SET_EMPTY_LIST
});

export const setCouriersCurrentList = (payload: CouriersListTypes): CouriersActionTypes => ({
  type: COURIERS_SET_CURRENT_LIST,
  payload,
});

export const setCouriersDetails = (payload: CouriersDetailsResponse): CouriersActionTypes => ({
  type: COURIERS_SET_DETAILS,
  payload,
});

export const couriersReplenishBalance = (payload: CouriersReplenishBalancePayload): CouriersActionTypes => ({
  type: COURIERS_REPLENISH_BALANCE,
  payload,
});

export const setCouriersEmptyDetails = (): CouriersActionTypes => ({
  type: COURIERS_SET_EMPTY_DETAILS,
});

export const setCouriersLocationList = (payload: CouriersOnlineLocationsResponse[]): CouriersActionTypes => ({
  type: COURIERS_SET_LOCATION_RESULT,
  payload,
});
