import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  Map,
  Marker,
  GoogleApiWrapper,
  IProvidedProps,
} from 'google-maps-react';
import moment, { Moment } from 'moment';
import { Button } from 'antd';

import { fromTraversable } from 'monocle-ts';
import { record } from 'fp-ts/lib/Record';
import { pipe } from 'fp-ts/es6/pipeable';

import {
  VerticalAlignTopOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { City, CourierLocation } from '../../api/protocol';
import { RootState } from '../../store/root';
import { CouriersLocationList } from '../../store/couriers/types';
import {
  apiCouriersOnlineLocations,
  makeMapDispatch,
} from '../../store/dispatcher';

import { EventBus } from '../../utils/bus';
import { useInterval } from '../../hooks/interval';
import { showApiErrorNotification } from '../../utils/noty';

import CourierMarkerIcon from '../../assets/Courier.svg';

import './CouriersMap.scss';
import { CounterTag } from '../CounterTag';

type StateProps = { positions: CouriersLocationList, city: City };
const mapState = (state: RootState): StateProps => ({
  positions: state.couriers.locationList.items,
  city: state.user.city,
});
const mapDispatch = makeMapDispatch({
  fetchLocations: apiCouriersOnlineLocations,
});

type Props = ReturnType<typeof mapDispatch> & IProvidedProps & StateProps;
const CouriersMap: FunctionComponent<Props> = props => {
  const { google, fetchLocations, positions, city } = props;
  const { Point, Size, LatLngBounds, LatLng } = google.maps;

  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(true);

  const [lastDate, setLastDate] = useState<Moment | null>(null);

  useInterval(() => setFetched(false), 7500);
  useEffect(() => {
    if (fetched || loading) return;
    setLoading(true);
    fetchLocations(lastDate)
      .then(() => {
        setFetched(true);
        setLastDate(moment());
        setLoading(false);
      })
      .catch(e => {
        showApiErrorNotification(e);
        setTimeout(() => setLoading(false), 3000);
      })
  }, [fetched, loading, lastDate, fetchLocations]);

  const defaultCenter = {
    lat: city.latitude,
    lng: city.longitude,
  };

  const courierIcon = useMemo(
    () => ({
      url: CourierMarkerIcon,
      size: new Size(31, 48),
      anchor: new Point(15.5, 40),
    }),
    [Size, Point]
  );

  const [markers, bounds] = useMemo(() => {
    const markerBounds = new LatLngBounds();
    const markerList = fromTraversable(record)<CourierLocation>()
      .filter(courier => courier.is_online)
      .asFold()
      .getAll(positions)
      .map(location => {
        const point = new LatLng(location.latitude, location.longitude);
        markerBounds.extend(point);
        return (
          <Marker
            key={`courier-marker-${location.id}`}
            icon={courierIcon}
            title="Показать подробности"
            onClick={() => {
              EventBus.emit('openCourierPreview', location.id);
            }}
            position={point}
          />
        );
      });
    return [markerList, markerBounds];
  }, [positions, courierIcon, LatLng, LatLngBounds]);

  return (
    <section className="CouriersMap">
      <header className="CouriersMap__header">
        {markers.length > 0 && (
          <Button danger>
            <span style={{ marginRight: 8 }}>Курьеров в работе</span>
            <CounterTag>{markers.length}</CounterTag>
          </Button>
        )}
        <Button
          type="primary"
          style={{ pointerEvents: 'all' }}
          className="CouriersMap__primaryButton"
          onClick={() => setHidden(!hidden)}
        >
          {hidden ? (
            <>
              <VerticalAlignTopOutlined />
              <span className="CouriersMap__buttonText">Развернуть карту</span>
            </>
          ) : (
            <>
              <VerticalAlignBottomOutlined />
              <span className="CouriersMap__buttonText">Свернуть карту</span>
            </>
          )}
        </Button>
      </header>
      <main className={hidden ? 'hidden' : ''}>
        <Map
          bounds={bounds}
          google={google}
          style={{ width: '100%', height: '400px' }}
          initialCenter={defaultCenter}
          mapTypeControl={false}
          fullscreenControl={false}
          streetViewControl={false}
          visible={!hidden}
        >
          {markers}
        </Map>
      </main>
    </section>
  );
};

const component = pipe(
  CouriersMap,
  connect(mapState, mapDispatch),
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    language: 'ru',
  })
);
export { component as CouriersMap };
