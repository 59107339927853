import React, { FunctionComponent, useState } from 'react';

import { Drawer } from 'antd';

import { OperatorWithStats } from '../../api/protocol';

import { foldView } from '../../utils/view';

import { CenteredText, LoaderAdaptive } from '../Loader';
import { OperatorPreview } from '../OperatorPreview/OperatorPreview';
import { useBusEffect } from '../../utils/bus';

declare module '../../utils/bus' {
  interface BusEvents {
    openOperatorDrawer: OperatorWithStats;
  }
}

export const OperatorDrawer: FunctionComponent = () => {
  const [data, setData] = useState<OperatorWithStats | null>(null);
  const [isOpen, setOpen] = useState(false);
  const foldV = foldView(data, false, null);

  const onClose = (): void => setOpen(false);

  useBusEffect('openOperatorDrawer', value => {
    setOpen(true);
    setData(value);
  });

  return (
    <Drawer
      className="CourierPreview__drawer"
      width={640}
      footer={null}
      onClose={onClose}
      visible={isOpen}
    >
      {foldV(
        () => (
          <CenteredText text="Оператор не найден" size="small" />
        ),
        () => (
          <LoaderAdaptive />
        ),
        () => null,
        operatorData => (
          <OperatorPreview operatorData={operatorData} />
        )
      )}
    </Drawer>
  );
};
