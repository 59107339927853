import React, { FunctionComponent, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Drawer } from 'antd';

import './CourierPreview.scss';

import { CouriersDetailsResponse, CourierTransaction } from '../../api/protocol';
import { apiCouriersGet, apiCourierTransactionList, makeMapDispatch } from '../../store/dispatcher';
import { RootState } from '../../store/root';

import { showApiErrorNotification } from '../../utils/noty';
import { useBusEffect } from '../../utils/bus';

import { LoaderAdaptive } from '../../components/Loader';
import { CourierProfilePreview } from '../../components/CourierProfilePreview/CourierProfilePreview';

declare module '../../utils/bus' {
  interface BusEvents {
    openCourierPreview: number
  }
}

type PT = PaginateLikeList<CourierTransaction>;
type PCT = PaginateLikeCacheList<CourierTransaction>;
type StateProps = { cacheList: RootState['couriers']['details'] }
const mapState = (state: RootState): StateProps => ({ cacheList: state.couriers.details });
const mapDispatch = makeMapDispatch({ getDetails: apiCouriersGet, getTransactions: apiCourierTransactionList });
type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
const CourierPreview: FunctionComponent<Props> = props => {
  const { cacheList, getDetails, getTransactions } = props;

  const [data, setData] = useState<CouriersDetailsResponse>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClose = (): void => { setVisible(false); };

  useBusEffect('openCourierPreview', id => {
    setVisible(true);
    if (id in cacheList) setData(cacheList[id]);
    else {
      setLoading(true);
      getDetails({ id })
        .then(setData)
        .catch(showApiErrorNotification)
        .finally(() => { setLoading(false); });
    }
  });

  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [transactionsList, setTransactionsList] = useState<PCT>({
    items: {},
    page: 1,
    total: 0,
    page_size: 0,
  });

  const setTransactionsListFromResponse = (transactions: PT): void => {
    const { items, page, ...other } = transactions;
    setTransactionsList(h => ({ items: { ...h.items, [page]: items }, page, ...other }));
  };

  useEffect(() => { if (data) setTransactionsListFromResponse(data.transactions); }, [data]);

  const onTransactionsPageChange = (page: number): void => {
    if (!data) return;
    if (page in transactionsList.items) setTransactionsList(h => ({ ...h, page }));
    else {
      setTransactionsLoading(true);
      getTransactions({ courier_id: data.id, page })
        .then(setTransactionsListFromResponse)
        .catch(showApiErrorNotification)
        .finally(() => { setTransactionsLoading(false); });
    }
  };

  let content = null;
  if (loading) content = <LoaderAdaptive/>;
  else if (data) content = (
    <CourierProfilePreview
      loading={transactionsLoading}
      profileData={data}
      transactions={transactionsList}
      onPageChange={onTransactionsPageChange}
    />
  );

  return (
    <Drawer
      className="CourierPreview__drawer"
      width={750}
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
    >
      {content}
    </Drawer>
  );
};

const connected = withRouter(connect(mapState, mapDispatch)(CourierPreview));
export { connected as CourierPreview };
