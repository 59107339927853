import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/es6/pipeable';

import { Input, message, Modal } from 'antd';

import { apiNotificationCreate, makeMapDispatch } from '../../store/dispatcher';
import { useBusEffect } from '../../utils/bus';

import './PushNotification.scss';
import { showApiErrorNotification } from '../../utils/noty';

declare module '../../utils/bus' {
  interface BusEvents {
    showPushForm: void;
  }
}

const defaultTitle = 'Важная информация';
const defaultText = '';

const mapActions = makeMapDispatch({ sendPush: apiNotificationCreate });

type Props = ReturnType<typeof mapActions>;
const PushNotification: FunctionComponent<Props> = props => {
  const { sendPush } = props;

  const [text, setText] = useState(defaultText);
  const [title, setTitle] = useState(defaultTitle);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const onCancel = (): void => {
    setText(defaultText);
    setTitle(defaultTitle);
    setLoading(false);
    setVisible(false);
  };

  const onConfirm = async (): Promise<void> => {
    if (loading) return;
    setLoading(true);
    try {
      await sendPush({ title, text });
      message.success({ content: 'Уведомление отправлено' });
      onCancel();
    } catch (e) {
      showApiErrorNotification(e);
      setLoading(false);
    }
  };

  useBusEffect('showPushForm', () => setVisible(true));

  return (
    <Modal
      title="Новое push-уведомление"
      onOk={onConfirm}
      okText="Отправить"
      visible={visible}
      onCancel={onCancel}
      okButtonProps={{ loading, disabled: title.length < 1 || text.length < 1 }}
    >
      <div className="PushNotification">
        <div className="PushNotification__formItem">
          <p className="PushNotification__formTitle">Заголовок</p>
          <Input
            maxLength={255}
            value={title}
            onChange={evt => setTitle(evt.target.value)}
          />
        </div>
        <div className="PushNotification__formItem">
          <p className="PushNotification__formTitle">Сообщение</p>
          <Input.TextArea
            maxLength={255}
            value={text}
            onChange={evt => setText(evt.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

const component = pipe(PushNotification, connect(null, mapActions));

export { component as PushNotification };
