import { array } from 'fp-ts/lib/Array';
// import { record } from 'fp-ts/lib/Record';
import { Lens, fromTraversable } from 'monocle-ts';

export type ErrorMessage = { code: string, message: string }
export type PropertyError = { [path: string]: ErrorMessage[] }
export type ApiErrorResponse = { error: PropertyError }

// HTK lens for record
// const errorLens = Lens.fromProp<ApiErrorResponse>()('error');
// const propertyErrorTraverse = errorLens.composeTraversal<ErrorMessage[]>(fromTraversable(record)());

const errorMessageTraverse = fromTraversable(array)<ErrorMessage>();
const messageLens = Lens.fromProp<ErrorMessage>()('message');
const codeLens = Lens.fromProp<ErrorMessage>()('code');

const errorMessagesLensComposeToCodes = errorMessageTraverse.composeLens(codeLens).asFold();
const errorMessagesLensComposeToMessages = errorMessageTraverse.composeLens(messageLens).asFold();

export class ApiError {
    private common: ErrorMessage[] | null = null;
    private invalidFields: PropertyError = {};

    constructor(readonly response: ApiErrorResponse) {
        Object.keys(response.error).forEach(key => {
            const error = response.error[key];
            if (key === '' && error.length) {
                this.common = error
            } else {
                this.invalidFields[key] = error;
            }
        })
    }

    isCommon(): boolean { return this.common !== null; }

    getCommonCodes(): string[] {
        if (this.common === null) return [];
        return errorMessagesLensComposeToCodes.getAll(this.common);
    }

    getCommonMessages(): string[] {
        if (this.common === null) return [];
        return errorMessagesLensComposeToMessages.getAll(this.common);
    }

    getCommonFirstCode(): string | null {
        const codes = this.getCommonCodes();
        if (codes.length === 0) return null;
        return codes[0];
    }

    getCommonFirstMessage(): string | null {
        const messages = this.getCommonMessages();
        if (messages.length === 0) return null;
        return messages[0];
    }

    hasInvalidField(key: string): boolean { return key in this.invalidFields; }

    getInvalidFieldsKeys(): string[] { return Object.keys(this.invalidFields) }

    getInvalidFieldCodes(key: string): string[] {
        if (!this.hasInvalidField(key)) return [];
        return errorMessagesLensComposeToCodes.getAll(this.invalidFields[key])
    }

    getInvalidFieldMessages(key: string): string[] {
        if (!this.hasInvalidField(key)) return [];
        return errorMessagesLensComposeToMessages.getAll(this.invalidFields[key])
    }
}
