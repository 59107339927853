import React, { FunctionComponent, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import moment from 'moment';

import { Button, Tag } from 'antd';
import {
  ClockCircleOutlined,
  InboxOutlined,
  BankOutlined,
  IdcardOutlined,
  UserSwitchOutlined,
  CommentOutlined,
  WalletOutlined,
} from '@ant-design/icons';

import { RootState } from '../../store/root';
import { AddressInfo, OrderListItem, UserRole } from '../../api/protocol';

import { EventBus } from '../../utils/bus';
import { formatCurrency } from '../../utils/strings';
import { formatDate, formatPercents, formatPhone, formatPrice } from '../../utils/view';

import { OrderMap } from '../OrderMap/OrderMap';

import './OrderPreview.scss';

type BlockType = {
  label: string;
  heading?: ReactNode;
  address?: ReactNode;
  phone?: string;
  icon?: JSX.Element;
};

const Block: React.FunctionComponent<BlockType> = data => (
  <div className="OrderPreviewBlock">
    <div className="OrderPreviewBlock__label">
      {data.icon}
      {data.label}
    </div>

    {data.heading && (
      <div className="OrderPreviewBlock__heading">{data.heading}</div>
    )}

    {data.address && (
      <div className="OrderPreviewBlock__address">{data.address}</div>
    )}

    {data.phone && (
      <a className="OrderPreviewBlock__phone" href={`tel:+${data.phone}`}>
        {formatPhone(data.phone)}
      </a>
    )}

    {data.children}
  </div>
);

type StateProps = {
  canAssign: boolean
  userRole: UserRole | null
};
const mapState = (state: RootState): StateProps => ({
  canAssign: state.user.role === 'operator',
  userRole: state.user.role,
});

type Props = { orderData: OrderListItem } & StateProps & RouteComponentProps;
const OrderPreview: FunctionComponent<Props> = props => {
  const { orderData, canAssign, userRole } = props;

  const courierProfit = orderData.delivery_price * (orderData.courier_commission_percent / 100);
  const toPickUp = moment(orderData.to_pick_up_at);
  const toDeliver = moment(orderData.to_deliver_at);
  const diff = toDeliver.diff(toPickUp, 'minutes');

  const mapSource = (orderData.customer.branch?.address ||
    orderData.customer.address) as AddressInfo;
  const mapDestination = orderData.recipient.address;

  return (
    <div className="OrderPreview">
      <div className="CourierProfilePreview__container">
        <div className="OrderPreview__id">
          <InboxOutlined style={{ marginRight: 8 }} />
          Заказ №{orderData.id}
        </div>

        {orderData.courier ? (
          <Tag color="success">Курьер назначен</Tag>
        ) : (
          <Tag color="red">Курьер не назначен</Tag>
        )}

        <div className="OrderPreview__blocks">
          <Block
            label="Заведение"
            icon={<BankOutlined style={{ marginRight: 8 }} />}
            heading={orderData.customer.name}
            address={
              orderData.customer.address?.address ||
              orderData.customer.branch?.address?.address
            }
            phone={orderData.phone}
          />

          <Block
            label="Клиент"
            icon={<IdcardOutlined style={{ marginRight: 8 }} />}
            heading={orderData.recipient.name}
            address={orderData.recipient.address.address}
            phone={orderData.recipient.phone}
          />

          {orderData.courier ? (
            <Block
              label="Курьер"
              icon={<UserSwitchOutlined style={{ marginRight: 8 }} />}
              heading={orderData.courier.full_name}
              phone={orderData.courier.phone}
            />
          ) : (
            <Block
              label="Курьер"
              icon={<UserSwitchOutlined style={{ marginRight: 8 }} />}
              heading={
                canAssign ? (
                  <Button
                    onClick={() => EventBus.emit('assignCourier', orderData.id)}
                  >
                    Назначить курьера
                  </Button>
                ) : (
                  'Курьер не назначен'
                )
              }
            />
          )}

          <Block
            label="Время на доставку"
            icon={<ClockCircleOutlined style={{ marginRight: 8 }} />}
          >
            <div className="OrderPreviewBlock__time">
              {formatDate(toPickUp)}
            </div>
            <Tag className="OrderPreviewBlock__tag">
              <ClockCircleOutlined style={{ marginRight: 4 }} />
              {diff} мин
            </Tag>
            <div className="OrderPreviewBlock__time">
              {formatDate(toDeliver)}
            </div>
          </Block>

          {orderData.comment && (
            <Block
              icon={<CommentOutlined style={{ marginRight: 8 }} />}
              label="Комментарий к заказу"
            >
              <div className="OrderPreviewBlock__comment">
                {orderData.comment}
              </div>
            </Block>
          )}

          {orderData.customer.branch?.comment && (
            <Block
              label="Комментарий к заведению"
              icon={<CommentOutlined style={{ marginRight: 8 }} />}
            >
              <div className="OrderPreviewBlock__comment">
                {orderData.customer.branch.comment}
              </div>
            </Block>
          )}
        </div>

        <div className="OrderPreview__map">
          <OrderMap
            source={mapSource}
            destination={mapDestination}
            courierId={orderData.courier?.id}
            showCourier={
              !(
                orderData.status === 'canceled' ||
                orderData.status === 'delivered'
              )
            }
          />
        </div>

        <div className="OrderPreview__totals OrderPreviewTotals">
          <div>
            <h4 className="OrderPreviewTotals__label">
              <WalletOutlined style={{ marginRight: 8 }} />
              <span>Общая сумма заказа</span>
            </h4>

            <div className="OrderPreviewTotals__text">
              <strong>{formatPrice(orderData.order_price.toString())} {formatCurrency()}</strong>
            </div>

            <div className="OrderPreviewTotals__status">
              {orderData.is_paid ? (
                <Tag color="success">Оплачен</Tag>
              ) : (
                <Tag color="red">Не оплачен</Tag>
              )}
            </div>
          </div>

          <ul className="OrderPreviewTotals__list">
            <li className="OrderPreviewTotals__listItem OrderPreviewTotals__text">
              Стоимость доставки:{' '}
              <strong>
                {formatPrice(orderData.delivery_price.toString())} {formatCurrency()}
              </strong>
            </li>

            {userRole !== 'customer' && (
              <>
                <li className="OrderPreviewTotals__listItem OrderPreviewTotals__text">
                  Комиссия сервиса:{' '}
                  <strong>{formatPrice((orderData.delivery_price - courierProfit).toString())} {formatCurrency()}</strong>
                </li>

                <li className="OrderPreviewTotals__listItem OrderPreviewTotals__text">
                  Выручка курьера:{' '}
                  <strong>{formatPrice(courierProfit.toString())} {formatCurrency()}</strong>
                </li>

                <li className="OrderPreviewTotals__listItem OrderPreviewTotals__text">
                  Комиссия курьера:{' '}
                  <strong>
                    {formatPercents(orderData.courier_commission_percent)} %
                  </strong>
                </li>
              </>
            )}

            <li className="OrderPreviewTotals__listItem OrderPreviewTotals__text">
              Дистанция: <strong>{orderData.distance_km} км</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const component = withRouter(connect(mapState)(OrderPreview));

export { component as OrderPreview };
