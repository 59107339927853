import React, { FunctionComponent, useMemo, useState } from 'react';

import { Button, Form, Select } from 'antd';
import { Store as FormValues } from 'rc-field-form/lib/interface';

import { eqStrict, getStructEq } from 'fp-ts/es6/Eq';

import { OperatorsOrderStatisticsRequest } from '../api/protocol';

import { SearchInput, Options } from './SearchInput';
import { DateTimeInput} from './Inputs';
import { clearFormData } from '../utils/form';

export type FilterData = Omit<OperatorsOrderStatisticsRequest, 'page'>;

const initialValues: FilterData = {
  date_from: undefined,
  date_to: undefined,
  courier_id: undefined,
  customer_id: undefined,
  branch_id: undefined,
  status: undefined,
};

const filtersEq = getStructEq<FilterData>({
  customer_id: eqStrict,
  branch_id: eqStrict,
  courier_id: eqStrict,
  date_from: eqStrict,
  date_to: eqStrict,
  status: eqStrict,
});

type Props = {
  onFiltersDataChange: (filters: FilterData) => void
  isFiltersApplying: boolean
  defaultBranches: Options
  isOperator: boolean
  isCouriers?: boolean
}
export const StatisticFilters: FunctionComponent<Props> = props => {
  const {
    onFiltersDataChange,
    isFiltersApplying,
    isOperator,
    defaultBranches,
    isCouriers = false,
  } = props;

  const [formRef] = Form.useForm();

  const [branches, setBranches] = useState<Options>(defaultBranches);
  const [appliedFilters, setAppliedFilters] = useState(initialValues);
  const [currentFilters, setCurrentFilters] = useState(initialValues);

  const onDataChange = (value: FormValues, data: FormValues): void => setCurrentFilters(data as FilterData);
  const onFinish = (data: FormValues): void => {
    const filters = data as FilterData;
    setAppliedFilters(filters);
    onFiltersDataChange(clearFormData(filters, true));
  };
  const onSelectCustomer = (options: Options): void => {
    formRef.setFields([{ name: 'branch_id', value: undefined }]);
    setBranches(options);
  };

  const cantApplyFilters = useMemo(() => filtersEq.equals(appliedFilters, currentFilters), [appliedFilters, currentFilters]);

  return (
    <div className="Statistics__filters">
      <Form
        form={formRef}
        initialValues={currentFilters}
        className="Statistics__filtersForm StatsFiltersForm"
        layout="vertical"
        onValuesChange={onDataChange}
        onFinish={onFinish}
      >
        <Form.Item
          className="StatsFiltersForm__item"
          label="Начиная c"
          name='date_from'
        >
          <DateTimeInput outputFormat='iso8601' />
        </Form.Item>

        <Form.Item
          className="StatsFiltersForm__item"
          label="Заканчивая по"
          name='date_to'
        >
          <DateTimeInput outputFormat='iso8601' endOfDay/>
        </Form.Item>

        {isCouriers &&
          <Form.Item
            className="StatsFiltersForm__item StatsFiltersForm__item--organization"
            label="Курьер"
            name='courier_id'
          >
            <SearchInput mode='courier'  />
          </Form.Item>
        }

        {
          isOperator && !isCouriers &&
          <Form.Item
            className="StatsFiltersForm__item StatsFiltersForm__item--organization"
            label="Организация"
            name='customer_id'
          >
            <SearchInput onSelectCustomer={onSelectCustomer}/>
          </Form.Item>
        }
        {!isCouriers &&
          <Form.Item
            className="StatsFiltersForm__item"
            label="Филиал"
            name='branch_id'
          >
            <Select
              placeholder='Все филиалы'
              dropdownClassName="StatsFiltersForm__dropdown"
              className='StatsFiltersForm__select StatsFiltersForm__item--branch'
              options={branches}
              disabled={branches.length === 0}
              allowClear
            />
          </Form.Item>
        }
        <Form.Item
          className="StatsFiltersForm__item"
          label="Статус"
          name='status'
        >
          <Select
            className='StatsFiltersForm__item--status'
            placeholder='Все статусы'
            allowClear
          >
            <Select.Option value='new'>В ожидании</Select.Option>
            <Select.Option value='courier_assigned'>Назначены</Select.Option>
            <Select.Option value='in_transit'>Выполняются</Select.Option>
            <Select.Option value='delivered'>Выполненные</Select.Option>
            <Select.Option value='canceled'>Отмененные</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item className="StatsFiltersForm__submit">
          <Button
            type="primary"
            htmlType='submit'
            disabled={cantApplyFilters}
            loading={isFiltersApplying}
          >
            Применить фильтры
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
