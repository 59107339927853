import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Map, Marker, GoogleApiWrapper, IProvidedProps } from 'google-maps-react';
import { connect } from 'react-redux';

import { pipe } from 'fp-ts/es6/pipeable';

import { RootState } from '../../store/root';
import { AddressInfo } from '../../api/protocol';
import { CouriersLocationList } from '../../store/couriers/types';

import './OrderMap.scss';

import CourierStartMarkerIcon from '../../assets/pointA.svg';
import CourierFinishMarkerIcon from '../../assets/pointB.svg';
import CourierCurrentMarkerIcon from '../../assets/Courier.svg';

const defaultCenter = { lat: 43.10562, lng: 131.87353 };

type IconType = {
  url: string,
  size: google.maps.Size,
  anchor: google.maps.Point,
}

type ComponentProps = {
  courierId?: number
  source: AddressInfo
  destination: AddressInfo
  showCourier: boolean
}

type StateProps = { activeCouriersList: CouriersLocationList }
const mapState = (state: RootState): StateProps => ({ activeCouriersList: state.couriers.locationList.items });

type Props = IProvidedProps & ComponentProps & StateProps;
const OrderMap: FunctionComponent<Props> = props => {
  const { google, source, destination, courierId, activeCouriersList, showCourier } = props;
  const { Point, Size, LatLngBounds, LatLng } = google.maps;

  type LatLngBounds = google.maps.LatLngBounds

  const iconMarker = useCallback((icon: string): IconType => ({
    url: icon,
    size: new Size(31, 48),
    anchor: new Point(15.5, 40),
  }), [Size, Point]);

  const courierStartMarker = useMemo(() => iconMarker(CourierStartMarkerIcon), [iconMarker]);
  const courierFinishMarker = useMemo(() => iconMarker(CourierFinishMarkerIcon), [iconMarker]);
  const courierCurrentMarker = useMemo(() => iconMarker(CourierCurrentMarkerIcon), [iconMarker]);

  const [bounds, setBounds] = useState<LatLngBounds>();
  const [markers, setMarkers] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const locations = [
      {
        id: 'source',
        icon: courierStartMarker,
        latitude: source.latitude,
        longitude: source.longitude,
      },
      {
        id: 'destination',
        icon: courierFinishMarker,
        latitude: destination.latitude,
        longitude: destination.longitude,
      },
    ];

    if (courierId && showCourier) {
      const courierLocation = activeCouriersList[courierId];
      if (courierLocation) locations.push({
        id: 'courier',
        icon: courierCurrentMarker,
        latitude: courierLocation.latitude,
        longitude: courierLocation.longitude,
      });
    }

    const mapBounds = new LatLngBounds();
    const mapMarkers: JSX.Element[] = [];
    locations.forEach(location => {
      const point = new LatLng(location.latitude, location.longitude);
      mapBounds.extend(point);
      mapMarkers.push(
        <Marker
          key={`order-marker-${location.id}`}
          icon={location.icon}
          title='Показать подробности'
          position={point}
        />,
      );
    });

    setBounds(mapBounds);
    setMarkers(mapMarkers);
  }, [
    LatLng,
    LatLngBounds,
    source,
    destination,
    courierId,
    showCourier,
    activeCouriersList,
    courierStartMarker,
    courierFinishMarker,
    courierCurrentMarker,
  ]);

  return (
    <div className="OrderMap">
      <Map
        bounds={bounds}
        google={google}
        style={{ width: '100%' }}
        initialCenter={defaultCenter}
        mapTypeControl={false}
        fullscreenControl={false}
        streetViewControl={false}
      >
        {markers}
      </Map>
    </div>
  );
};

const component = pipe(
  OrderMap,
  connect(mapState),
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    language: 'ru',
  }),
);
export { component as OrderMap };
