import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { persistStore } from 'redux-persist';

import { UserActionTypes } from './user/types';
import { NewsActionTypes } from './news/types';
import { OrdersActionTypes } from './orders/types';
import { CustomerActionTypes } from './customers/types';
import { CouriersActionTypes } from './couriers/types';

import { userReducer } from './user/reducer';
import { newsReducer } from './news/reducer';
import { ordersReducer } from './orders/reducer';
import { customerReducer } from './customers/reducer';
import { couriersReducer } from './couriers/reducer';

export const rootReducer = combineReducers({
  user: userReducer,
  news: newsReducer,
  customers: customerReducer,
  couriers: couriersReducer,
  orders: ordersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type RootActionType =
  | UserActionTypes
  | NewsActionTypes
  | CustomerActionTypes
  | CouriersActionTypes
  | OrdersActionTypes

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<RootState, RootActionType>))
);

export const persistor = persistStore(store);
