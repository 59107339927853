import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Empty, Table, Tag, Tooltip } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { PhoneOutlined, EditOutlined } from '@ant-design/icons';

import { formatCurrency } from '../utils/strings';
import { CouriersListItem } from '../api/protocol';
import { formatPhone, formatPrice } from '../utils/view';

import { AddMoneyIcon } from './AddMoneyIcon/AddMoneyIcon';
import { StatusTag } from './StatusTag';

const createColumnSettings = (
  redirectTo: (path: string) => void,
  onReplenishBalance: (id: number) => void
): ColumnsType<CouriersListItem> => [
  {
    title: '№',
    dataIndex: 'id',
    render: (id: number) => (
      <div className="ResponsiveTable__idWrapper">
        <h3 className="ResponsiveTable__mobileHeading">
          № курьера:
        </h3>
        <Tag color="red" className="ResponsiveTable__id">{id}</Tag>
      </div>
    ),
  },
  {
    title: 'ФИО',
    key: 'name',
    render: ({
      first_name: FN,
      middle_name: MN,
      last_name: LN,
      user: { phone },
    }: CouriersListItem) => {
      const name = `${LN} ${FN[0]}.${MN[0]}.`;
      return (
        <>
          <h3 className="ResponsiveTable__mobileHeading">
            Имя
          </h3>
          <p style={{ fontWeight: 'bold', marginBottom: 4 }}>{name}</p>
          {phone && (
            <a
              className="OrganizationsTable__agentPhone"
              href={`tel:+${phone}`}
            >
              <PhoneOutlined />
              {formatPhone(phone)}
            </a>
          )}
        </>
      );
    },
  },
  {
    title: 'Статус',
    dataIndex: 'is_online',
    render: (isOnline: boolean) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">
          Статус
        </h3>
        <StatusTag isOnline={isOnline}/>
      </>
    ),
  },
  {
    title: 'Выполненные заказы',
    dataIndex: 'orders_number',
    className: 'CouriersTable__completedOrdersCell',
    render: (ordersNumber: number) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">
          Выполненные заказы
        </h3>
        <div>{ordersNumber ?? 'Нет'}</div>
      </>
    ),
  },
  {
    title: 'Баланс',
    className: 'CouriersTable__balanceCell',
    render: ({ balance }: CouriersListItem) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">
          Баланс
        </h3>
        <div>{`${formatPrice(balance.toString())} ${formatCurrency()}`}</div>
      </>
    ),
  },
  {
    title: '',
    key: 'actions',
    className: 'CouriersTable__actionCell',
    render: ({ id }: CouriersListItem) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">
          Действия
        </h3>
        <div className="CouriersTable__actions">
          <Tooltip title="Пополнить баланс">
            <Button
              type="primary"
              className="CouriersTable__action"
              icon={AddMoneyIcon}
              onClick={e => {
                e.stopPropagation();
                onReplenishBalance(id);
              }}
            />
          </Tooltip>
          <Tooltip title="Изменить">
            <Button
              type="primary"
              className="CouriersTable__action"
              icon={<EditOutlined />}
              onClick={e => {
                e.stopPropagation();
                redirectTo('/couriers/edit/' + id);
              }}
            />
          </Tooltip>
        </div>
      </>
    ),
  },
];

type Props = {
  loading: boolean;
  couriers: CouriersListItem[];
  pagination: TablePaginationConfig;
  onRowClick: (id: number) => void;
  onPageChange: (number: number) => void;
  onReplenishBalance: (id: number) => void;
};

export const CouriersTable: FunctionComponent<Props> = props => {
  const {
    onReplenishBalance,
    onPageChange,
    onRowClick,
    loading,
    couriers,
    pagination,
  } = props;
  const { push } = useHistory();
  const columnsConfig = useMemo(
    () => createColumnSettings(push, onReplenishBalance),
    [push, onReplenishBalance]
  );
  return (
    <Table
      rowKey="id"
      className="CouriersTable ResponsiveTable ResponsiveTable--couriers"
      loading={loading}
      columns={columnsConfig}
      dataSource={couriers}
      pagination={pagination}
      onRow={record => ({ onClick: () => onRowClick(record.id) })}
      onChange={paginator => {
        onPageChange(paginator.current || 1);
      }}
      locale={{
        emptyText: (
          <Empty
            description="Список курьеров пуст"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ),
      }}
    />
  );
};
