import { pipe } from 'fp-ts/es6/pipeable';
import { map, isNonEmpty } from 'fp-ts/es6/Array';

import { NamePath, FieldData } from 'rc-field-form/lib/interface';
import { FormInstance } from 'antd/lib/form';
import { message } from 'antd';

import { ApiError } from '../api/client/errors';
import { possibleToBeNumber } from './form';

export const showApiErrorNotification = (error: unknown): void => {
  if (error instanceof ApiError && error.isCommon()) {
    message.warn(error.getCommonFirstMessage());
  }
};

const transformKey = (key: string): NamePath =>
  key
    .split('.')
    .map(chunk => possibleToBeNumber(chunk) ? parseFloat(chunk) : chunk);

export const setFormError = (error: unknown, form: FormInstance): void => {
  if (error instanceof ApiError && !error.isCommon()) {
    const fieldsErrors = pipe(
      error.getInvalidFieldsKeys(),
      map((key: string): FieldData => ({ name: transformKey(key), errors: error.getInvalidFieldMessages(key) }))
    );

    if (isNonEmpty(fieldsErrors)) form.setFields(fieldsErrors);
  }
};

export const bindErrorHandler = (form: FormInstance, callback: () => void) => (error: unknown) => {
  showApiErrorNotification(error);
  setFormError(error, form);
  callback();
};
