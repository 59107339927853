import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { pipe } from 'fp-ts/es6/pipeable';

import {
  ApartmentOutlined,
  IdcardOutlined,
  LeftOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import Password from 'antd/lib/input/Password';
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Select,
  Tooltip,
} from 'antd';

import {
  apiGetCityList,
  apiOperatorChangeData,
  makeMapDispatch,
} from '../../store/dispatcher';

import { clearFormData } from '../../utils/form';
import { bindErrorHandler, showApiErrorNotification } from '../../utils/noty';

import {
  City,
  Operator,
  OperatorChangeDataMode,
  OperatorChangeDataRequest,
} from '../../api/protocol';

import { PhoneInput } from '../Inputs';

import './OperatorForm.scss';

const required = [{ required: true, message: 'Пожалуйста, заполните поле.' }];

const mapActions = makeMapDispatch({
  changeData: apiOperatorChangeData,
  getCities: apiGetCityList,
});

type OperatorFormProps = ReturnType<typeof mapActions> & {
  mode: OperatorChangeDataMode;
  data?: Operator;
};
const OperatorForm: FunctionComponent<OperatorFormProps> = props => {
  const { mode, data, changeData, getCities } = props;

  const { push } = useHistory();
  const [formRef] = useForm();

  const [loadingCities, setLoadingCities] = useState(false);
  const [cities, setCities] = useState<City[]>([]);
  const [loading, setLoading] = useState(false);

  const errorHandler = bindErrorHandler(formRef, () => setLoading(false));
  const isCreateMode = mode === 'create';
  const isUpdateMode = mode === 'update';
  const citiesOptions = cities.map(city => ({
    label: city.name,
    value: city.id,
  }));

  const onSubmit = (): void => {
    if (loading) return;
    setLoading(true);

    const payload = clearFormData(
      formRef.getFieldsValue(),
    ) as OperatorChangeDataRequest;

    const { password } = payload.user;
    if (isUpdateMode) {
      if (!password || password.length === 0) {
        payload.user.password = undefined;
      }

      if (data && data.id) {
        payload.id = data.id;
      }
    }

    changeData(payload, mode)
      .then(() => {
        message.success(
          isCreateMode
            ? 'Франчайзи успешно создан'
            : 'Франчайзи успешно изменён',
        );

        if (isCreateMode) push('/operators');
      })
      .catch(e => {
        showApiErrorNotification(e);
        errorHandler(e);
      })
      .finally(() => setLoading(false));
  };

  const fetchCities = (): void => {
    if (loadingCities) return;
    setLoadingCities(true);

    getCities()
      .then(({ items }) => setCities(items))
      .catch(showApiErrorNotification)
      .finally(() => setLoadingCities(false));
  };

  useEffect(fetchCities, []);

  const submitButton = (isMobile = false): JSX.Element => (
    <Button
      className={
        clsx(
          'OperatorForm__submit',
          'CouriersForm__submitButton',
          isMobile && 'CouriersForm__submitButton--mobile',
        )
      }
      type='primary'
      htmlType='submit'
      size='large'
      loading={loading}
      onClick={onSubmit}
    >
      {!loading && <SaveOutlined />}

      {isCreateMode ? 'Добавить' : 'Сохранить'}
    </Button>
  );

  return (
    <Form
      layout="vertical"
      form={formRef}
      className="CouriersForm OperatorForm"
      scrollToFirstError
      initialValues={{
        id: isUpdateMode && data ? data.id : undefined,
        name: data?.name || '',
        note: data?.note || '',
        user: {
          phone: data?.phone || '',
          username: data?.username || '',
          password: '',
          is_blocked: data?.is_blocked || false,
        },
      }}
    >
      <Card className="OperatorForm__card">
        <div className="CouriersForm__header OperatorForm__header">
          <div className="CouriersForm__headerLeft">
            <Tooltip title="Назад к списку франчайзи">
              <Button
                type="link"
                onClick={() => push('/operators')}
                className="CouriersForm__headerBack"
              >
                <LeftOutlined />
              </Button>
            </Tooltip>

            <h2 className="CouriersForm__headerTitle">
              /
              <ApartmentOutlined style={{ marginRight: 8, marginLeft: 8 }} />
              <span>Франчайзи</span>
            </h2>
          </div>

          {submitButton()}
        </div>

        <Divider />

        <div className="OperatorForm__grid">
          <Form.Item label="ФИО" name="name" rules={required}>
            <Input />
          </Form.Item>

          {isCreateMode && (
            <Form.Item
              label="Город подключения"
              name="city_id"
              rules={required}
            >
              <Select options={citiesOptions} loading={loadingCities} />
            </Form.Item>
          )}

          <Form.Item
            label="Данные о франчайзи"
            name="note"
            className="OperatorForm__gridItem OperatorForm__gridItem--full"
          >
            <TextArea />
          </Form.Item>
        </div>
      </Card>

      <Card className="OperatorForm__card">
        <div className="CouriersForm__header OperatorForm__header">
          <h2 className="CouriersForm__headerTitle">
            <IdcardOutlined style={{ marginRight: 8, marginLeft: 8 }} />
            <span>Доступ</span>
          </h2>
        </div>

        <Divider />

        <div className="OperatorForm__grid">
          <Form.Item label="Телефон" name={['user', 'phone']} rules={required}>
            <PhoneInput />
          </Form.Item>

          <Form.Item label="Логин" name={['user', 'username']} rules={required}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name={['user', 'password']}
            rules={isCreateMode ? required : []}
          >
            <Password />
          </Form.Item>

          <Form.Item
            label="Пользователь заблокирован"
            name={['user', 'is_blocked']}
          >
            <Radio.Group>
              <Radio.Button value>Да</Radio.Button>
              <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>

        {submitButton(true)}
      </Card>
    </Form>
  );
};

const component = pipe(OperatorForm, connect(null, mapActions));
export { component as OperatorForm };
