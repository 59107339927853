import React, { FunctionComponent, useState } from 'react';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Col, Form, Input, InputNumber, Radio, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CourierCarType } from '../api/protocol';

type CarVariantFormProps = { form: FormInstance }
export const FormCarVariant: FunctionComponent<CarVariantFormProps> = ({ form }) => {
  const defaultValue: CourierCarType = form.getFieldValue(['car', 'type']) || 'light';
  const [type, setType] = useState<CourierCarType>(defaultValue);

  const onCarTypeChange = (e: RadioChangeEvent): void => { setType(e.target.value); };

  return (
    <>
      <Form.Item name={['car', 'type']}>
        <Radio.Group className="RateVariant__rateType" onChange={onCarTypeChange}>
          <Radio.Button value='light'>Легковой</Radio.Button>
          <Radio.Button value='truck'>Грузовик</Radio.Button>
          <Radio.Button value='walker'>Пешеход</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {type !== 'walker' &&
      (
        <>
          <Row gutter={24} className="RateVariant__fields">
            <Col style={{ width: '50%' }}>
              <Form.Item
                name={['car', 'brand']}
                label='Производитель'
              >
                <Input placeholder='Toyota'/>
              </Form.Item>
            </Col>
            <Col style={{ width: '50%' }}>
              <Form.Item
                name={['car', 'model']}
                label='Модель'
              >
                <Input placeholder='Camry'/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="RateVariant__fields">
            <Col style={{ width: '50%' }}>
              <Form.Item
                name={['car', 'year']}
                label='Год'
              >
                <InputNumber style={{ width: '100%' }} min={1900} placeholder='2014'/>
              </Form.Item>
            </Col>
            <Col style={{ width: '50%' }}>
              <Form.Item
                name={['car', 'color']}
                label='Цвет'
              >
                <Input placeholder='Жёлтый'/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="RateVariant__fields">
            <Col style={{ width: '100%' }}>
              <Form.Item
                name={['car', 'number']}
                label='Регистрационный номер'
              >
                <Input placeholder='А000АА000'/>
              </Form.Item>
            </Col>
          </Row>
        </>
      )
      }
    </>
  );
};
