import { CustomerBranchPayload, EncodedAddress } from '../api/protocol';

export const isArray = (value: unknown): value is Array<unknown> => Array.isArray(value);
export const isObject = (value: unknown): value is Record<string, unknown> => value !== null && typeof value === 'object';
export const isString = (value: unknown): value is string => typeof value === 'string';
export const isUndefined = (value: unknown): value is undefined => value === undefined;
export const possibleToBeNumber = (value: unknown): value is number => isString(value) && /^[-+]?[0-9]*\.?[0-9]+$/.test(value);

// TODO: refactor
export function clearFormData<T extends any>(data: T, omitUndefined = false): T {
  let value = null;
  if (isArray(data)) value = data.map((item: unknown) => clearFormData(item));
  else if (isObject(data)) value = Object.keys(data).reduce((obj, key) => {
    const val = data[key] as T;
    return omitUndefined && isUndefined(val) ? obj : { ...obj, [key]: clearFormData(data[key] as T) }
  }, {} as T);
  // else if (possibleToBeNumber(data)) value = parseFloat(data);
  else if (isString(data) && data.length === 0) value = null;
  else if (!isUndefined(data)) value = data;
  return value;
}

export const formatAutocompleteBranch = (
  branch: CustomerBranchPayload,
  index: number,
  encodedAddresses: EncodedAddress[],
): CustomerBranchPayload => {
  const newBranch: Omit<CustomerBranchPayload, 'address'> = {
    place_id: branch.place_id,
    phone: branch.phone,
    comment: branch.comment,
  };

  if (index < encodedAddresses.length) {

    const address = encodedAddresses.find(item => item.index === index);
    if (address !== undefined && address.value !== undefined) {
      newBranch.place_id = address.value;
    }

    if (newBranch.comment === undefined) {
      newBranch.comment = null;
    }

    if (branch.id !== undefined) newBranch.id = branch.id;
  }

  return newBranch;
};
