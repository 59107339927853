import {
  AuthLoginResponse,
  City,
  CustomersProfileDetailsResponse,
  Location,
  OperatorPhoneResponse,
  OperatorsProfileDetailsResponse,
  UserProfileDetailsResponse,
  UserRole,
} from '../../api/protocol';

type RoleKind<T, K extends UserRole> = KindOf<UserRole, T, K>

export type OperatorProfile = RoleKind<OperatorsProfileDetailsResponse, 'operator'>
export type CustomerProfile = RoleKind<CustomersProfileDetailsResponse, 'customer'>;
export type CommonUserProfile = RoleKind<UserProfileDetailsResponse, 'franchiser'>;

export type UserProfile = OperatorProfile | CustomerProfile | CommonUserProfile | null
export type UserSettings = { collapsed: boolean, location?: Location }
export type OperatorData = { phone: string | null }

export type UserState = {
  isAuthenticated: boolean
  settings: UserSettings
  city: City;
  profile: UserProfile
  role: UserRole | null
  searchSessionToken: GUID
  operatorData: OperatorData
}

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SET_PROFILE = 'USER_SET_PROFILE';
export const USER_SET_SETTINGS = 'USER_SET_SETTINGS';
export const SETTINGS_SET_COLLAPSE = 'SETTINGS_SET_COLLAPSE';
export const SETTINGS_SET_LOCATION = 'SETTINGS_SET_LOCATION';
export const USER_SET_OPERATOR_PHONE = 'USER_SET_OPERATOR_PHONE';

interface UserLoginAction {
  type: typeof USER_LOGIN
  payload: AuthLoginResponse
}

interface UserLogoutAction {
  type: typeof USER_LOGOUT
}

interface UserSetProfileAction {
  type: typeof USER_SET_PROFILE
  payload: OperatorProfile | CustomerProfile | CommonUserProfile
}

interface UserSetSettingsAction {
  type: typeof USER_SET_SETTINGS
  payload: UserSettings
}

interface SettingsSetCollapseAction {
  type: typeof SETTINGS_SET_COLLAPSE
  payload: boolean
}

interface SettingsSetLocationAction {
  type: typeof SETTINGS_SET_LOCATION
  payload: Location
}

interface UserSetOperatorPhoneAction {
  type: typeof USER_SET_OPERATOR_PHONE
  payload: OperatorPhoneResponse
}

export type UserActionTypes =
  | UserLoginAction
  | UserLogoutAction
  | UserSetProfileAction
  | UserSetSettingsAction
  | SettingsSetCollapseAction
  | SettingsSetLocationAction
  | UserSetOperatorPhoneAction
