import React, { FunctionComponent } from 'react';
import { Route, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../store/root';
import { UserRole } from '../api/protocol';
import { NotFound } from '../views/NotFound/NotFound';

type PrivateProps = {
  allow?: UserRole[]
  redirectTo?: string
  children: JSX.Element
  exact?: boolean
  path: string
}

type StoreProps = { isAuthenticated: boolean, userRole: UserRole | null }
const mapState = (state: RootState): StoreProps => ({
  isAuthenticated: state.user.isAuthenticated,
  userRole: state.user.role,
});
type PrivateRouteProps = PrivateProps & StoreProps & RouteComponentProps

const PrivateRoute: FunctionComponent<PrivateRouteProps> = props => {
  const {
    userRole,
    isAuthenticated,
    path,
    allow = [],
    exact = false,
    redirectTo = '/login',
    children,
  } = props;

  const roleCondition = !!userRole && (allow.length === 0 || allow.includes(userRole));

  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) => {
        if (isAuthenticated) return roleCondition ? children : <NotFound/>;
        return <Redirect
          to={{
            pathname: redirectTo,
            state: { from: location },
          }}
        />;
      }}
    />
  );
};

const connected = withRouter(connect(mapState)(PrivateRoute));
export { connected as PrivateRoute };
