import { OperatorsOrderListResponse, OrderCourierShortInfo, OrderListItem, OrderStatus } from '../../api/protocol';

export type OrderPaginateList = PaginateLikeCacheList<OrderListItem>
export type OrderListCount = Record<OrderStatus, number>
export interface OrdersState {
  currentType: OrderStatus
  list: Record<OrderStatus, OrderPaginateList>
  listCount: OrderListCount
}

export const ORDERS_SET_LIST_ITEM = 'ORDERS_SET_LIST_ITEM';
export const ORDERS_SET_EMPTY_LIST = 'ORDERS_SET_EMPTY_LIST';
export const ORDERS_RESET_LIST = 'ORDERS_RESET_LIST';
export const ORDERS_SET_CURRENT_TYPE = 'ORDERS_SET_CURRENT_TYPE';
export const ORDERS_SET_COURIER_INFO = 'ORDERS_SET_COURIER_INFO';

export type OrderSetListItemPayload = { kind: OrderStatus, response: OperatorsOrderListResponse }
interface OrderSetListItemAction {
  type: typeof ORDERS_SET_LIST_ITEM
  payload: OrderSetListItemPayload
}

interface OrderSetEmptyListAction {
  type: typeof ORDERS_SET_EMPTY_LIST
  payload: OrderStatus
}

interface OrderResetListAction {
  type: typeof ORDERS_RESET_LIST
}

interface OrderSetCurrentTypeAction {
  type: typeof ORDERS_SET_CURRENT_TYPE
  payload: OrderStatus
}

export type OrderSetCourierInfoPayload = { orderId: number, response: OrderCourierShortInfo | null };
interface OrderSetCourierInfoAction {
  type: typeof ORDERS_SET_COURIER_INFO
  payload: OrderSetCourierInfoPayload
}

export type OrdersActionTypes =
  | OrderSetListItemAction
  | OrderSetEmptyListAction
  | OrderSetCurrentTypeAction
  | OrderResetListAction
  | OrderSetCourierInfoAction
