import React, { FunctionComponent, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button, Card, Divider, Form, Input, message } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

import {
  apiGetSettingsDetails,
  apiSettingsUpdate,
  makeMapDispatch,
} from '../../store/dispatcher';

import { FranchiserSettings, SettingsUpdateRequest } from '../../api/protocol';

import { clearFormData } from '../../utils/form';
import { bindErrorHandler, showApiErrorNotification } from '../../utils/noty';

import { CurrencyInput } from '../../components/Inputs';
import { LoaderAdaptive } from '../../components/Loader';

import './Settings.scss';

const required = [{ required: true, message: 'Пожалуйста, заполните поле.' }];

const mapActions = makeMapDispatch({
  getSettings: apiGetSettingsDetails,
  updateSettings: apiSettingsUpdate,
});

type SettingsProps = ReturnType<typeof mapActions>;
const Settings: FunctionComponent<SettingsProps> = props => {
  const { getSettings, updateSettings } = props;

  const [formRef] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [settings, setSettings] = useState<FranchiserSettings | null>(null);

  const errorHandler = bindErrorHandler(formRef, () => setLoadingUpdate(false));

  const fetchSettings = (): void => {
    if (loading) return;
    setLoading(true);

    getSettings()
      .then(setSettings)
      .catch(showApiErrorNotification)
      .finally(() => setLoading(false));
  };

  const onUpdate = (): void => {
    if (loadingUpdate) return;
    setLoadingUpdate(true);

    const payload = clearFormData(
      formRef.getFieldsValue()
    ) as SettingsUpdateRequest;

    updateSettings(payload)
      .then(() => {
        message.success('Настройки успешно изменены');
      })
      .catch((e) => {
        showApiErrorNotification(e);
        errorHandler(e);
      })
      .finally(() => setLoadingUpdate(false));
  };

  useEffect(fetchSettings, []);

  return (
    <div className="Settings">
      <section className="Settings__container">
        <Card>
          <h2>
            <IdcardOutlined style={{ marginRight: 8 }} />
            Настройки
          </h2>

          <Divider />

          {loading || !settings ? (
            <LoaderAdaptive />
          ) : (
            <Form
              form={formRef}
              layout="vertical"
              initialValues={settings ? { ...settings } : {}}
              scrollToFirstError
            >
              <div className="Settings__formGrid">
                <Form.Item
                  label="Комиссия курьера"
                  name="courier_commission_percent"
                  rules={required}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Повышенный % курьеру от доставки"
                  name="courier_increased_commission_percent"
                  rules={required}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className="Settings__formGrid">
                <Form.Item
                  label="Сумма заказа для повышенных % (в рублях)"
                  name="courier_increased_commission_start_price_roubles"
                  rules={required}
                >
                  <CurrencyInput />
                </Form.Item>

                <Form.Item
                  label="Сумма заказа для повышенных % (в тенге)"
                  name="courier_increased_commission_start_price_tenge"
                  rules={required}
                >
                  <CurrencyInput currency="tenge" />
                </Form.Item>
              </div>

              <Button
                size="large"
                type="primary"
                htmlType="button"
                onClick={onUpdate}
                loading={loadingUpdate}
                disabled={loadingUpdate}
                className="CreateOrder__actionButton"
              >
                Обновить
              </Button>
            </Form>
          )}
        </Card>
      </section>
    </div>
  );
};

const component = withRouter(connect(null, mapActions)(Settings));

export { component as Settings };
