import React, { FunctionComponent } from 'react';
import { Tag } from 'antd';

export const CounterTag: FunctionComponent = props => {
  const { children } = props;
  return (
    <Tag
      className="CounterTag"
      color="red"
    >
      {children}
    </Tag>
  );
};
