import { OrderStatus } from '../../api/protocol';
import {
  ORDERS_RESET_LIST,
  ORDERS_SET_COURIER_INFO,
  ORDERS_SET_CURRENT_TYPE,
  ORDERS_SET_EMPTY_LIST,
  ORDERS_SET_LIST_ITEM,
  OrdersActionTypes,
  OrderSetCourierInfoPayload,
  OrderSetListItemPayload,
} from './types';

export const setOrderListItem = (payload: OrderSetListItemPayload): OrdersActionTypes => ({ type: ORDERS_SET_LIST_ITEM, payload });
export const setOrderEmptyList = (payload: OrderStatus): OrdersActionTypes => ({ type: ORDERS_SET_EMPTY_LIST, payload });
export const setCurrentType = (payload: OrderStatus): OrdersActionTypes => ({ type: ORDERS_SET_CURRENT_TYPE, payload });
export const resetOrdersList = (): OrdersActionTypes => ({ type: ORDERS_RESET_LIST });
export const setOrderCourierInfo = (payload: OrderSetCourierInfoPayload): OrdersActionTypes => ({ type: ORDERS_SET_COURIER_INFO, payload });
