import React, { FunctionComponent } from 'react';
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from 'react-redux';
import { store, persistor } from '../store/root';
import { App } from '../App';

export const Root: FunctionComponent = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App/>
    </PersistGate>
  </Provider>
);
