import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { RadioChangeEvent } from 'antd/es/radio';
import { Button, Modal, Tooltip, Radio } from 'antd';
import {
  PlusOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
  FieldTimeOutlined,
  CarOutlined,
  StopOutlined,
  CheckCircleOutlined,
  ApartmentOutlined,
} from '@ant-design/icons';

import { RootState } from '../../store/root';
import { OrderListCount } from '../../store/orders/types';
import { OrderListItem, OrderStatus } from '../../api/protocol';
import {
  apiCustomerOrderList,
  apiGetOperatorsOrderList,
  apiOperatorsReleaseCourier,
  makeMapDispatch,
} from '../../store/dispatcher';

import { CounterTag } from '../../components/CounterTag';
import { OrdersTable } from '../../components/OrdersTable';
import { CouriersMap } from '../../components/CouriersMap/CouriersMap';
import { OrderDrawer } from '../../components/OrderDrawer/OrderDrawer';
import { OrderCancel } from '../../components/OrderCancel/OrderCancel';
import { CourierPreview } from '../CourierPreview/CourierPreview';
import { CourierListSelect } from '../../components/CourierListSelect/CourierListSelect';

import { showApiErrorNotification } from '../../utils/noty';
import { useBusEffect } from '../../utils/bus';

import './Orders.scss';
import './ResponsiveTable.scss';

declare module '../../utils/bus' {
  interface BusEvents {
    onReleaseCourier: number;
  }
}

type StateProps = {
  currentType: OrderStatus;
  cacheList: OrderListItem[];
  listCount: OrderListCount;
  paginator: Paginator;
  isOperator: boolean;
};

const mapState = (state: RootState): StateProps => {
  const { currentType, listCount, list } = state.orders;
  const { items, ...paginator } = list[currentType];
  const cacheList = items[paginator.page] || [];
  const isOperator = state.user.role === 'operator';
  return { cacheList, currentType, listCount, paginator, isOperator };
};

const mapActions = makeMapDispatch({
  getOperatorOrders: apiGetOperatorsOrderList,
  getCustomerOrders: apiCustomerOrderList,
  releaseCourier: apiOperatorsReleaseCourier,
});

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapActions>;
const Orders: FunctionComponent<Props> = props => {
  const {
    currentType,
    listCount,
    cacheList,
    getOperatorOrders,
    getCustomerOrders,
    paginator,
    isOperator,
    releaseCourier,
  } = props;

  const getOrders = useCallback(
    isOperator ? getOperatorOrders : getCustomerOrders,
    [isOperator]
  );

  const [loading, setLoading] = useState(false);
  const [outdated, setOutdated] = useState(false);

  const [timer, setTimer] = useState(-1);
  const resetTimer = (): void => {
    clearInterval(timer);
    setTimer(window.setInterval(() => setOutdated(true), 7000));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onReload = async (
    showReload: boolean,
    options: { page?: number; status?: OrderStatus } = {}
  ): Promise<void> => {
    const { page = paginator.page, status = currentType } = options;

    resetTimer();

    if (showReload) setLoading(true);

    try {
      await getOrders({ page, status });
    } catch (e) {
      showApiErrorNotification(e);
    } finally {
      if (showReload) setLoading(false);
      setOutdated(false);
    }
  };

  useEffect(() => {
    if (outdated) onReload(false);
  }, [outdated]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    onReload(true);
    return () => {
      clearInterval(timer);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeCurrentType = async (evt: RadioChangeEvent): Promise<void> => {
    const status = evt.target.value as OrderStatus;
    onReload(true, { status });
  };

  const onPageChange = useCallback(
    async (page: number): Promise<void> => onReload(true, { page }),
    [onReload]
  );

  useBusEffect('onReleaseCourier', (id: number): void => {
    Modal.confirm({
      title: 'Подтверждение действия',
      content: 'Вы действительно снять курьера с доставки?',
      okText: 'Да',
      icon: <ExclamationCircleOutlined />,
      onOk: async close => {
        setLoading(true);
        try {
          close();
          await releaseCourier({ order_id: id });
          onReload(true);
        } catch (e) {
          showApiErrorNotification(e);
        } finally {
          setLoading(false);
        }
      },
    });
  });

  return (
    <section className="Orders">
      {isOperator && (
        <section>
          <CouriersMap />
        </section>
      )}
      <section className="Orders__container">
        <header className="Orders__header">
          <div className="Orders__filters">
            <Radio.Group
              className="Organizations__radioButtons Orders__radioButtons"
              value={currentType}
              buttonStyle="solid"
              onChange={onChangeCurrentType}
            >
              <Radio.Button value="new" className="Orders__radioButton">
                <span className="Orders__radioIcon">
                  <FieldTimeOutlined />
                </span>
                <span className="Orders__radioText">В ожидании</span>
                <CounterTag>{listCount.new}</CounterTag>
              </Radio.Button>

              <Radio.Button
                value="courier_assigned"
                className="Orders__radioButton"
              >
                <span className="Orders__radioIcon">
                  <ApartmentOutlined />
                </span>
                <span className="Orders__radioText">Назначены</span>
                <CounterTag>{listCount.courier_assigned}</CounterTag>
              </Radio.Button>

              <Radio.Button value="in_transit" className="Orders__radioButton">
                <span className="Orders__radioIcon">
                  <CarOutlined />
                </span>
                <span className="Orders__radioText">Выполняются</span>
                <CounterTag>{listCount.in_transit}</CounterTag>
              </Radio.Button>

              <Radio.Button value="delivered" className="Orders__radioButton">
                <span className="Orders__radioIcon">
                  <CheckCircleOutlined />
                </span>
                <span className="Orders__radioText">Выполненные</span>
              </Radio.Button>

              <Radio.Button value="canceled" className="Orders__radioButton">
                <span className="Orders__radioIcon">
                  <StopOutlined />
                </span>
                <span className="Orders__radioText">Отмененные</span>
              </Radio.Button>
            </Radio.Group>
            <Tooltip title="Обновить" placement="bottom">
              <Button
                className="Orders__reloadButton"
                disabled={loading}
                onClick={() => onReload(true)}
              >
                <ReloadOutlined spin={loading} />
              </Button>
            </Tooltip>
          </div>
          <div className="Orders__createButtonWrapper">
            <Link to="/orders/create">
              <Button type="primary" className="Orders__createButton">
                <PlusOutlined />
                Создать заказ
              </Button>
            </Link>
          </div>
        </header>
        <OrdersTable
          list={cacheList}
          loading={loading}
          paginator={paginator}
          canAssign={isOperator}
          onPageChange={onPageChange}
        />
      </section>
      <OrderCancel />
      <CourierListSelect />
      <OrderDrawer />
      {isOperator && <CourierPreview />}
    </section>
  );
};

const component = withRouter(connect(mapState, mapActions)(Orders));

export { component as Orders };
