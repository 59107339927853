import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { debounce } from 'lodash-es';
import { pipe } from 'fp-ts/es6/pipeable';

import { AutoComplete, Input, Tooltip } from 'antd';
import { LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone, CompassOutlined } from '@ant-design/icons';

import { ApiError } from '../../api/client/errors';
import { AddressSearchRequest, Location } from '../../api/protocol';

import { RootState } from '../../store/root';
import { apiSearchAddress, makeMapDispatch } from '../../store/dispatcher';

import { foldView } from '../../utils/view';
import './AddressSearchInput.scss';
import { toRoundedKilometers } from '../../utils/distance';

type StateProps = { searchSessionToken: GUID, location?: Location }
const mapState = (state: RootState): StateProps => ({ 
  searchSessionToken: state.user.searchSessionToken,
  location: state.user.settings.location,
});
const mapActions = makeMapDispatch({ searchAddress: apiSearchAddress });

type Option = SelectOption<string>
type Options = Option[]
type ComponentProps = { value?: string, onChange?(value: string): void }
type Props = { setOuterValue: (value?: string) => void } & ReturnType<typeof mapState> & ReturnType<typeof mapActions> & ComponentProps
const SearchAddressInput: FunctionComponent<Props> = props => {
  const { setOuterValue, searchSessionToken, location, searchAddress, children, ...inputProps } = props;

  const [error, setError] = useState<string | null>(null);
  const [options, setOptions] = useState<Options>([]);
  const [loading, setLoading] = useState(false);

  const renderLabel = (address: string, distance: number): JSX.Element => 
    <div className="AddressSearchInput__option">    
      {distance !== null && (
        <span className="AddressSearchInput__distance">
            <CompassOutlined /> {toRoundedKilometers(distance)} км
        </span>
      )}
      {address}
    </div>

  const onSearch = useCallback(async (value: string): Promise<void> => {
    if (value.length === 0) {
      setError(null);
      setLoading(false);
      setOptions([]);
      return;
    }

    setLoading(true);
    const payload: AddressSearchRequest = { 
      address_query: value.toLowerCase(), 
      search_session_token: searchSessionToken,
      location,
    };

    try {
      const { items } = await searchAddress(payload);
      setOptions(
        items.map(item => ({
          key: item.place_id,
          value: item.full_address,
          label: renderLabel(item.full_address, item.distance_meters),
        }))
      );

      setError(items.length ? null : 'Адрес не найден');
    } catch (err) {
      setOptions([]);
      if (err instanceof ApiError) setError(err.getCommonFirstMessage());
    } finally {
      setLoading(false);
    }
  }, [searchSessionToken, location, searchAddress]);

  const callable = useMemo(() => debounce(onSearch, 500, { leading: false, trailing: true }), [onSearch]);

  const foldV = foldView(options, loading, error);

  return (
    <AutoComplete
      {...inputProps}
      onSearch={callable}
      options={options}
      onSelect={(value, option) => {
        setOuterValue((option as Option).key);
      }}
      onDeselect={() => setOuterValue(undefined)}
      className="AddressSearchInput"
    >
      <Input
        suffix={
          foldV(
            () => <span/>, // https://ant.design/components/input/#FAQ
            () => <LoadingOutlined/>,
            (errorData) => <Tooltip title={errorData}><CloseCircleTwoTone twoToneColor="#ff4d4f"/></Tooltip>,
            () => <CheckCircleTwoTone twoToneColor="#52c41a"/>,
          )
        }
      />
    </AutoComplete>
  );
};

const component = pipe(
  SearchAddressInput,
  connect(mapState, mapActions),
);

export { component as SearchAddressInput };
