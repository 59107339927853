export const optional = <A, B>(condition: boolean, f: (a: A) => B): (a: A) => A | B => {
  if (condition) return f;
  return s => s;
};

declare namespace Tag {
  const OpaqueTagSymbol: unique symbol;
  class OpaqueTag<S extends symbol> { private [OpaqueTagSymbol]: S }
  export type OpaqueType<T, S extends symbol> = T & OpaqueTag<S>;
}

declare const GUIDTag: unique symbol;

declare global {
  type Opaque<T, S extends symbol> = Tag.OpaqueType<T, S>
  type SelectOption<T> = { key?: T, value: T, label: string|JSX.Element }
  type GUID = Opaque<string, typeof GUIDTag>;
  type KindOf<S, T, K extends S> = T & { kind: K }
  type AtLeastOne<T, Keys extends keyof T = keyof T> = Partial<T> & { [K in Keys]: Required<Pick<T, K>> }[Keys]
  type PartialLike<T, Keys extends keyof T = keyof T> = Partial<T> & Omit<T, Keys>
  type Paginator = { total: number, page_size: number, page: number }
  type PaginateLikeList<V> = Paginator & { items: V[] }
  type PaginateLikeCacheList<V, K extends string = string> = Paginator & { items: Record<K, V[]> }
  type Float = number;
}
