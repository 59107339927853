import React, { FunctionComponent } from 'react';
import { Divider, Form, Button, Card, Input } from 'antd';
import { BranchesOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons/lib';
import TextArea from 'antd/lib/input/TextArea';
import { PhoneInput } from './Inputs';
import { SearchAddressInput } from './AddressSearchInput/AddressSearchInput';

const requiredRule = [{ required: true, message: 'Заполните поле' }];

type Props = {
  isUpdate: boolean;
  setOuterValues: (index: number, value?: string) => void;
  addressPath: string[];
}
export const FormBranchesPart: FunctionComponent<Props> = ({ isUpdate, setOuterValues, addressPath = ['address'] }) => (
  <Card style={{ marginBottom: 24 }}>
    <h2>
      <BranchesOutlined style={{ marginRight: 8 }}/>
      Адрес
    </h2>
    <Divider/>
    <Form.List name="branches">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <div className="BranchForm" key={field.key}>
              {isUpdate && (<Form.Item name={[index, 'id']} noStyle><Input type='hidden'/></Form.Item>)}
              <Form.Item label="Телефон" name={[index, 'phone']} rules={requiredRule}>
                <PhoneInput/>
              </Form.Item>
              <Form.Item label="Адрес" name={[index, ...addressPath]} rules={requiredRule}>
                <SearchAddressInput
                  setOuterValue={(value) => {
                    setOuterValues(index, value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Комментарий" name={[index, 'comment']}>
                <TextArea/>
              </Form.Item>
              {
                !!index &&
                <Button
                  className="BranchForm__delete"
                  onClick={() => remove(field.name)}
                  danger
                >
                  <DeleteOutlined/> Удалить
                </Button>
              }
              <Divider/>
            </div>
          ))}
          <Button onClick={() => add()} type='dashed' className="BranchForm__add">
            <PlusOutlined/> Добавить филиал
          </Button>
        </>
      )}
    </Form.List>
  </Card>
);
