import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { NotificationOutlined, PlusOutlined } from '@ant-design/icons';

import { RootState } from '../../store/root';
import { CouriersListTypes } from '../../store/couriers/types';
import { apiCouriersList, makeMapDispatch } from '../../store/dispatcher';
import { setCouriersCurrentList, setCouriersListPage } from '../../store/couriers/actions';

import { EventBus } from '../../utils/bus';
import { useOnDestroy } from '../../hooks/lifecycle';
import { showApiErrorNotification } from '../../utils/noty';

import { CouriersTable } from '../../components/CouriersTable';
import { CourierPreview } from '../CourierPreview/CourierPreview';
import { ReplenishBalance } from '../../components/ReplenishBalance';
import { PushNotification } from '../../components/PushNotification/PushNotification';
import { CouriersStatistic } from '../../components/CouriersStatistic';

import './Couriers.scss';
import '../Orders/ResponsiveTable.scss';
import { OrderDrawer } from '../../components/OrderDrawer/OrderDrawer';
import { OrderCancel } from '../../components/OrderCancel/OrderCancel';
import { CourierListSelect } from '../../components/CourierListSelect/CourierListSelect';

type StateProps = {
  couriersList: RootState['couriers']['list']
  currentList: RootState['couriers']['currentList']
  listExtra: RootState['couriers']['listExtra']
}

const mapState = (state: RootState): StateProps => ({
  couriersList: state.couriers.list,
  currentList: state.couriers.currentList,
  listExtra: state.couriers.listExtra,
});

const mapDispatch = makeMapDispatch({
  setPage: setCouriersListPage,
  setCurrentList: setCouriersCurrentList,
  getCouriersPage: apiCouriersList,
});

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const Couriers: FunctionComponent<Props> = props => {
  const { setPage, setCurrentList, getCouriersPage, couriersList, currentList, listExtra } = props;

  const [loading, setLoading] = useState(false);
  const [selector, setSelector] = useState<CouriersListTypes>(currentList);

  const { items, ...pagination } = couriersList[selector];

  const requestPage = (page: number): void => {
    if (page in items) {
      setPage(page);
    } else {
      setLoading(true);
      getCouriersPage({ page, is_blocked: selector === 'blocked' }, selector)
        .catch(showApiErrorNotification)
        .finally(() => { setLoading(false); });
    }
  };

  const { push: redirectTo } = useHistory();
  useEffect(() => { if (!loading) requestPage(pagination.page); }, [selector, loading, pagination.page]); // eslint-disable-line react-hooks/exhaustive-deps
  useOnDestroy(() => setCurrentList('active'));

  const onChangeFilter = (evt: RadioChangeEvent): void => { setSelector(evt.target.value as CouriersListTypes); };

  const viewList = items[pagination.page] || [];
  const loadingStatistic = useMemo(() => {
    const emptyExtra = !(listExtra.active || listExtra.blocked || listExtra.online || listExtra.offline);
    return loading && emptyExtra;
  }, [loading, listExtra]);

  return (
    <section className="AppSection Couriers">
      <header className="Couriers__header">
        <Radio.Group
          defaultValue={selector}
          buttonStyle="solid"
          className="Couriers__radios"
          onChange={onChangeFilter}
        >
          <Radio.Button className="Couriers__radio" value="active">Активные</Radio.Button>
          <Radio.Button className="Couriers__radio" value="blocked">Черный список</Radio.Button>
        </Radio.Group>
        <CouriersStatistic loading={loadingStatistic} {...listExtra}/>
        <div className="Couriers__buttons">
          <Button className="Couriers__button" onClick={() => EventBus.emit('showPushForm')}>
            <NotificationOutlined/>
            Отправить пуш
          </Button>
          <Button type="primary" onClick={() => { redirectTo('/couriers/create') }} className="Couriers__button">
            <PlusOutlined/>
            Добавить курьера
          </Button>
        </div>
      </header>
      <div className="Couriers__table">
        <CouriersTable
          loading={loading}
          couriers={viewList}
          pagination={{
            total: pagination.total,
            current: pagination.page,
            pageSize: pagination.page_size,
            hideOnSinglePage: true,
          }}
          onRowClick={id => EventBus.emit('openCourierPreview', id)}
          onReplenishBalance={id => EventBus.emit('onReplenishBalance', id)}
          onPageChange={page => requestPage(page)}
        />
      </div>
      <OrderDrawer/>
      <OrderCancel/>
      <CourierListSelect/>
      <CourierPreview/>
      <ReplenishBalance/>
      <PushNotification/>
    </section>
  );
};

const connected = withRouter(connect(mapState, mapDispatch)(Couriers));
export { connected as Couriers };
