import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { Reducer } from 'redux';
import { Lens } from 'monocle-ts';
import { v4 as generateUuid } from 'uuid';

import { UserActionTypes, UserState } from './types';

type OptionalGUID = GUID | undefined;
const getSearchSessionToken = (): GUID => {
  let sessionGuid = sessionStorage.getItem('searchSessionToken') as OptionalGUID;
  if (sessionGuid) return sessionGuid;
  sessionGuid = generateUuid() as GUID;
  sessionStorage.setItem('searchSessionToken', sessionGuid);
  return sessionGuid
};

const initialState: UserState = {
  searchSessionToken: getSearchSessionToken(),
  city: {
    id: 0,
    name: '',
    currency_type: 'roubles',
    latitude: 43.10562,
    longitude: 131.87353,
  },
  isAuthenticated: false,
  profile: null,
  role: null,
  settings: {
    collapsed: window.innerWidth < 768,
  },
  operatorData: {
    phone: null
  }
};

const profileLens = Lens.fromProp<UserState>()('profile');
const settingsLens = Lens.fromProp<UserState>()('settings');
const collapsedLens = settingsLens.compose(Lens.fromProp<UserState['settings']>()('collapsed'));
const locationLens = settingsLens.compose(Lens.fromProp<UserState['settings']>()('location'));
const operatorDataLens = Lens.fromProp<UserState>()('operatorData');

const baseUserReducer: Reducer<UserState, UserActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGIN':
      return { ...state, isAuthenticated: true, city: action.payload.city, role: action.payload.role };
    case 'USER_LOGOUT':
      return { ...state, isAuthenticated: false, profile: null, role: null, operatorData: { phone: null } };
    case 'USER_SET_PROFILE':
      return profileLens.set(action.payload)(state);
    case 'USER_SET_SETTINGS':
      return settingsLens.set(action.payload)(state);
    case 'SETTINGS_SET_COLLAPSE':
      return collapsedLens.set(action.payload)(state);
    case 'SETTINGS_SET_LOCATION':
      return locationLens.set(action.payload)(state);
    case 'USER_SET_OPERATOR_PHONE':
      return operatorDataLens.set(action.payload)(state);
    default:
      return state;
  }
};

const persistConfig = {
  key: 'userData',
  storage,
  whitelist: [
    'isAuthenticated',
    'settings',
    'role',
    'city',
  ],
};

export const userReducer = persistReducer(persistConfig, baseUserReducer);
