import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';

import { fold } from 'fp-ts/es6/Monoid';
import { pipe } from 'fp-ts/es6/pipeable';
import { eqString, eqNumber, getStructEq, eqStrict } from 'fp-ts/es6/Eq';
import { flatten, makeBy, map, sort, uniq } from 'fp-ts/es6/Array';
import { ordString, ordNumber, contramap, getMonoid } from 'fp-ts/es6/Ord';

import { LabeledValue } from 'antd/es/select';
import { Select, Modal, Empty, Button } from 'antd';

import {
  apiCourierReplenishBalance,
  apiGetOperatorsOrderAvailableCouriers,
  apiOperatorsOrderAssignCourier,
  makeMapDispatch,
} from '../../store/dispatcher';
import { OrderAvailableCourier } from '../../api/protocol';

import { useBusEffect } from '../../utils/bus';
import { showApiErrorNotification } from '../../utils/noty';

import { CurrencyInput } from '../Inputs';
import { Option } from '../SearchInput';

import './CourierListSelect.scss';

const byName = pipe(ordString, contramap((courier: OrderAvailableCourier) => courier.name));
const byDistance = pipe(ordNumber, contramap((courier: OrderAvailableCourier) => courier.distance_to_customer || Number.MAX_SAFE_INTEGER));
const courierMonoid = getMonoid<OrderAvailableCourier>();
const courierComparator = fold(courierMonoid)([byDistance, byName]);
const mapAvailableCouriers = map((courier: OrderAvailableCourier): Option => ({
  label: `${courier.name}${courier.distance_to_customer !== null ? ` (${courier.distance_to_customer.toFixed(2)} км)` : ''}`,
  value: courier.id.toString(),
}));

const courierEq = getStructEq<OrderAvailableCourier>({
  id: eqNumber,
  name: eqString,
  phone: eqString,
  distance_to_customer: eqStrict,
});

declare module '../../utils/bus' {
  interface BusEvents {
    assignCourier: number
  }
}

const mapDispatch = makeMapDispatch({
  findCouriers: apiGetOperatorsOrderAvailableCouriers,
  assignCourier: apiOperatorsOrderAssignCourier,
  replenishBalance: apiCourierReplenishBalance,
});

type ComponentProps = {
  afterAssign?: () => void
}

type Props = ComponentProps & ReturnType<typeof mapDispatch>;
const CourierListSelect: FunctionComponent<Props> = props => {
  const { findCouriers, assignCourier, replenishBalance, afterAssign } = props;

  const [list, setList] = useState<Option[]>([]);
  const [value, setValue] = useState<LabeledValue>();
  const [orderId, setOrderId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [replenishing, setReplenishing] = useState(false);
  const [showReplenish, setShowReplenish] = useState(false);
  const [replenishValue, setReplenishValue] = useState(100);

  useBusEffect('assignCourier', async id => {
    setOrderId(id);
    setList([]);
    setLoading(true);
    setVisible(true);
    try {
      const firstResponse = await findCouriers({ order_id: id, page: 1 });
      const couriers = [firstResponse.items];

      const totalPages = Math.max(Math.ceil(firstResponse.total / firstResponse.page_size), 1);
      if (totalPages > 1) {
        const stack = makeBy(totalPages - 1, pageIndex => findCouriers({ order_id: id, page: pageIndex + 2 }));
        const otherResponses = await Promise.all(stack);

        otherResponses.forEach(response => { couriers.push(response.items); });
      }

      const options = pipe(
        couriers,
        flatten,
        uniq(courierEq),
        sort(courierComparator),
        mapAvailableCouriers,
      );

      setList(options);
    } catch (e) {
      showApiErrorNotification(e);
    } finally {
      setLoading(false);
    }
  });

  const onClose = (): void => {
    setValue(undefined);
    setList([]);
    setOrderId(0);
    setVisible(false);
    setLoading(false);
    setSubmitting(false);
    setReplenishing(false);
    setReplenishValue(100);
    setShowReplenish(false);
  };

  const onConfirm = async (): Promise<void> => {
    if (!value) return;

    setSubmitting(true);
    try {
      const courierId = parseInt(value.value.toString(), 10);
      await assignCourier({ courier_id: courierId, order_id: orderId });
      onClose();
      if (afterAssign) afterAssign();
    } catch (e) {
      showApiErrorNotification(e);
      setSubmitting(false);
      setShowReplenish(true);
    }
  };

  const onSelect = (val: unknown): void => {
    const selectedValue = val as LabeledValue;
    setValue(selectedValue);
    setShowReplenish(false);
  };

  const onReplenish = async (): Promise<void> => {
    if (!value) return;

    setReplenishing(true);
    try {
      const courierId = parseInt(value.value.toString(), 10);
      await replenishBalance({ id: courierId, amount: replenishValue });
      setShowReplenish(false);
      setReplenishValue(100);
    } catch (e) {
      showApiErrorNotification(e);
    } finally {
      setReplenishing(false);
    }
  };

  const onReplenishValueChange = (val: string): void => {
    setReplenishValue(parseInt(val, 10));
  };

  return (
    <Modal
      style={{ zIndex: 100 }}
      onOk={onConfirm}
      title={`Назначить курьера для заказа №${orderId}`}
      okText='Подтверить'
      visible={visible}
      onCancel={onClose}
      okButtonProps={{
        disabled: !value || replenishing,
        loading: submitting,
      }}
    >
      <div className='CourierListSelect'>
        <Select
          style={{ width: '100%' }}
          value={value}
          options={list}
          loading={loading}
          onChange={onSelect}
          placeholder='Выберите курьера из списка'
          labelInValue
          notFoundContent={
            <Empty
              description="Список курьеров пуст"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          }
        />
        {
          showReplenish &&
          <div className='CourierListSelect__replenish'>
            <span className='CourierListSelect__replenishLabel'>Пополнить баланс</span>
            <div className='CourierListSelect__replenishControls'>
              <CurrencyInput
                value={replenishValue}
                onChange={onReplenishValueChange}
                className='CourierListSelect__replenishInput'
              />
              <Button
                type='primary'
                onClick={onReplenish}
                loading={replenishing}
                className='CourierListSelect__replenishButton'
              >
                Пополнить
              </Button>
            </div>
          </div>
        }
      </div>
    </Modal>
  );
};

const component = pipe(
  CourierListSelect,
  connect(null, mapDispatch),
);

export { component as CourierListSelect };
