import {
  CUSTOMER_SET_EMPTY_LIST,
  CUSTOMER_SET_LIST_ITEM,
  CUSTOMER_SET_LIST_PAGE,
  CUSTOMER_SET_TYPES,
  CustomerActionTypes,
  CustomerListTypes,
} from './types';
import { CustomerListResponse, CustomerTypesResponse } from '../../api/protocol';

export const setCustomerTypes = (payload: CustomerTypesResponse): CustomerActionTypes => ({
  type: CUSTOMER_SET_TYPES,
  payload,
});

export const setCustomerListItems = (payload: { response: CustomerListResponse, selector: CustomerListTypes }): CustomerActionTypes => ({
  type: CUSTOMER_SET_LIST_ITEM,
  payload,
});

export const setCustomerListPage = (payload: { page: number, selector: CustomerListTypes }): CustomerActionTypes => ({
  type: CUSTOMER_SET_LIST_PAGE,
  payload,
});


export const setCustomerEmptyList = (): CustomerActionTypes => ({
  type: CUSTOMER_SET_EMPTY_LIST
});
