import { FormInstance } from 'antd/lib/form';
import React, { FunctionComponent, useState } from 'react';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Form, Radio } from 'antd';
import { PercentInput, CurrencyInput } from './Inputs';

const requiredRule = [{ required: true, message: 'Заполните поле' }];

type RateVariantFormProps = { form: FormInstance }
export const FormRateVariant: FunctionComponent<RateVariantFormProps> = ({ form }) => {
  const defaultValue = form.getFieldValue(['rate_variant', 'rate_type']) || 'fix';
  const [type, setType] = useState(defaultValue);

  const onRateTypeChange = (e: RadioChangeEvent): void => { setType(e.target.value); };

  return (
    <>
      <Form.Item name={['rate_variant', 'rate_type']}>
        <Radio.Group className="RateVariant__rateType" onChange={onRateTypeChange}>
          <Radio.Button value='fix'>Фиксированный</Radio.Button>
          <Radio.Button value='percent'>Процентный</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {type === 'fix' ?
        (
          <Form.Item
            name={['rate_variant', 'delivery_price_fix']}
            label='Стоимость доставки'
            rules={requiredRule}
          >
            <CurrencyInput/>
          </Form.Item>
        ) : (
          <Form.Item
            name={['rate_variant', 'delivery_price_percent']}
            label='Стоимость доставки'
            rules={requiredRule}
          >
            <PercentInput/>
          </Form.Item>
        )
      }
    </>
  );
};
