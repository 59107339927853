import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Lens } from 'monocle-ts';

import { Statistic, Card, Divider, Row, Col } from 'antd';
import { FilterOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { OrderStatisticsItem } from '../../api/protocol';

import { apiOperatorStatistic, makeMapDispatch } from '../../store/dispatcher';

import { Options } from '../../components/SearchInput';
import { StatisticsTable } from '../../components/StatisticsTable/StatisticsTable';
import {
  FilterData,
  StatisticFilters,
} from '../../components/StatisticFilters';

import { formatPrice } from '../../utils/view';
import { formatCurrency } from '../../utils/strings';
import { showApiErrorNotification } from '../../utils/noty';

import '../Statistics/Statistics.scss';
import '../Orders/ResponsiveTable.scss';

const pageLens = Lens.fromProp<Paginator>()('page');

type StateProps = {
  defaultBranchOptions: Options;
};
const mapState = (): StateProps => {
  const defaultBranchOptions: Options = [];

  return {
    defaultBranchOptions,
  };
};
const mapActions = makeMapDispatch({
  fetchOperatorStatistic: apiOperatorStatistic,
});

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapActions>;
const CouriersStatistics: FunctionComponent<Props> = props => {
  const { fetchOperatorStatistic, defaultBranchOptions } = props;

  const [data, setData] = useState<OrderStatisticsItem[]>([]);
  const [filters, setFilters] = useState<FilterData>({});
  const [loading, setLoading] = useState(false);
  const [outdated, setOutdated] = useState(true);
  const [statistic, setStatistic] = useState({
    count: 0,
    amount: 0,
    commission: 0,
    delivery: 0,
    serviceProfit: 0,
  });
  const [paginator, setPaginator] = useState({
    total: 0,
    page_size: 0,
    page: 1,
  });
  const [applyingFilter, setApplyingFilter] = useState(false);

  const fetchStatisticData = useCallback(async (): Promise<void> => {
    const payload = { ...filters, page: paginator.page };

    const method = fetchOperatorStatistic;

    setLoading(true);
    try {
      const {
        items,
        total_order_price: amount,
        total_delivery_price: delivery,
        total_service_commission: commission,
        ...paginatorData
      } = await method(payload);

      setData(items);
      setPaginator(paginatorData);
      setStatistic({
        amount,
        commission,
        count: paginatorData.total,
        delivery,
        serviceProfit: delivery - commission,
      });
    } catch (e) {
      showApiErrorNotification(e);
    } finally {
      setApplyingFilter(false);
      setOutdated(false);
      setLoading(false);
    }
  }, [fetchOperatorStatistic, filters, paginator]);

  const onFiltersChange = (filtersData: FilterData): void => {
    setFilters(filtersData);
    setOutdated(true);
    setApplyingFilter(true);
  };

  const onPageChange = (page: number): void => {
    setPaginator(pageLens.set(page));
    setOutdated(true);
  };

  useEffect(() => {
    if (outdated && !loading) fetchStatisticData();
  }, [fetchStatisticData, outdated, loading]);

  return (
    <section className="AppSection Statistics">
      <header className="Statistics__header">
        <Row
          style={{ maxWidth: 800 }}
          gutter={{ xs: 4, sm: 8, md: 12, lg: 16 }}
          className="Statistics__row"
        >
          <Col span={24} xs={12} className="Statistics__column">
            <Card className="Statistics__cardWrapper">
              <Statistic
                className="Statistics__card"
                title="Количество заказов"
                value={statistic.count}
              />
            </Card>
          </Col>

          <Col span={24} xs={12} className="Statistics__column">
            <Card className="Statistics__cardWrapper">
              <Statistic
                className="Statistics__card"
                title="Выручка курьера"
                value={formatPrice(statistic.serviceProfit.toString())}
                suffix={formatCurrency()}
              />
            </Card>
          </Col>

          <Col span={24} xs={12} className="Statistics__column">
            <Card className="Statistics__cardWrapper">
              <Statistic
                className="Statistics__card"
                title="Выручка франчайзи"
                suffix={formatCurrency()}
                value={formatPrice(statistic.commission.toString())}
              />
            </Card>
          </Col>
        </Row>
      </header>

      <Divider orientation="left">
        <FilterOutlined style={{ marginRight: 8 }} />
        Фильтры
      </Divider>

      <StatisticFilters
        onFiltersDataChange={onFiltersChange}
        isFiltersApplying={applyingFilter}
        isOperator
        defaultBranches={defaultBranchOptions}
        isCouriers
      />

      <Divider orientation="left">
        <UnorderedListOutlined style={{ marginRight: 8 }} />
        Список заказов
      </Divider>

      <StatisticsTable
        data={data}
        loading={loading}
        paginator={paginator}
        onPageChange={onPageChange}
      />
    </section>
  );
};

const component = withRouter(connect(mapState, mapActions)(CouriersStatistics));
export { component as CouriersStatistics };
