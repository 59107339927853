import React, { FunctionComponent, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';

import { ColumnsType } from 'antd/es/table';
import {
  Button,
  Empty,
  Table,
  Tag,
  Dropdown,
  Menu,
  Typography,
  Tooltip,
} from 'antd';
import {
  ClockCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  MoreOutlined,
} from '@ant-design/icons/lib';

import { OrderListItem } from '../api/protocol';

import { EventBus } from '../utils/bus';
import {
  formatPhone,
  formatPrice,
  formatDate,
  formatRecipient,
  rowClickCallback,
} from '../utils/view';
import { formatCurrency } from '../utils/strings';
import { useInterval } from '../hooks/interval';

const { Text } = Typography;

const buildColumnsConfig = (
  canAssign: boolean,
  now: Moment
): ColumnsType<OrderListItem> => [
  {
    title: '№ заказа',
    key: 'id',
    dataIndex: 'id',
    render: (id: number) => (
      <div className="ResponsiveTable__idWrapper">
        <h3 className="ResponsiveTable__mobileHeading">№ заказа:</h3>
        <Tag color="red" className="ResponsiveTable__id">
          {id}
        </Tag>
      </div>
    ),
  },
  {
    title: 'Заведение',
    key: 'organization',
    render: ({ customer, phone }: OrderListItem) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">Заведение</h3>
        <p className="OrdersTable__organizationTitle">{customer.name}</p>
        <p className="OrdersTable__organizationAddress">
          {customer.address?.address || customer.branch?.address?.address}
        </p>
        <a href={`tel:+${phone}`} className="OrdersTable__organizationPhone">
          {formatPhone(phone)}
        </a>
      </>
    ),
  },
  {
    title: 'Клиент',
    key: 'client',
    render: ({ recipient }: OrderListItem) => (
      <>
        <h3 className="ResponsiveTable__mobileHeading">Клиент</h3>
        <p className="OrdersTable__clientName">{recipient.name}</p>
        <p className="OrdersTable__clientAddress">
          {formatRecipient(recipient)}
        </p>
        <a href={`tel:+${recipient.phone}`} className="OrdersTable__clientPhone">
          {formatPhone(recipient.phone)}
        </a>
      </>
    ),
  },
  {
    title: 'Время на доставку',
    key: 'deliveryTime',
    render: (order: OrderListItem) => {
      const toPickUp = moment(order.to_pick_up_at);
      const toDeliver = moment(order.to_deliver_at);
      const diff = toDeliver.diff(now, 'minutes');

      const isTooLate = diff < 1;

      return (
        <>
          <h3 className="ResponsiveTable__mobileHeading">Время на доставку</h3>
          <p className="OrdersTable__deliveryTimeFrom">
            {formatDate(toPickUp)}
          </p>
          <Tag color={isTooLate ? 'red' : 'default'}>
            <ClockCircleOutlined style={{ marginRight: 4 }} />
            {isTooLate ? 'Не осталось' : `${diff} мин`}
          </Tag>
          <p className="OrdersTable__deliveryTimeTo">{formatDate(toDeliver)}</p>
        </>
      );
    },
  },
  {
    title: 'Курьер',
    key: 'courier',
    render: ({ courier, id, status }: OrderListItem) => {
      const canChangeCourier = status !== 'canceled' && status !== 'delivered';

      if (courier)
        return (
          <div>
            <h3 className="ResponsiveTable__mobileHeading">Курьер</h3>
            <div className="OrdersTable__courier">
              <div className="OrdersTable__courierInfo">
                <p className="OrdersTable__courierName">{courier.full_name}</p>
                <a
                  href={`tel:+${courier.phone}`}
                  className="OrdersTable__courierPhone"
                >
                  {formatPhone(courier.phone)}
                </a>
              </div>
              {canChangeCourier && canAssign && (
                <Dropdown
                  placement="bottomCenter"
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={evt => {
                          evt.domEvent.stopPropagation();
                          EventBus.emit('assignCourier', id);
                        }}
                      >
                        Назначить другого курьера
                      </Menu.Item>
                      <Menu.Item
                        onClick={evt => {
                          evt.domEvent.stopPropagation();
                          EventBus.emit('onReleaseCourier', id);
                        }}
                      >
                        <Text type="danger">Снять курьера с заказа</Text>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    type="ghost"
                    shape="circle-outline"
                    icon={<MoreOutlined />}
                    onClick={rowClickCallback()}
                    className="OrdersTable__courierMore"
                  />
                </Dropdown>
              )}
            </div>
          </div>
        );

      return canAssign && canChangeCourier ? (
        <div>
          <h3 className="ResponsiveTable__mobileHeading">Курьер</h3>
          <Button
            onClick={rowClickCallback(() => EventBus.emit('assignCourier', id))}
          >
            Назначить
          </Button>
        </div>
      ) : (
        <Tag color="red">Курьер не назначен</Tag>
      );
    },
  },
  {
    title: 'Общая сумма заказа',
    key: 'deliveryPrice',
    render: (order: OrderListItem) => (
      <>
        <p className="OrdersTable__deliveryPrice">
          Заказ на{' '}
          <strong>{formatPrice(order.order_price.toString())} {formatCurrency()}</strong>
        </p>
        <p className="OrdersTable__deliveryPrice">
          Доставка{' '}
          <strong>{formatPrice(order.delivery_price.toString())} {formatCurrency()}</strong>
        </p>
        {order.is_paid && <Tag color="success">Оплачен</Tag>}
      </>
    ),
  },
  {
    key: 'actions',
    render: ({ status, id }: OrderListItem) => {
      const canCancel = status !== 'canceled' && status !== 'delivered';
      return (
        <div className="OrdersTable__actions">
          <Tooltip title="Подробнее" placement="bottom">
            <Button className="OrdersTable__action" type="primary" icon={<InfoCircleOutlined />} />
          </Tooltip>
          {canCancel && (
            <Tooltip title="Отменить заказ" placement="bottomRight">
              <Button
                className="OrdersTable__action"
                type="default"
                danger
                icon={<DeleteOutlined />}
                onClick={rowClickCallback(() =>
                  EventBus.emit('cancelOrder', { id, kind: status })
                )}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
];

type Props = {
  list: OrderListItem[];
  loading: boolean;
  paginator: Paginator;
  canAssign: boolean;
  onPageChange: (number: number) => void;
};

export const OrdersTable: FunctionComponent<Props> = props => {
  const { list, loading, paginator, canAssign, onPageChange } = props;

  const [now, setNow] = useState(moment());
  useInterval(() => setNow(moment()), 1000);

  const formattedNow = now.format('HH:mm');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsConfig = useMemo(() => buildColumnsConfig(canAssign, now), [
    canAssign,
    formattedNow,
  ]);

  return (
    <Table
      className="OrdersTable ResponsiveTable ResponsiveTable--orders"
      columns={columnsConfig}
      dataSource={list}
      onRow={record => ({
        onClick: () => EventBus.emit('openOrderDrawer', record.id),
      })}
      rowKey={(order: OrderListItem) => order.id}
      loading={loading}
      pagination={{
        total: paginator.total,
        current: paginator.page,
        pageSize: paginator.page_size,
      }}
      onChange={pagination => {
        onPageChange(pagination.current || 1);
      }}
      locale={{
        emptyText: (
          <Empty
            description="Список заказов пуст"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ),
      }}
    />
  );
};
