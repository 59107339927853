import { ActionCreatorsMapObject, AnyAction, BindActionCreators, bindActionCreators, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Moment } from 'moment';
import { makeBy } from 'fp-ts/es6/Array';

import { RootActionType, RootState } from './root';
import { ApiClient } from '../api';
import * as Protocol from '../api/protocol';

import { setListFlush, setListItem } from './news/actions';
import { setUserProfile, userLogin, userLogout, setOperatorPhone } from './user/actions';
import { setCustomerEmptyList, setCustomerListItems, setCustomerTypes } from './customers/actions';
import {
  couriersReplenishBalance,
  setCouriersDetails,
  setCouriersEmptyDetails,
  setCouriersEmptyList,
  setCouriersListItems,
  setCouriersLocationList,
} from './couriers/actions';
import { resetOrdersList, setOrderEmptyList, setOrderListItem } from './orders/actions';

import { CustomerListTypes } from './customers/types';
import { CouriersListTypes } from './couriers/types';
import { OrderStatus } from '../api/protocol';

export type AppThunk<T = void, R = Promise<T>> = ThunkAction<R, RootState, unknown, AnyAction>;
export type AppDispatch<T extends ActionCreatorsMapObject> = (dispatch: Dispatch<RootActionType>) => BindActionCreators<T>;

export const makeMapDispatch = <T extends ActionCreatorsMapObject>(actions: T): AppDispatch<T> => dispatch => bindActionCreators(actions, dispatch);

export const apiUserLogin = (payload: Protocol.AuthLoginRequest): AppThunk<Protocol.AuthLoginResponse> => async dispatch => {
  const response = await ApiClient.post<Protocol.AuthLoginResponse, Protocol.AuthLoginRequest>('/api/auth/cookie/login', payload);
  dispatch(userLogin(response));
  return response;
};

export const apiUserLogout = (): AppThunk => async dispatch => {
  await ApiClient.post<void>('/api/auth/cookie/logout', null);
  dispatch(userLogout());
  dispatch(setCustomerEmptyList());
  dispatch(setCouriersEmptyList());
  dispatch(setCouriersEmptyDetails());
  dispatch(resetOrdersList());
};

export const apiProfileDetails = (): AppThunk => async (dispatch, getState) => {
  const { user: { role } } = getState();
  if (role === 'operator') {
    const response = await ApiClient.get<Protocol.OperatorsProfileDetailsResponse, Protocol.OperatorsProfileDetailsRequest>('/api/operators/profile/details', {});
    dispatch(setUserProfile({ kind: 'operator', ...response }));
  } else if (role === 'customer') {
    const response = await ApiClient.get<Protocol.CustomersProfileDetailsResponse, Protocol.CustomersProfileDetailsRequest>('/api/customers/profile/details', {});
    dispatch(setUserProfile({ kind: 'customer', ...response }));
  } else if (role === 'franchiser') {
    const response = await ApiClient.get<Protocol.UserProfileDetailsResponse, Protocol.UserProfileDetailsRequest>('/api/user/profile/details', {});
    dispatch(setUserProfile({ kind: 'franchiser', ...response }));
  }
};

export const apiProfileUpdate = (payload: Protocol.OperatorsProfileUpdateRequest): AppThunk => async dispatch => {
  const response = await ApiClient.post<Protocol.OperatorsProfileUpdateResponse, Protocol.OperatorsProfileUpdateRequest>('/api/operators/profile/update', payload);
  dispatch(setUserProfile({ kind: 'operator', ...response}));
};

export const apiCustomerProfileUpdate = (payload: Protocol.CustomersProfileUpdateRequest): AppThunk => async dispatch => {
  const response = await ApiClient.post<Protocol.CustomersProfileUpdateResponse, Protocol.CustomersProfileUpdateRequest>('/api/customers/profile/update', payload);
  dispatch(setUserProfile({ kind: 'customer', ...response}));
};

export const apiNewsList = (payload: Protocol.NewsListRequest = {}): AppThunk<Protocol.NewsListResponse> => async dispatch => {
  const response = await ApiClient.get<Protocol.NewsListResponse, Protocol.NewsListRequest>('/api/news/list', payload);
  dispatch(setListItem(response));
  return response;
};

export const apiNewsCreate = (payload: Protocol.NewsCreateRequest): AppThunk<Protocol.NewsCreateResponse> => async dispatch => {
  const response = await ApiClient.post<Protocol.NewsCreateResponse, Protocol.NewsCreateRequest>('/api/news/create', payload);
  dispatch(setListFlush());
  return response;
};

export const apiNewsDelete = (payload: Protocol.NewsDeleteRequest): AppThunk => async dispatch => {
  await ApiClient.post<Protocol.NewsDeleteResponse, Protocol.NewsDeleteRequest>('/api/news/delete', payload);
  dispatch(setListFlush());
};

export const apiCustomerTypes = (): AppThunk<Protocol.CustomerTypesResponse> => async dispatch => {
  const response = await ApiClient.get<Protocol.CustomerTypesResponse, Protocol.CustomerTypesRequest>('/api/customers/types', {});
  dispatch(setCustomerTypes(response));
  return response;
};

export const apiCustomerList = (payload: Protocol.CustomerListRequest, selector: CustomerListTypes): AppThunk => async dispatch => {
  const response = await ApiClient.get<Protocol.CustomerListResponse, Protocol.CustomerListRequest>('/api/customers/list', payload);
  dispatch(setCustomerListItems({ response, selector }));
};

export const apiCustomerCreate = (payload: Protocol.CustomerCreateRequest): AppThunk => async dispatch => {
  await ApiClient.post<Protocol.CustomerCreateResponse, Protocol.CustomerCreateRequest>('/api/customers/create', payload);
  dispatch(setCustomerEmptyList());
};

export const apiCustomerGet = (payload: Protocol.CustomerDetailsRequest): AppThunk<Protocol.CustomerDetailsResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.CustomerDetailsResponse, Protocol.CustomerDetailsRequest>('/api/customers/details', payload);
  return response;
};

export const apiCustomerUpdate = (payload: Protocol.CustomerUpdateRequest): AppThunk<Protocol.CustomerUpdateResponse> => async dispatch => {
  const response = await ApiClient.post<Protocol.CustomerUpdateResponse, Protocol.CustomerUpdateRequest>('/api/customers/update', payload);
  dispatch(setCustomerEmptyList());
  return response;
};

export const apiSearchCustomer = (query: string): AppThunk<Protocol.CustomerListResponse> => async () => {
  const payload = {
    is_blocked: false,
    search_query: query,
  };

  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.CustomerListResponse, Protocol.CustomerListRequest>('/api/customers/list', payload);
  return response;
};

export const apiSearchCourier = (query: string): AppThunk<Protocol.CouriersListResponse> => async () => {
  const payload = {
    is_blocked: false,
    search_query: query,
  };

  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.CouriersListResponse, Protocol.CouriersListRequest>('/api/couriers/list', payload);
  return response;
}

export const apiCouriersList = (payload: Protocol.CouriersListRequest, selector: CouriersListTypes): AppThunk => async dispatch => {
  const response = await ApiClient.get<Protocol.CouriersListResponse, Protocol.CouriersListRequest>('/api/couriers/list', payload);
  dispatch(setCouriersListItems({ response, selector }));
};

export const apiCouriersGet = (payload: Protocol.CouriersDetailsRequest): AppThunk<Protocol.CouriersDetailsResponse> => async dispatch => {
  const response = await ApiClient.get<Protocol.CouriersDetailsResponse, Protocol.CouriersDetailsRequest>('/api/couriers/details', payload);
  dispatch(setCouriersDetails(response));
  return response;
};

export const apiCourierTransactionList = (payload: Protocol.CouriersTransactionsListRequest): AppThunk<Protocol.CouriersTransactionsListResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.CouriersTransactionsListResponse, Protocol.CouriersTransactionsListRequest>('/api/couriers/transactions/list', payload);
  return response;
};

export const apiCourierReplenishBalance = (payload: Protocol.CouriersReplenishBalanceRequest): AppThunk => async (dispatch, getState) => {
  const { balance } = await ApiClient.post<Protocol.CouriersReplenishBalanceResponse, Protocol.CouriersReplenishBalanceRequest>('/api/couriers/replenish_balance', payload);
  const state = getState();
  let transactions = null;
  if (payload.id in state.couriers.details) {
    transactions = await dispatch(apiCourierTransactionList({ courier_id: payload.id }));
  }
  dispatch(couriersReplenishBalance({ id: payload.id, balance, transactions }));
};

export const apiCourierCreate = (payload: Protocol.CouriersCreateRequest): AppThunk => async dispatch => {
  const response = await ApiClient.post<Protocol.CouriersCreateResponse, Protocol.CouriersCreateRequest>('/api/couriers/create', payload);
  dispatch(setCouriersDetails(response));
  dispatch(setCouriersEmptyList());
};

export const apiCourierUpdate = (payload: Protocol.CouriersUpdateRequest): AppThunk => async dispatch => {
  const response = await ApiClient.post<Protocol.CouriersUpdateResponse, Protocol.CouriersUpdateRequest>('/api/couriers/update', payload);
  dispatch(setCouriersDetails(response));
  dispatch(setCouriersEmptyList());
};

const fetchLocations = <Req = Protocol.CouriersOnlineLocationsRequest, Res = Protocol.CouriersOnlineLocationsResponse>(payload: Req): Promise<Res> =>
  ApiClient.get<Res, Req>('/api/couriers/online_locations', payload);
export const apiCouriersOnlineLocations = (lastDate: Moment | null): AppThunk => async (dispatch, getState) => {
  const { couriers: { locationList: { pages } } } = getState();
  const payloadChunk = lastDate ? { last_update_at: lastDate.format() } : {};
  const stack = makeBy(pages, index => fetchLocations({ ...payloadChunk, page: index + 1 }));
  const data = await Promise.all(stack);
  dispatch(setCouriersLocationList(data));
};

export const apiSearchAddress = (payload: Protocol.AddressSearchRequest): AppThunk<Protocol.AddressSearchResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.AddressSearchResponse, Protocol.AddressSearchRequest>('/api/address/search', payload);
  return response;
};

export const apiGetOperatorsOrderList = (payload: Protocol.OperatorsOrderListRequest): AppThunk => async dispatch => {
  const response = await ApiClient.get<Protocol.OperatorsOrderListResponse, Protocol.OperatorsOrderListRequest>('/api/operators/order/list', payload);
  dispatch(setOrderListItem({ kind: payload.status, response }))
};

export const apiOperatorsOrderCreate = (payload: Protocol.OperatorsOrderCreateRequest): AppThunk => async dispatch => {
  await ApiClient.post<Protocol.OperatorsOrderCreateResponse, Protocol.OperatorsOrderCreateRequest>('/api/operators/order/create', payload);
  dispatch(setOrderEmptyList('new'));
};

export const apiOperatorsOrderCalculate = (payload: Protocol.OperatorsOrderCalculateRequest): AppThunk<Protocol.OperatorsOrderCalculateResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.OperatorsOrderCalculateResponse, Protocol.OperatorsOrderCalculateRequest>('/api/operators/order/calculate', payload);
  return response;
};

export const apiGetOperatorsOrderAvailableCouriers = (payload: Protocol.OperatorsOrderAvailableCouriersRequest): AppThunk<Protocol.OperatorsOrderAvailableCouriersResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.OperatorsOrderAvailableCouriersResponse, Protocol.OperatorsOrderAvailableCouriersRequest>('/api/operators/order/available_couriers', payload);
  return response;
};

export const apiOperatorsOrderCancel = (payload: Protocol.OperatorsOrderCancelRequest, kind: OrderStatus): AppThunk => async dispatch => {
  await ApiClient.post<Protocol.OperatorsOrderCancelResponse, Protocol.OperatorsOrderCancelRequest>('/api/operators/order/cancel', payload);
  dispatch(setOrderEmptyList(kind));
};

export const apiOperatorsOrderAssignCourier = (payload: Protocol.OperatorsOrderAssignCourierRequest): AppThunk => async () => {
  await ApiClient.post<Protocol.OperatorsOrderAssignCourierResponse, Protocol.OperatorsOrderAssignCourierRequest>('/api/operators/order/assign_courier', payload);
};

export const apiCustomerOrderList = (payload: Protocol.CustomersOrderListRequest): AppThunk => async dispatch => {
  const response = await ApiClient.get<Protocol.CustomersOrderListResponse, Protocol.CustomersOrderListRequest>('/api/customers/order/list', payload);
  dispatch(setOrderListItem({ kind: payload.status, response }))
};

export const apiCustomerOrderCreate = (payload: Protocol.CustomersOrderCreateRequest): AppThunk => async dispatch => {
  await ApiClient.post<Protocol.CustomersOrderCreateResponse, Protocol.CustomersOrderCreateRequest>('/api/customers/order/create', payload);
  dispatch(setOrderEmptyList('new'));
};

export const apiCustomerOrderCancel = (payload: Protocol.CustomersOrderCancelRequest, kind: OrderStatus): AppThunk => async dispatch => {
  await ApiClient.post<Protocol.CustomersOrderCancelResponse, Protocol.CustomersOrderCancelRequest>('/api/customers/order/cancel', payload);
  dispatch(setOrderEmptyList(kind));
};

export const apiCustomerOrderCalculate = (payload: Protocol.CustomersOrderCalculateRequest): AppThunk<Protocol.CustomersOrderCalculateResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.CustomersOrderCalculateResponse, Protocol.CustomersOrderCalculateRequest>('/api/customers/order/calculate', payload);
  return response;
};

export const apiNotificationCreate = (payload: Protocol.NotificationsCreateRequest): AppThunk => async () => {
  await ApiClient.post<Protocol.NotificationsCreateResponse, Protocol.NotificationsCreateRequest>('/api/notifications/create', payload);
};

export const apiGetOperatorPhone = (): AppThunk => async dispatch => {
  const response = await ApiClient.get<Protocol.OperatorPhoneResponse, Protocol.OperatorPhoneRequest>('/api/operator_phone', {});
  dispatch(setOperatorPhone(response));
};

export const apiOperatorStatistic = (payload: Protocol.OperatorsOrderStatisticsRequest): AppThunk<Protocol.OperatorsOrderStatisticsResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.OperatorsOrderStatisticsResponse, Protocol.OperatorsOrderStatisticsRequest>('/api/operators/order/statistics', payload);
  return response;
};

export const apiCustomersStatistic = (payload: Protocol.CustomersOrderStatisticsRequest): AppThunk<Protocol.CustomersOrderStatisticsResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.CustomersOrderStatisticsResponse, Protocol.CustomersOrderStatisticsRequest>('/api/customers/order/statistics', payload);
  return response;
};

export const apiOperatorsReleaseCourier = (payload: Protocol.OperatorsOrderReleaseCourierRequest): AppThunk => async () => {
  await ApiClient.post<Protocol.OperatorsOrderReleaseCourierResponse, Protocol.OperatorsOrderReleaseCourierRequest>('/api/operators/order/release_courier', payload);
};

export const apiGetOperatorsOrderDetails = (payload: Protocol.OperatorsOrderReleaseCourierRequest): AppThunk<Protocol.OperatorsOrderDetailsResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.OperatorsOrderDetailsResponse, Protocol.OperatorsOrderDetailsRequest>('/api/operators/order/details', payload);
  return response;
};

export const apiGetSettingsDetails = (payload: Protocol.SettingsDetailsRequest): AppThunk<Protocol.SettingsDetailsResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.SettingsDetailsResponse, Protocol.SettingsDetailsRequest>('/api/settings/details', payload);
  return response;
};

export const apiSettingsUpdate = (payload: Protocol.SettingsUpdateRequest): AppThunk<Protocol.SettingsUpdateResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.post<Protocol.SettingsUpdateResponse, Protocol.SettingsUpdateRequest>('/api/settings/update', payload);
  return response;
};

export const apiGetOperators = (payload: Protocol.OperatorsListRequest): AppThunk<Protocol.OperatorsListResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.OperatorsListResponse, Protocol.OperatorsListRequest>('/api/operators/list', payload);
  return response;
};

export const apiOperatorChangeData = (payload: Protocol.OperatorChangeDataRequest, mode: Protocol.OperatorChangeDataMode): AppThunk<Protocol.OperatorChangeDataResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.post<Protocol.OperatorChangeDataResponse, Protocol.OperatorChangeDataRequest>(`/api/operators/${mode}`, payload);
  return response;
};

export const apiOperatorDetails = (payload: Protocol.OperatorDetailsRequest): AppThunk<Protocol.OperatorDetailsResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.OperatorDetailsResponse, Protocol.OperatorDetailsRequest>('/api/operators/details', payload);
  return response;
};

export const apiGetCityList = (payload: Protocol.CityListRequest): AppThunk<Protocol.CityListResponse> => async () => {
  // noinspection UnnecessaryLocalVariableJS
  const response = await ApiClient.get<Protocol.CityListResponse, Protocol.CityListRequest>('/api/city/list', payload);
  return response;
};
