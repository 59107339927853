import { AuthLoginResponse, Location, OperatorPhoneResponse } from '../../api/protocol';
import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_SET_PROFILE,
  USER_SET_SETTINGS,
  SETTINGS_SET_COLLAPSE,
  SETTINGS_SET_LOCATION,
  USER_SET_OPERATOR_PHONE,
  UserActionTypes,
  UserSettings,
  UserProfile,
} from './types';

export const userLogin = (payload: AuthLoginResponse): UserActionTypes => ({ type: USER_LOGIN, payload });
export const userLogout = (): UserActionTypes => ({ type: USER_LOGOUT });
export const setUserProfile = (payload: NonNullable<UserProfile>): UserActionTypes => ({ type: USER_SET_PROFILE, payload });
export const setUserSettings = (payload: UserSettings): UserActionTypes => ({ type: USER_SET_SETTINGS, payload });
export const setCollapseState = (payload: boolean): UserActionTypes => ({ type: SETTINGS_SET_COLLAPSE, payload });
export const setLocationState = (payload: Location): UserActionTypes => ({ type: SETTINGS_SET_LOCATION, payload });
export const setOperatorPhone = (payload: OperatorPhoneResponse): UserActionTypes => ({ type: USER_SET_OPERATOR_PHONE, payload });
