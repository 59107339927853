import React, { FunctionComponent } from 'react';

import { OperatorForm } from '../../components/OperatorForm/OperatorForm';

export const CreateOperator: FunctionComponent = () => {
  return (
    <div>
      <section className="AppSection">
        <OperatorForm mode="create" />
      </section>
    </div>
  );
};
