import React, { ReactNode } from 'react';
import { Moment } from 'moment';
import { UserProfile } from '../store/user/types';
import { OrderRecipient } from '../api/protocol';

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const formatPhone = (phone: string | null, fallback: string = 'Не указан'): string => phone
  ? phone.replace(/^(?:\+7|7|8)(\d{3})(\d{3})(\d{2})(\d{2})/i, '+7 ($1) $2-$3-$4')
  : fallback;

export const formatPrice = (value: string, digits = 2, separator = ' ', removeTrailingZeros = true): string => {
  const val = parseFloat(value) / 100;
  const formatted = val.toFixed(digits).replace(/(\d)(?=(\d{3})+(\.|$))/g, `$1${separator}`).replace(/\./g, ',');
  return val * 100 % 100 === 0 && removeTrailingZeros ? formatted.replace(/,\d{2}/, '') : formatted;
};

export const formatPercents = (value: number): string =>
  value.toFixed(2)
    .replace(/\./g, ',')
    .replace(/,\d{2}/, '');

export const formatDate = (date: Moment): string => date.format('HH:mm DD.MM.YYYY');

export const getProfileName = (profile: UserProfile): string | null => {
  if (profile === null) return null;
  switch (profile.kind) {
    case 'customer':
      return profile.name;
    case 'operator':
      return profile.name;
    case 'franchiser':
      return profile.name;
    default:
      return null;
  }
};

export const formatRecipient = (address: OrderRecipient): string => {
  let out = address.address.address;
  if (address.floor) out += (', этаж ' + address.floor);
  if (address.apartment) out += (', кв ' + address.apartment);
  if (address.entrance) out += (', подъезд ' + address.entrance);
  return out;
};

export const foldView = <T>(data: T | null | undefined, loading: boolean, error: null | string) => (
  emptyNode: () => ReactNode,
  loadingNode: () => ReactNode,
  errorNode: (errorData: typeof error) => ReactNode,
  viewNode: (componentData: T) => ReactNode,
): ReactNode => {
  if (loading) return loadingNode();
  if (error) return errorNode(error);
  if (!data || (Array.isArray(data) && data.length === 0)) return emptyNode();
  return viewNode(data);
};

export const rowClickCallback = (callback = () => {}) => (evt?: React.MouseEvent<HTMLElement, MouseEvent>) => {
  evt?.stopPropagation();
  callback();
};
