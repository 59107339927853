import React, { FunctionComponent, useCallback, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { Form } from 'antd';

import { apiCustomerCreate, makeMapDispatch } from '../store/dispatcher';
import { RootState } from '../store/root';
import { clearFormData } from '../utils/form';
import { CustomerCreateRequest } from '../api/protocol';
import { bindErrorHandler } from '../utils/noty';
import { OrganizationForm } from '../components/OrganizationForm';

type StateProps = { typesList: RootState['customers']['types'] };
const mapState = (state: RootState): StateProps => ({ typesList: state.customers.types });
const mapActions = makeMapDispatch({ createCustomer: apiCustomerCreate });
type Props = ReturnType<typeof mapState> & ReturnType<typeof mapActions>;
const OrganizationCreate: FunctionComponent<Props> = props => {
  const { typesList, createCustomer } = props;

  const { push: redirectTo } = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const errorHandler = bindErrorHandler(form, () => { setLoading(false); });

  const redirectToList = useCallback(() => {
    setLoading(false);
    redirectTo('/organizations');
  }, [redirectTo]);

  const handleCreate = (data: unknown): void => {
    setLoading(true);
    const formattedData = clearFormData(data) as CustomerCreateRequest;
    formattedData.user.phone = null;
    formattedData.user.name = null;

    createCustomer(formattedData)
      .then(redirectToList)
      .catch(errorHandler);
  };

  return <OrganizationForm
    form={form}
    loading={loading}
    isUpdate={false}
    typesList={typesList}
    onBack={redirectToList}
    onSubmit={handleCreate}
  />;
};

const connected = withRouter(connect(mapState, mapActions)(OrganizationCreate));
export { connected as OrganizationCreate };
