import { Currency } from '../api/protocol';
import { store } from '../store/root';

export const camelize = (str: string): string => {
  return str.replace(/([-_][a-z])/gi, ($1: string) => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

export type CurrencyValue = {
  sign: string;
  name: string;
};
export const formatCurrency = (
  prop: keyof CurrencyValue = 'sign',
  currency?: Currency
): string => {
  const currencyType = store.getState().user.city.currency_type;
  const currencyValues: Record<Currency, CurrencyValue> = {
    roubles: {
      sign: '₽',
      name: 'Рубль',
    },
    tenge: {
      sign: '₸',
      name: 'Тенге',
    },
  };

  return currencyValues[currency || currencyType][prop];
};
