import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
} from 'react-router-dom';

import { ConfigProvider, Layout } from 'antd';
import ruLocale from 'antd/es/locale/ru_RU';

import { PrivateRoute } from './containers/PrivateRoute';

import { NavigationMenu } from './components/NavigationMenu/NavigationMenu';
import { HeaderTopBar } from './components/HeaderTopBar/HeaderTopBar';
import { Loader } from './components/Loader';

import { UserRole } from './api/protocol';

import { CouriersStatistics } from './views/CouriersStatictics/CouriersStatistics';
import { CreateOperator } from './views/CreateOperator/CreateOperator';
import { UpdateOperator } from './views/UpdateOperator/UpdateOperator';
import { Organizations } from './views/Organizations/Organizations';
import { OrganizationCreate } from './views/OrganizationCreate';
import { OrganizationUpdate } from './views/OrganizationUpdate';
import { ProfileFranchiser } from './views/ProfileFranchiser';
import { CreateOrder } from './views/CreateOrder/CreateOrder';
import { Statistics } from './views/Statistics/Statistics';
import { ProfileOperator } from './views/ProfileOperator';
import { ProfileCustomer } from './views/ProfileCustomer';
import { Operators } from './views/Operators/Operators';
import { CouriersCreate } from './views/CouriersCreate';
import { CouriersUpdate } from './views/CouriersUpdate';
import { Couriers } from './views/Couriers/Couriers';
import { Settings } from './views/Settings/Settings';
import { NotFound } from './views/NotFound/NotFound';
import { NewsList } from './views/News/News';
import { Orders } from './views/Orders/Orders';
import { Auth } from './views/Auth/Auth';

import {
  apiCustomerTypes,
  apiGetOperatorPhone,
  apiProfileDetails,
  apiUserLogout,
  makeMapDispatch,
} from './store/dispatcher';
import { setCollapseState, setLocationState } from './store/user/actions';
import { UserSettings } from './store/user/types';
import { RootState } from './store/root';
import { ApiClient } from './api';

import { showApiErrorNotification } from './utils/noty';
import { useBusEffect } from './utils/bus';

import './App.scss';
import 'antd/dist/antd.less';
import logo from './assets/logo.svg';
import logoCollapsed from './assets/logo-collapsed.svg';

const RootPath = process.env.PUBLIC_URL || '/';
const { Content, Sider } = Layout;

declare module './utils/bus' {
  interface BusEvents {
    onLogout: void;
  }
}

type StateProps = {
  settings: UserSettings;
  role: UserRole | null;
  isAuthenticated: boolean;
  needFetchProfile: boolean;
  needFetchTypesList: boolean;
  needFetchOperatorPhone: boolean;
};

const mapState = (state: RootState): StateProps => {
  const isFranchiser = state.user.role === 'franchiser';
  const isCourier = state.user.role === 'courier';
  return {
    settings: state.user.settings,
    role: state.user.role,
    isAuthenticated: state.user.isAuthenticated,
    needFetchProfile: state.user.profile === null && !isCourier,
    needFetchTypesList:
      state.customers.types.length === 0 && !isFranchiser && !isCourier,
    needFetchOperatorPhone:
      state.user.operatorData.phone === null && !isCourier,
  };
};

const mapDispatch = makeMapDispatch({
  fetchOrganizationTypes: apiCustomerTypes,
  fetchOperatorPhone: apiGetOperatorPhone,
  fetchMe: apiProfileDetails,
  emitLogout: apiUserLogout,
  setCollapse: setCollapseState,
  setLocation: setLocationState,
});

type AppProps = StateProps & ReturnType<typeof mapDispatch>;
const App: FunctionComponent<AppProps> = props => {
  const {
    settings,
    role,
    isAuthenticated,
    needFetchProfile,
    needFetchTypesList,
    needFetchOperatorPhone,
    setCollapse,
    setLocation,
    fetchMe,
    fetchOperatorPhone,
    fetchOrganizationTypes,
    emitLogout,
  } = props;

  const [isNotAllowed, setNotAllowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);

  const logout = useCallback(() => {
    setLoading(true);
    emitLogout().finally(() => {
      setLoading(false);
      setFetching(false);
    });
  }, [emitLogout]);

  const isFranchiser = role === 'franchiser';
  const redirectLink = isFranchiser ? '/operators' : '/orders';

  ApiClient.setInvalidTokenHandler(logout);
  useBusEffect('onLogout', logout);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(pos => {
      setLocation({
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const stack: Promise<unknown>[] = [];
    if (isAuthenticated && !fetching && !isNotAllowed) {
      if (needFetchProfile) stack.push(fetchMe());
      if (needFetchTypesList) stack.push(fetchOrganizationTypes());
      if (needFetchOperatorPhone) stack.push(fetchOperatorPhone());
      if (stack.length) {
        setLoading(true);
        setFetching(true);
        Promise.all(stack)
          .then(() => {
            setFetching(false);
            setLoading(false);
          })
          .catch(e => {
            showApiErrorNotification(e);
            setNotAllowed(true);
          });
      }
    } else setLoading(false);
  }, [
    loading,
    isNotAllowed,
    fetching,
    isAuthenticated,
    needFetchProfile,
    needFetchTypesList,
    needFetchOperatorPhone,
    fetchMe,
    fetchOrganizationTypes,
    fetchOperatorPhone,
  ]);

  if (loading || fetching) return <Loader />;

  return (
    <ConfigProvider locale={ruLocale}>
      <Router basename={RootPath}>
        <Layout style={{ minHeight: '100vh' }}>
          {isAuthenticated && (
            <Sider
              collapsible
              collapsed={settings.collapsed}
              onCollapse={setCollapse}
              className="App__sider"
              width={271}
              trigger={null}
              defaultCollapsed
            >
              <Link className="Sidebar__homeLink" to="/">
                <img src={settings.collapsed ? logoCollapsed : logo} alt="" />
              </Link>
              <NavigationMenu />
            </Sider>
          )}

          <Layout
            className="App__layoutWrapper"
            style={{ backgroundColor: '#f6f8fa' }}
          >
            {isAuthenticated && <HeaderTopBar />}
            <Content style={{ minHeight: 280 }}>
              <Switch>
                <Route path="/" exact>
                  <Redirect to={redirectLink} />
                </Route>

                <Route path="/login" exact>
                  {isAuthenticated ? <Redirect to={redirectLink} /> : <Auth />}
                </Route>

                <PrivateRoute path="/orders" exact>
                  <Orders />
                </PrivateRoute>

                <PrivateRoute path="/orders/create" exact>
                  <CreateOrder />
                </PrivateRoute>

                <PrivateRoute path="/news" exact>
                  <NewsList />
                </PrivateRoute>

                <PrivateRoute path="/stats" exact>
                  <Statistics />
                </PrivateRoute>

                <PrivateRoute path="/couriers-stats" exact allow={['operator']}>
                  <CouriersStatistics />
                </PrivateRoute>

                <PrivateRoute path="/organizations" exact allow={['operator']}>
                  <Organizations />
                </PrivateRoute>

                <PrivateRoute
                  path="/organizations/create"
                  exact
                  allow={['operator']}
                >
                  <OrganizationCreate />
                </PrivateRoute>

                <PrivateRoute
                  path="/organizations/edit/:id"
                  exact
                  allow={['operator']}
                >
                  <OrganizationUpdate />
                </PrivateRoute>

                <PrivateRoute path="/couriers" exact allow={['operator']}>
                  <Couriers />
                </PrivateRoute>

                <PrivateRoute
                  path="/couriers/create"
                  exact
                  allow={['operator']}
                >
                  <CouriersCreate />
                </PrivateRoute>

                <PrivateRoute
                  path="/couriers/edit/:id"
                  exact
                  allow={['operator']}
                >
                  <CouriersUpdate />
                </PrivateRoute>

                <PrivateRoute
                  path="/profile/operator"
                  exact
                  allow={['operator']}
                >
                  <ProfileOperator />
                </PrivateRoute>

                <PrivateRoute
                  path="/profile/customer"
                  exact
                  allow={['customer']}
                >
                  <ProfileCustomer />
                </PrivateRoute>

                <PrivateRoute
                  path="/profile/franchiser"
                  exact
                  allow={['franchiser']}
                >
                  <ProfileFranchiser />
                </PrivateRoute>

                <PrivateRoute path="/operators" exact allow={['franchiser']}>
                  <Operators />
                </PrivateRoute>

                <PrivateRoute
                  path="/operators/create"
                  exact
                  allow={['franchiser']}
                >
                  <CreateOperator />
                </PrivateRoute>

                <PrivateRoute
                  path="/operators/edit/:id"
                  exact
                  allow={['franchiser']}
                >
                  <UpdateOperator />
                </PrivateRoute>

                <PrivateRoute path="/settings" exact allow={['franchiser']}>
                  <Settings />
                </PrivateRoute>

                <PrivateRoute path="*">
                  <NotFound />
                </PrivateRoute>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Router>
    </ConfigProvider>
  );
};

const connected = connect(mapState, mapDispatch)(App);
export { connected as App };
