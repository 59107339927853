import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Eq, getStructEq, eqString, eqStrict } from 'fp-ts/es6/Eq';

import { Button, Card, Divider, Form, Input } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import { Store } from 'rc-field-form/lib/interface';

import { apiProfileUpdate, makeMapDispatch } from '../store/dispatcher';
import { OperatorProfile } from '../store/user/types';
import { RootState } from '../store/root';

import { bindErrorHandler } from '../utils/noty';

const dataEq: Eq<Pick<OperatorProfile, 'name' | 'username'>> = getStructEq({ username: eqString, name: eqStrict });

type StateProps = { profile: OperatorProfile };
const mapState = (state: RootState & { user: { profile: OperatorProfile } }): StateProps => ({ profile: state.user.profile });
const mapDispatch = makeMapDispatch({ updateProfile: apiProfileUpdate });
type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
const ProfileOperator: FunctionComponent<Props> = props => {
  const { profile, updateProfile } = props;
  const [formData, setFormData] = useState(props.profile);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const errorHandler = bindErrorHandler(form, () => { setLoading(false) });

  useEffect(() => { setFormData(profile) }, [profile]);
  const disabled = useMemo(() => dataEq.equals(profile, formData), [profile, formData]);

  const update = (): void => {
    const payload = {
      name: formData.name || null,
      username: formData.username,
    };
    setLoading(true);
    updateProfile(payload)
      .then(() => { setLoading(false) })
      .catch(errorHandler)
  };

  const onChange = (field: Store, data: Store): void => {
    form.setFields(Object.keys(field).map(name => ({ name, errors: [] })));
    setFormData(data as OperatorProfile)
  };

  return (
    <Card style={{ margin: 24 }}>
      <h2>
        <IdcardOutlined style={{ marginRight: 8 }}/>
        Профиль
      </h2>
      <Divider/>
      <Form
        style={{ width: 320, maxWidth: '100%' }}
        form={form}
        initialValues={formData}
        onValuesChange={onChange}
      >
        <Form.Item label="Имя" name="name">
          <Input/>
        </Form.Item>

        <Form.Item label="Логин" name="username">
          <Input/>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            loading={loading}
            disabled={disabled}
            onClick={update}
          >
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

const connected = withRouter(connect(mapState, mapDispatch)(ProfileOperator));
export { connected as ProfileOperator };
