import React, { FunctionComponent } from 'react';
import { Statistic } from 'antd';
import { TeamOutlined } from '@ant-design/icons/lib';

import { LoaderAdaptive } from '../Loader';

import './OperatorsStatistic.scss';

type OperatorsStatisticProps = {
  loading: boolean;
  active: number;
  blocked: number;
};
export const OperatorsStatistic: FunctionComponent<OperatorsStatisticProps> = props => {
  const { active, blocked, loading } = props;

  const items = [
    { name: 'Активных', icon: <TeamOutlined />, value: active },
    { name: 'В черном списке', icon: <TeamOutlined />, value: blocked },
  ];

  if (loading)
    return (
      <div className="Couriers__statistic">
        <LoaderAdaptive size="small" />
      </div>
    );

  return (
    <div className="OperatorsStatistic">
      {items.map((item, index) => (
        <Statistic
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="OperatorsStatistic__item"
          title={item.name}
          value={item.value}
          prefix={item.icon}
        />
      ))}
    </div>
  );
};
