import React, { FunctionComponent, useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { apiNewsCreate } from '../store/dispatcher';
import { useThunkDispatch } from '../hooks/thunk';
import { bindErrorHandler } from '../utils/noty';
import { NewsTargetUserRole } from '../api/protocol';

type ArticleFormData = {
  title: string
  text: string
  target_user_role: NewsTargetUserRole
}
type Props = {
  afterCreate(): void
  closeModal(): void
}

const targetRoleOptions: SelectOption<NewsTargetUserRole>[] = [
  {
    label: 'Курьеры',
    value: 'courier',
  },
  {
    label: 'Организации',
    value: 'customer',
  },
];
export const CreateArticleForm: FunctionComponent<Props> = ({ afterCreate, closeModal }) => {
  const [form] = Form.useForm();
  const dispatch = useThunkDispatch();

  const [loading, setLoading] = useState(false);
  const errorHandler = bindErrorHandler(form, () => setLoading(false));

  const createActiveAction = (payload: unknown): void => {
    setLoading(true);
    dispatch(apiNewsCreate(payload as ArticleFormData))
      .then(() => {
        form.resetFields();
        setLoading(false);
        closeModal();
        afterCreate();
      })
      .catch(errorHandler)
  };

  return (
    <Form layout="vertical" form={form} onFinish={createActiveAction}>
      <Form.Item label="Для кого?" name='target_user_role' rules={[{ required: true, message: 'Выберите группу' }]}>
        <Select options={targetRoleOptions}/>
      </Form.Item>

      <Form.Item label="Заголовок" name='title' rules={[{ required: true, message: 'Заполните поле' }]}>
        <Input/>
      </Form.Item>

      <Form.Item label="Текст новости" name='text' rules={[{ required: true, message: 'Заполните поле' }]}>
        <Input.TextArea rows={4}/>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} style={{ marginRight: '8px' }}>
          Добавить
        </Button>
        <Button htmlType="button" onClick={closeModal} disabled={loading}>
          Отмена
        </Button>
      </Form.Item>
    </Form>
  );
};
